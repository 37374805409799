<template>
  <div>
    <form @submit.prevent="$emit('step4')">
      <h4>Summary</h4>
      <hr>
      <div
          v-if="is_valid"
          class="row"
      >
        <div class="col-md-6">
          <div class="bond-content">
            <p>Company: <span>{{ company_name }}</span></p>
            <p>Contract Type: <span>{{ performance.cover }}</span></p>
            <p>Addressee</p>
            <p><span>{{ performance.addressee }}</span></p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="bond-content">
            <p>Procuring Entity: <span>{{ counterparty }}</span></p>
            <p>Contract Number: <span>{{ performance.contract_no }}</span></p>
            <p>Tender Purpose</p>
            <p><span>{{ performance.purpose }}</span></p>
          </div>
        </div>
      </div>
      <hr>
      <div
          v-if="is_valid"
          class="row"
      >
        <div class="col-md-6">
          <div class="bond-content">
            <p>Performance Bond Amount: <span>{{ performance.currency }} {{ $number.format(performance.amount) }}</span>
            </p>
            <p>Tender Period: <span>{{ performance.period }}</span></p>
            <p>Start Date: <span>{{ start_date }}</span></p>
            <p>End Date: <span>{{ performance.end_date }}</span></p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="bond-content">
            <p>Commission: <span>{{ performance.currency }} {{ $number.format(pricing.performance_charge) }}</span></p>
            <p>Excise Duty: <span>{{ performance.currency }} {{ $number.format(pricing.excise_duty) }}</span></p>
            <p>Indemnity Cost: <span>{{ performance.currency }} {{ $number.format(pricing.indemnity_cost) }}</span></p>
            <p v-if="performance.amount > 500000 || performance.cover !== 'Labor'">
              Limit/Cash Cover:
              <span> {{ performance.currency }} {{ $number.format(performance.amount) }}</span>
            </p>
            <p>Total Commission: <span>{{ performance.currency }} {{ $number.format(pricing.total) }}</span></p>
          </div>
        </div>
      </div>
      <br v-if="is_valid">
      <div
          v-if="! is_valid"
          class="alert alert-danger"
      >
        <p>The Performance bond amount is invalid. Please try a different amount.</p>
      </div>
      <div class="form-group">
        <a
            class="btn btn-info pull-left btn-sm"
            href=""
            @click.prevent="$emit('back')"
        >Previous</a>
        <button
            v-if="is_valid"
            class="btn btn-primary pull-right btn-sm"
        >
          Confirm
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment/src/moment';
import {mapGetters} from "vuex";

export default {
  props: {
    performance: {
      type: Object,
      default() {
        return {
          company: {}
        }
      }
    },
    pricing: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      counterparty: ''
    }
  },
  computed: {
    ...mapGetters({
      counterparties: 'getPerformanceParties'
    }),
    start_date() {
      return moment(this.performance.start_date).format('YYYY-MM-DD')
    },
    company_name() {
      if (this.performance.joint_venture) {
        return `${this.performance.company.name}-${this.performance.joint_venture} JV`;
      }
      return this.performance.company.name;
    },
    is_valid() {
      return this.pricing.performance_charge != null;
    }
  },
  watch: {
    counterparties() {
      this.setCounterparty();
    }
  },
  mounted() {
    this.setCounterparty();
  },
  methods: {
    setCounterparty() {
      if (this.counterparties.length){
        this.counterparty = this.counterparties.filter(x => x.id === this.performance.counterparty_id)[0].name;
      }
    }
  }
}
</script>

