<template>
  <div class="home">
    <h4 class="sub-heading">
      RM Summary
    </h4>
    <div class="tiles">
      <div class="row">
        <div class="col-md-3">
          <div class="tile red">
            <div class="tile-title">
              BidBonds
            </div>
            <div class="tile-content">
              {{ $number.format(bidbonds_count) }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tile blue">
            <div class="tile-title">
              Bidbond Value
            </div>
            <div class="tile-content">
              {{ $number.format(bidbonds_value) }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tile green">
            <div class="tile-title">
              Commission
            </div>
            <div class="tile-content">
              {{ $number.format(commission) }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tile yellow">
            <div class="tile-title">
              Companies
            </div>
            <div class="tile-content">
              {{ $number.format(companies_count) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3 col-md-push-3">
        <label>Start Date</label>
        <date-picker
            v-model="start.date"
            format="yyyy-MM-dd"
            input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label>End Date</label>
        <date-picker
            v-model="to.date"
            format="yyyy-MM-dd"
            input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label>Branch</label>
        <v-select
            v-model="branch_id"
            v-validate="'required'"
            :options="branches"
            :reduce="c => c.id"
            label="name"
            name="branch"
            placeholder="Filter By Branch"
        />
      </div>
    </div>
    <table
        ref="tb_rm_summary"
        class="table table-striped table-responsive-sm"
    >
      <thead>
      <tr>
        <th>#</th>
        <th>Relationship Manager</th>
        <th>Branch</th>
        <th>Companies</th>
        <th>Bid Bonds</th>
        <th>Bid Bond Value</th>
        <th>Commission</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item, i) in rm_summary"
          :key="i"
      >
        <td>{{ parseInt(i) + 1 }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.branch_name }}</td>
        <td>{{ item.companies_count }}</td>
        <td>{{ item.bidbonds_count }}</td>
        <td>{{ $number.format(item.bidbonds_value) }}</td>
        <td>{{ $number.format(item.commission) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import $ from 'jquery';
import moment from 'moment/src/moment';
import {mapGetters} from "vuex";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      dt: "",
      branch_id: null,
      start: {
        date: moment().add('week', '-1').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    ...mapGetters({
      rm_summary: 'getRMSummary',
      branches: 'getBranches'
    }),
    companies_count() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.companies_count), 0) : 0
    },
    bidbonds_count() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.bidbonds_count), 0) : 0
    },
    bidbonds_value() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.bidbonds_value), 0) : 0
    },
    commission() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.commission), 0) : 0
    },
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    branch_id() {
      this.fetch();
    },
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
    rm_summary() {
      if (this.dt) this.dt.destroy();
      setTimeout(() => {
        this.dt = $(this.$refs.tb_rm_summary).DataTable({
          destroy: true,
          dom: 'Bfrtip',
          buttons: [{extend: 'csvHtml5', text: 'Excel'}]
        });
      }, 3000);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.dt = $(this.$refs.tb_rm_summary).DataTable({
        dom: 'Bfrtip',
        buttons: [{extend: 'csvHtml5', text: 'Excel'}]
      });
    });
    this.getRMSummary();
  },
  methods: {
    getRMSummary() {
      if (!this.rm_summary.length) {
        this.fetch();
      }
      if (!this.branches.length) {
        this.$store.dispatch("fetchBranches");
      }
    },
    fetch() {
      this.$store.dispatch('fetchRMSummary', {start: this.start.date, end: this.to.date, branch_id: this.branch_id});
    }
  }
}
</script>
