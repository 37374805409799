export const contracts = [
    'Labor',
    'Services',
    'Goods',
    'Goods and Services',
];

export const contract_description = [
    'Labor contracts are ...',
    'Services contracts are ...',
    'Goods contracts are ...',
    'Goods and Services contracts are ...',
];
