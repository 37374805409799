<template>
  <div>
    <form @submit.prevent="validateForm">
      <div class="form-group">
        <label>Performance Amount</label>
        <vue-numeric
          v-model="performance.amount"
          v-validate="'required'"
          name="performance_amount"
          class="form-control"
        />
        <small
          v-if="errors.has('performance_amount')"
          class="help-block"
        >{{ errors.first('performance_amount') }}
        </small>
      </div>
      <div class="form-group">
        <label>Currency</label>
        <v-select
          v-model="performance.currency"
          v-validate="'required'"
          :options="currencies"
          name="currency"
          placeholder="Select Currency"
        />
        <small
          v-if="errors.has('currency')"
          class="help-block"
        >{{ errors.first('currency') }}
        </small>
      </div>
      <div class="form-group">
        <label>Contract Period (days)</label>
        <vue-numeric
          v-model="performance.period"
          v-validate="'required|min_value:30'"
          class="form-control"
          name="contract_period"
        />
        <small
          v-if="errors.has('contract_period')"
          class="help-block"
        >{{ errors.first('contract_period') }}
        </small>
      </div>
      <div class="form-group">
        <label>Start Date</label>
        <input
          ref="yesterdays_date"
          v-model="maxStartDate"
          type="hidden"
        >
        <date-picker
          v-model="performance.start_date"
          v-validate="'required|date_format:yyyy-MM-dd|after:yesterdays_date'"
          format="yyyy-MM-dd"
          name="performance_start_date"
          input-class="form-control bg-white"
          :disabled-dates="disabledDates"
        />
        <small
          v-if="errors.has('performance_start_date')"
          class="help-block"
        >
          {{ errors.first('performance_start_date') }}
        </small>
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input
          v-model="end_date"
          type="text"
          class="form-control"
          readonly
        >
      </div>
      <div class="form-group">
        <a
          href=""
          class="btn btn-info pull-left btn-sm"
          @click.prevent="$emit('back')"
        >Previous</a>
        <button
          class="btn btn-primary pull-right btn-sm"
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import {currencies} from "@/mixins/currencies";

export default {
  components: {
    DatePicker
  },
  props: {
    current: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      performance: {
        amount: '',
        currency: 'KES',
        period: '',
        start_date: '',
        end_date: ''
      },
      limit: null,
      maxStartDate: moment().add(-1, 'day').format('yyyy-MM-DD'),
      disabledDates: {
        to: moment().add(-1, 'day').toDate()
      },
      pre_pricing: {}
    }
  },
  computed: {
    end_date() {
      if (this.performance.start_date && this.performance.period) {
        let end_date = moment(this.performance.start_date).add(parseInt(this.performance.period), 'days').format('YYYY-MM-DD');
        this.formatStartDate();
        return end_date;
      }
      return '';
    },
    labor_limit() {
      return this.$store.getters.getLaborLimit;
    },
  },
  watch: {
    end_date(newval) {
      this.performance.end_date = newval;
    },
    labor_limit(newval) {
      if (newval) {
        this.limit = newval;
      }
    },
    current(newval) {
      newval.currency ? this.performance.currency = newval.currency : this.performance.currency = 'KES';
      newval.amount ? this.performance.amount = newval.amount : this.performance.amount = '';
      newval.period ? this.performance.period = newval.period : this.performance.period = '';
      newval.start_date ? this.performance.start_date = newval.start_date : this.performance.start_date = '';
    }
  },
  created() {
    this.currencies = currencies;
  },
  mounted() {
    this.current.currency ? this.performance.currency = this.current.currency : this.performance.currency = '';
    this.current.amount ? this.performance.amount = this.current.amount : this.performance.amount = '';
    this.current.period ? this.performance.period = this.current.period : this.performance.period = '';
    this.current.start_date ? this.performance.start_date = this.current.start_date : this.performance.start_date = '';
    this.getLaborLimit();
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('step3', this.performance);
        }
      });
    },
    formatStartDate() {
      this.performance.start_date = moment(this.performance.start_date).format(
          "YYYY-MM-DD"
      );
    },
    getLaborLimit() {
      if (this.current.cover !== 'Labor') {
        this.limit = this.current.company.limit > 0 ? this.current.company.balance : null;
      } else {
        if (!this.labor_limit) {
          this.$store.dispatch("fetchLaborLimit").then(() =>{
            this.limit = this.labor_limit;
          });
        }
        this.limit = this.labor_limit;
      }
    }
  }
}
</script>
