<template>
  <div
      id="company-payment"
      class="card"
  >
    <nav>
      <div
          id="nav-tab"
          class="nav nav-tabs nav-fill"
          role="tablist"
      >
        <a
            id="nav-home-tab"
            class="nav-item nav-link active"
            data-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home"
            aria-selected="false"
        >Pay via MPesa</a>
      </div>
      <div v-html="$error.handle(error)"/>
    </nav>

    <div
        id="nav-tabContent"
        class="tab-content"
    >
      <div
          id="nav-home"
          class="tab-pane fade  show active"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
      >
        <div class="mpesa">
          <div class="row">
            <div class="col-md-6">
              <div class="stk-push">
                <h6>Option One</h6>
                <p>
                  Enter your phone number (07XXXXXXXX) to receive a payment request on your phone. All
                  you have to do is input your m-pesa pin.
                </p>
                <form
                    data-vv-scope="form1"
                    @submit.prevent="validateForm('form1')"
                >
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input
                        v-model="stk_phone_number"
                        v-validate="'required|digits:10'"
                        type="number"
                        class="form-control"
                        name="phone"
                        data-vv-scope="form1"
                        :class="{'is-invalid': errors.has('form1.phone')}"
                    >
                    <div :class="{'invalid-feedback': errors.has('form1.phone')}">
                      {{ errors.first('form1.phone') }}
                    </div>
                  </div>
                  <div
                      v-if="!exceeds_mpesa"
                      class="form-group"
                  >
                    <a
                        v-if="stk_waiting"
                        href="#"
                        class="btn btn-primary pull-right btn-loading"
                        @click.prevent="requestSTK"
                    >
                      <i class="fa fa-spin fa-spinner"/>
                      Waiting for payment...
                    </a>
                    <button
                        v-else
                        type="submit"
                        class="btn btn-primary pull-right"
                    >
                      Request Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6">
              <div class="manual">
                <h6>Option Two</h6>
                <p>Follow the instructions below:</p>
                <ul>
                  <li>Go to m-pesa menu</li>
                  <li>Select lipa na mpesa</li>
                  <li>Select pay bill</li>
                  <li>Enter paybill number <strong>{{ mpesa_paybill }}</strong></li>
                  <li>Enter account number <strong>CP{{ account_number }}</strong></li>
                  <li>Enter the amount <strong>{{ $number.format(amount) }}</strong></li>
                  <li>Enter your pin and send</li>
                </ul>
                <p>
                  Once you receive the confirmation SMS, enter the transaction code in the box
                  below
                </p>
                <form
                    data-vv-scope="form2"
                    @submit.prevent="validateForm('form2')"
                >
                  <div class="form-group">
                    <label>Transaction Code</label>
                    <input
                        v-model="transaction_code"
                        v-validate="'required'"
                        type="text"
                        name="transaction_code"
                        placeholder="XXXXXX"
                        class="form-control"
                        :class="{'is-invalid': errors.has('form2.transaction_code')}"
                    >
                    <div :class="{'invalid-feedback': errors.has('form2.transaction_code')}">
                      {{ errors.first('form2.transaction_code') }}
                    </div>
                  </div>
                  <div class="form-group">
                    <a
                        v-if="confirming"
                        href="#"
                        class="btn btn-primary pull-right btn-loading"
                        @click.prevent="confirmTrx"
                    ><i
                        class="fa fa-spin fa-spinner"
                    /> Verifying payment</a>
                    <button
                        v-else
                        type="submit"
                        class="btn btn-primary pull-right"
                    >
                      Verify
                      Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stk_phone_number: '',
      amount: '',
      stk_waiting: false,
      confirming: false,
      transaction_code: '',
      error: ''
    }
  },

  computed: {
    account_number() {
      return this.$route.params.id;
    },
    exceeds_mpesa() {
      return this.amount > 70000;
    },
    mpesa_paybill() {
      return process.env.VUE_APP_MPESA_PAYBILL;
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/companies/search-cost').then(response => {
        this.amount = response.data.amount;
      });
    },
    validateForm(scope) {
      return this.$validator.validateAll(scope).then(result => {
        if (result) {
          if (scope === 'form1') this.requestSTK();

          if (scope === 'form2') this.confirmTrx()
        }
      });
    },
    requestSTK() {
      this.stk_waiting = true;

      this.$axios.post('/api/v1/initiate-stk', {
        phone: this.stk_phone_number,
        account: 'CP' + this.account_number,
        amount: this.amount
      }).then(() => {
        this.confirmPayment();
      }).catch(err => {
        this.stk_waiting = false;
        this.error = err.response;
      });
    },

    confirmTrx() {
      this.error = '';
      this.confirming = true;
      this.$axios.post('/api/v1/confirm-transaction', {
        account: 'CP' + this.account_number,
        transaction_code: this.transaction_code
      }).then(response => {
        if (response.data.confirmed) {
          this.companysearch();
          this.confirming = false;
        } else {
          this.confirming = false;
          this.$toastr.e("Could not find this payment", "Payment not found");
        }
      }).catch(err => {
        this.confirming = false;
        this.$toastr.e(err.response.data.error.message);
        this.error = err.response;
      });

    },

    confirmPayment() {
      const self = this;
      let attempts = 0;
      let interval = setInterval(function () {
        self.$axios.post('/api/v1/confirm-payment', {
          account: 'CP' + self.account_number
        }).then(response => {
          if (response.data.confirmed) {
            clearInterval(interval);
            self.stk_waiting = false;
            self.companysearch();
          } else {
            attempts += 1;
            if (attempts === 10) {
              clearInterval(interval);
              self.stk_waiting = false;
              attempts = 0;
              self.$toastr.e("Took too long to perform payment.Retry confirm", "Timeout");
            }
          }
        }).catch(err => {
          self.stk_waiting = false;
          clearInterval(interval);
          self.$toastr.e(err.response.data.error.message);
        });
      }, 5000);
    },

    companysearch() {
      this.$axios.get('/api/v1/companies/search/' + this.$route.params.id).then(response => {
        if (response.data.remaining_directors === 0) {
          this.$toastr.s('The company search was successful.', 'Success');
        } else {
          this.$toastr.s('The company search was successful. You may now add directors', 'Success');
        }
        this.$router.push({name: 'companies.directors.create', params: {id: this.$route.params.id}});
      }).catch(error => {
        this.error = error.response;
        this.$toastr.w(error.response.data.error, 'Error');
        if (error.response.data === "You have already performed this search before!") {
          this.$router.push({name: 'companies.directors.create', params: {id: this.$route.params.id}});
        }
      });
    }
  }
}
</script>

<style lang="scss">
#company-payment {
  .mpesa {
    padding: 20px 0;

    .manual {
      border-left: 1px dotted #aaa;
      padding-left: 20px;

      @media (max-width: 480px) {
        border-top: solid 1px #ddd;
        margin-top: 30px;
        padding-top: 10px;
        border-left: none;
        padding-left: 0;
      }
    }
  }
}
</style>
