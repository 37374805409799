<template>
  <div>
    <div>
      <button
        v-if="$can.view($store.getters.getAuthUser, 'create-roles')"
        type="button"
        class="btn btn-black pull-right"
        @click.prevent="roleModal(null)"
      >
        Create Role
      </button>
      <h4>User Roles</h4>
    </div>
    <table class="table table-striped table-responsive-sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Created At</th>
          <th v-if="$can.view($store.getters.getAuthUser, 'create-roles')">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(role, i) in roles"
          :key="role.id"
        >
          <td>{{ i+1 }}</td>
          <td>{{ role.name | unslug }}</td>
          <td>{{ role.created_at | stampTodateFilter }}</td>
          <td v-if="$can.view($store.getters.getAuthUser, 'create-roles')">
            <button
              type="button"
              class="btn btn-sm btn-info"
              @click.prevent="roleModal(role)"
            >
              <i
                class="fa fa-edit"
              /> Edit
            </button>
            <button
              type="button"
              class="btn btn-sm ml-1 btn-danger"
              @click.prevent="deleteRole(role)"
            >
              <i
                class="fa fa-trash"
              /> Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <modal
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5 v-if="role">
          Edit Role
        </h5>
        <h5 v-else>
          Create New Role
        </h5>
      </template>
      <form @submit.prevent="save">
        <div class="row">
          <div
            class="col-md-12"
            v-html="$error.handle(error)"
          />
          <div class="form-group col-md-12">
            <label>Name</label>
            <input
              v-model="name"
              type="text"
              class="form-control"
            >
          </div>
          <div class="form-group col-md-12">
            <button
              class="btn btn-primary pull-right"
              type="submit"
            >
              <span v-if="role">Update</span>
              <span v-else>Create</span>
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {

  data() {
    return {
      roles: [],
      showModal: false,
      role: null,
      name: '',
      error: ''
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/roles').then(response => {
        this.roles = response.data;
        this.loading = false;
      });
    },
    save() {
      if (this.role) {
        this.edit();
        return;
      }
      this.$axios.post('/api/v1/roles/create', {'name': this.name.replace(/ /g, '_')}).then(() => {
        this.showModal = false;
        this.fetch();
        this.name = '';
        this.role = null;
        this.$toastr.s('Role Created');
      });
    },
    edit() {
      this.role.name = this.name.replace(/ /g, '_');
      this.$axios.post('/api/v1/roles/update', this.role).then(() => {
        this.showModal = false;
        this.fetch();
        this.name = '';
        this.role = null;
        this.$toastr.s('Role updated');
      }).catch(error => {
        this.error = error.response;
      });
    },
    deleteRole(role) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the role " + role.name + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.post('/api/v1/roles/delete', role).then(() => {
            this.fetch();
            this.role = null;
            this.$toastr.s('Role deleted');
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          });
        }
      })
    },
    roleModal(role) {
      if (role != null) {
        this.role = role;
        this.name = role.name;
      } else {
        this.role = null;
      }
      this.showModal = true;
    }
  }
}
</script>
