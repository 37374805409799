<template>
  <div>
    <loading v-if="!is_loaded" />
    <template v-else>
      <div class="row mb-3">
        <div class="input-group col-md-4">
          <input
            v-model="search"
            class="form-control py-2 border-right-0 border"
            type="search"
            placeholder="Search ..."
          >
          <span class="input-group-append">
            <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
            >
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
      <table class="table table-striped table-responsive-sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>ID Number</th>
            <th>Status</th>
            <th>Created At</th>
            <th v-if="$can.view($store.getters.getAuthUser, 'view-user')">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(u, i) in users"
            :key="i"
          >
            <td>{{ pagination.from + i }}</td>
            <td>{{ fullname(u) }}</td>
            <td>{{ u.email }}</td>
            <td>{{ u.phone_number }}</td>
            <td>{{ u.id_number }}</td>
            <td>{{ u.active ? 'Active' : 'Inactive' }}</td>
            <td>{{ u.created_at }}</td>
            <td>
              <router-link
                :to="{name: 'users.show', params: { id: u.user_unique_id }}"
                class="btn btn-sm btn-warning"
              >
                show
              </router-link>
              <button
                v-if="can_whitelist(u.stage)"
                type="button"
                class="btn btn-sm ml-1 btn-info"
                @click.prevent="fetchWhitelisters(u)"
              >
                <i
                  class="fa fa-check"
                /> Whitelist
              </button>
            </td>
          </tr>
          <tr v-if="!users.length">
            <td :colspan="$can.view($store.getters.getAuthUser, 'view-user') ? 8 : 7">
              <div class="alert alert-info">
                No customers found
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="5"
        @paginate="fetch"
      />
    </template>
    <modal
      v-if="user"
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5>WHITELIST USER</h5>
      </template>
      <form>
        <div class="container">
          <div class="row">
            <div class="col-md-12 form-group">
              <label>Name : </label>
              <input
                v-model="user.name"
                class="form-control"
                readonly
              >
            </div>
            <div
              class="col-md-12 form-group"
              :class="{ 'has-feedback has-error': errors.has('reason')}"
            >
              <label>Reason</label>
              <textarea
                v-model="form.reason"
                v-validate="'required'"
                class="form-control"
                placeholder="Reason user is to be whitelisted"
                name="reason"
              />
              <small
                v-if="errors.has('reason')"
                class="help-block"
              >
                {{ errors.first('reason') }}
              </small>
            </div>
            <table class="table table-bordered">
              <tr v-if="user.maker">
                <td><b>Maker: </b><span v-text="user.maker" /></td>
                <td><b>Reason: </b> <span v-text="user.maker_reason" /></td>
              </tr>
              <tr v-if="user.checker">
                <td><b>Checker: </b><span v-text="user.checker" /></td>
                <td><b>Reason: </b><span v-text="user.checker_reason" /></td>
              </tr>
            </table>
            <div class="col-md-12 form-group">
              <button
                v-if="user.stage"
                class="btn btn-danger pull-right"
                type="button"
                @click="submitForm('rejected')"
              >
                <i class="fa fa-ban" /> Reject
              </button>
              <button
                class="btn btn-success mr-2 pull-right"
                type="button"
                @click="submitForm('approved')"
              >
                <i class="fa fa-check-circle" /> Approve
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
export default {
  mixins: [helper],
  data() {
    return {
      users: [],
      user: {
        id: null,
        stage: null,
        name: '',
        checker: '',
        maker: '',
        checker_reason: '',
        maker_reason: '',
      },
      form: {
        stage: null,
        user_id: null,
        reason: '',
        status: ''
      },
      is_loaded: false,
      showModal: false,
      search: '',
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    },
  },
  mounted() {
    this.fetch();
  },

  methods: {
    can_whitelist(stage) {
      return (this.$can.view(this.$store.getters.getAuthUser, 'whitelist') && stage === 0) ||
          (this.$can.view(this.$store.getters.getAuthUser, 'approve-whitelist') && stage === 1);
    },
    fetchWhitelisters(u) {
      this.loading = true;
      this.$axios.get(`/api/v1/users/'${u.id}/whitelisters`).then(response => {
        this.loading = false;
        this.showModal = true;
        this.user = response.data;
      });
    },
    submitForm(status) {
      this.form.status = status;
      this.form.user_id = this.user.id;
      this.form.stage = this.user.stage;
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.showModal = false;
          this.loading = true;
          this.$axios.post(`/api/v1/users/'unban`, this.form).then(() => {
            this.$toastr.s('User Whitelisted');
            this.$router.go();
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          }).then(() => {
            this.loading = false;
          });
        }
      });
    },
    fetch() {
      this.$axios.get('/api/v1/users/banned', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.users = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 2000ms
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    }
  }
}
</script>
