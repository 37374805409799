<template>
  <div
      v-if="page_loaded"
      id="company-payment"
      class="card"
  >
    <div v-html="$error.handle(error)"/>
    <div
        v-if="can_pay_via_account"
        class="row"
    >
      <div
          v-if="!can_pay"
          class="alert alert-info"
      >
        <h4>Please add CustomerID and Account Number Details to be able to pay from Customer Bank Account!</h4>
      </div>
      <template v-else>
        <div class="col-md-6">
          <p><strong>Company: </strong><span v-text="company"/></p>
          <p><strong>Account No: </strong><span v-text="account_no"/></p>
          <p><strong>Customer ID: </strong><span v-text="customer_id"/></p>
          <p><strong>Account Balance:</strong> KES {{ $number.format(bank_balance) }}</p>
        </div>
        <div class="col-md-6">
          <p><strong>Cover Type:</strong> {{ cover }} </p>
          <p>
            <strong v-text="cover_type"></strong>
            {{ currency }} {{ $number.format(performance_amount) }}
          </p>
          <p><strong>Commission:</strong> {{ currency }} {{ $number.format(commission) }} </p>
          <p><strong>Total:</strong> {{ currency }} {{ $number.format(total) }} </p>
        </div>
        <div
            v-if="bank_balance > total"
            class="col-md-12"
        >
          <template
              v-if="$store.getters.getAuthUser.role === 'customer' || $store.getters.getAuthUser.role === 'agent'"
          >
            <a
                v-if="! loading"
                class="btn btn-primary"
                href="#"
                @click.prevent="sendOTP"
            >Pay From
              Bank</a>
            <a
                v-else
                class="btn btn-primary btn-loading"
                href="#"
            >Sending
              OTP...</a>
          </template>
          <template v-else>
            <a
                v-if="! loading"
                class="btn btn-primary"
                href="#"
                @click.prevent="payWithBank"
            >Pay From
              Bank</a>
            <a
                v-else
                class="btn btn-primary btn-loading"
                href="#"
            >Authorizing
              Payment...</a>
          </template>
        </div>
      </template>
    </div>
    <modal
        :show-modal="showOTPForm"
        @close="showOTPForm = false"
    >
      <template v-slot:header>
        <h5>Enter OTP to authorize payment</h5>
      </template>
      <div v-html="$error.handle(error)"/>
      <form
          class="row"
          @submit.prevent="validateForm()"
      >
        <div
            v-for="(r, i) in otps"
            :key="i"
            class="form-group col-md-4"
        >
          <label>OTP</label>
          <input
              v-model="otps[i]"
              v-validate="'required'"
              :name="'otp_'+i"
              class="form-control"
              data-vv-as="otp"
              type="text"
          >
          <div :class="{'help-block': errors.has('otp_'+i)}">
            {{ errors.first('otp_' + i) }}
          </div>
        </div>
        <div class="form-group col-md-12">
          <button
              class="btn btn-success"
              type="submit"
          >
            Authorize
          </button>
        </div>
      </form>
    </modal>
  </div>
  <loading v-else :message="message"/>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      message: 'Loading ...',
      error: '',
      commission: 0,
      amount: 0,
      customer_id: '',
      account_no: '',
      company: '',
      bank_balance: 0,
      can_pay: false,
      loading: false,
      page_loaded: false,
      showOTPForm: false,
      otps: [],
      attempts: 0,
      cover: 'Labor',
      secured: false,
      company_secured:false,
      currency: 'KES',
      performance_amount: null,
      performance_kes_amount: null,
      pbalance: null
    }
  },
  computed: {
    ...mapGetters({
      current_user: 'getAuthUser',
      labor_limit: 'getLaborLimit',
    }),
    account() {
      return this.$route.params.secret;
    },
    can_pay_via_account() {
      return this.$can.view(this.current_user, 'pay-via-account');
    },
    cover_type() {
      let state = 'Cash Cover & Contract Value :';
      // non labor or unsecured counterparty
      if (!this.secured) return state;

      if (this.cover === 'Labor') {
        if(!this.company_secured){ //company not approved for unsecure
          return state;
        }
        if (this.performance_kes_amount < this.labor_limit) {
          return 'Contract Value';
        }
        if (this.performance_kes_amount < this.pbalance) state = 'Limit To Use :';
      }
      return state;
    },
    total() {
      if (this.cover_type === 'Cash Cover & Contract Value :'){
        return this.commission + this.performance_amount;
      } else {
        return this.commission;
      }
    }
  },
  watch: {
    current_user() {
      if (this.$can.view(this.current_user, 'pay-via-account')) {
        this.getBankBalance();
      }
    }
  },
  mounted() {
    this.fetch();
    if (this.$can.view(this.current_user, 'pay-via-account')) {
      this.getBankBalance();
    }
  },
  methods: {
    async fetch() {
      this.page_loaded = false;
      await this.$axios.get('/api/v1/performances/cost?secret=' + this.$route.params.secret)
          .then(response => {
            this.page_loaded = true;
            this.cover = response.data.cover;
            this.secured = response.data.secured;
            this.company_secured = response.data.company_secured;
            this.performance_amount = response.data.performance_amount;
            this.performance_kes_amount = response.data.performance_kes_amount;
            this.pbalance = response.data.balance;
            this.commission = response.data.total;
            this.amount = response.data.total;
            this.currency = response.data.currency;
            this.getLaborLimit();
          });
    },
    async getLaborLimit() {
      if (this.cover === 'Labor' && !this.labor_limit) {
        this.setLoader('Loading ...', false);
        await this.$store.dispatch("fetchLaborLimit").then(() => {
          this.setLoader();
        });
      }
    },
    async getBankBalance() {
      this.attempts++;
      this.setLoader('Fetching Bank Account Details ...', false);
      await this.$axios.post('/api/v1/companies/can-make-bank-payment', {
        company_id: this.$route.params.company
      }).then(response => {
        this.can_pay = response.data.can_pay;
        if (this.can_pay) {
          this.setLoader();
          this.bank_balance = response.data.balance;
          this.customer_id = response.data.customer_id;
          this.account_no = response.data.account;
          this.company = response.data.company;
        } else {
          if (this.attempts < 3) {
            setTimeout(() => {
              this.getBankBalance();
            }, 4500);
          } else {
            this.setLoader();
          }
        }
      }).catch(err => {
        this.setLoader();
        this.$toastr.e(err.response.data.error.message);
      });
    },
    async sendOTP() {
      this.otps = [];
      await this.$axios.post('/api/v1/approvers/otp', {
        company_unique_id: this.$route.params.company
      }).then(response => {
        for (let i = 0; i < response.data.approvers; i++) {
          this.otps.push('');
        }
        this.showOTPForm = true;
      }).catch(err => {
        this.error = err.response;
      });
    },
    async payWithBank() {
      this.loading = true;
      await this.$axios.post('/api/v1/make-bank-payment', {
        account: this.$route.params.secret,
        company_id: this.$route.params.company,
        otp: this.otps,
        performance
      }).then(response => {
        this.loading = false;
        if (response.data.status === 'success') {
          this.showOTPForm = false;
          this.$toastr.s(response.data.message);
          this.checkExists();
        } else {
          this.$toastr.e(response.data.message);
        }
      }).catch(err => {
        this.loading = false;
        this.error = err.response;
        this.showOTPForm = true;
        this.$toastr.e(err.response.data.error.message);
      });
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) this.payWithBank()
      });
    },
    setLoader(msg = 'Loading ...', state = true) {
      this.message = msg
      this.page_loaded = state;
    },
    stopInterval(interval) {
      this.loading = false;
      clearInterval(interval);
    },
    checkExists() {
      this.$toastr.i("Performance Bond payment successfully received!");
      this.notifyChecking();
      const self = this;
      let attempts = 0;
      let interv = setInterval(function () {
        self.$axios.post('/api/v1/performances/exists', {
          secret: self.account
        }).then(response => {
          if (response.data.exists) {
            clearInterval(interv);
            self.$toastr.i("Performance bond generation successfully!");
            self.$router.push({
              name: 'performance.show',
              params: {secret: self.$route.params.secret}
            });
          } else {
            attempts += 1;
            if (attempts === 10) {
              clearInterval(interv);
              attempts = 0;
              self.$toastr.e("Performance processing took too long.If you do not receive the bid bond in your email in the next 5 minutes contact support", "Performance Delay");
              self.$router.push({name: 'performance.index'});
            } else {
              self.notifyChecking();
            }
          }
        }).catch(err => {
          self.stopInterval(interv);
          self.error = err.response;
        });
      }, 6000);
    },
    notifyChecking() {
      this.$swal({
        title: 'Performance Bond Processing',
        text: "Waiting for performance bond to complete processing ...",
        icon: 'info',
        timer: 6000,
        timerProgressBar: true,
      });
    }
  }
}
</script>
