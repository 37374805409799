<template>
  <div
    id="company-payment"
    class="card"
  >
    <div v-html="$error.handle(error)" />
    <nav>
      <div
        id="nav-tab"
        class="nav nav-tabs nav-fill"
        role="tablist"
      >
        <a
          id="nav-profile-tab"
          class="nav-item nav-link active"
          data-toggle="tab"
          href="#nav-profile"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="true"
        ><i class="fa fa-google-wallet" /> Pay via Wallet</a>
        <a
          id="nav-home-tab"
          class="nav-item nav-link"
          data-toggle="tab"
          href="#nav-home"
          role="tab"
          aria-controls="nav-home"
          aria-selected="false"
        ><i class="fa fa-mobile" /> Pay via MPesa</a>
        <a
          v-if="can_pay_via_account"
          id="nav-bank-tab"
          class="nav-item nav-link"
          data-toggle="tab"
          href="#nav-bank"
          role="tab"
          aria-controls="nav-bank"
          aria-selected="false"
        ><i class="fa fa-bank" /> Pay via Bank Account</a>
      </div>
    </nav>
    <div
      id="nav-tabContent"
      class="tab-content"
    >
      <div
        id="nav-profile"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div style="padding: 3rem;">
          <h4>Available Balance: KES {{ $number.format(balance) }}</h4>
          <p>Cost: KES {{ $number.format(amount) }} </p>
          <template v-if="balance >= amount">
            <a
              v-if="! loading"
              href="#"
              class="btn btn-primary"
              @click.prevent="payWithWallet"
            >Pay From
              Wallet</a>
            <a
              v-if="loading"
              href="#"
              class="btn btn-primary btn-loading"
              @click.prevent="payWithWallet"
            >Processing
              Payment...</a>
          </template>
        </div>
      </div>
      <div
        id="nav-home"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="mpesa">
          <div class="row">
            <div class="col-md-6">
              <div class="stk-push">
                <h6>Option One</h6>
                <p>
                  Enter your phone number (07XXXXXXXX) to receive a payment request on your phone. All
                  you have to do is input your m-pesa pin.
                </p>
                <form
                  data-vv-scope="form1"
                  @submit.prevent="validateForm('form1')"
                >
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input
                      v-model="stk_phone_number"
                      v-validate="'required|digits:10'"
                      type="number"
                      class="form-control"
                      name="phone"
                      data-vv-scope="form1"
                      :class="{'is-invalid': errors.has('form1.phone')}"
                    >
                    <div :class="{'help-block': errors.has('form1.phone')}">
                      {{ errors.first('form1.phone') }}
                    </div>
                  </div>
                  <div
                    v-if="!exceeds_mpesa"
                    class="form-group"
                  >
                    <a
                      v-if="stk_waiting"
                      href="#"
                      class="btn btn-primary pull-right btn-loading"
                      @click.prevent="requestSTK"
                    >
                      <i class="fa fa-spin fa-spinner" />
                      Waiting for payment...
                    </a>
                    <button
                      v-else
                      type="submit"
                      class="btn btn-primary pull-right"
                    >
                      Request Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6">
              <div class="manual">
                <h6>Option Two</h6>
                <p>Follow the instructions below:</p>
                <ul>
                  <li>Go to m-pesa menu</li>
                  <li>Select lipa na mpesa</li>
                  <li>Select pay bill</li>
                  <li>Enter paybill number <strong>{{ mpesa_paybill }}</strong></li>
                  <li>Enter account number <strong>BP{{ account }}</strong></li>
                  <li>Enter the amount <strong>KES {{ $number.format(amount) }}</strong></li>
                  <li>Enter your pin and send</li>
                </ul>
                <p>
                  Once you receive the confirmation SMS, enter the transaction code in the box
                  below
                </p>
                <form
                  data-vv-scope="form2"
                  @submit.prevent="validateForm('form2')"
                >
                  <div class="form-group">
                    <label>Transaction Code</label>
                    <input
                      v-model="transaction_code"
                      v-validate="'required'"
                      type="text"
                      name="transaction_code"
                      placeholder="XXXXXX"
                      class="form-control"
                      :class="{'is-invalid': errors.has('form2.transaction_code')}"
                    >
                    <div :class="{'help-block': errors.has('form2.transaction_code')}">
                      {{ errors.first('form2.transaction_code') }}
                    </div>
                  </div>
                  <div class="form-group">
                    <a
                      v-if="loading"
                      href="#"
                      class="btn btn-primary pull-right btn-loading"
                      @click.prevent="confirmTrx"
                    ><i
                      class="fa fa-spin fa-spinner"
                    /> Verifying payment</a>
                    <button
                      v-else
                      type="submit"
                      class="btn btn-primary pull-right"
                    >
                      Verify
                      Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="can_pay_via_account"
        id="nav-bank"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="nav-bank-tab"
      >
        <div style="padding: 3rem;">
          <div
            v-if="!can_pay"
            class="alert alert-info"
          >
            <h4 v-text="message" />
          </div>
          <template v-else>
            <p><strong>Company: </strong><span v-text="company" /></p>
            <p><strong>Account No: </strong><span v-text="account_no" /></p>
            <p><strong>Customer ID: </strong><span v-text="customer_id" /></p>
            <p><strong>Account Balance:</strong> KES {{ $number.format(bank_balance) }}</p>
            <p><strong>Cost:</strong> KES {{ $number.format(amount) }} </p>
            <template v-if="bank_balance > amount">
              <template v-if="$store.getters.getAuthUser.role === 'customer' || $store.getters.getAuthUser.role === 'agent'">
                <a
                  v-if="! loading"
                  href="#"
                  class="btn btn-primary"
                  @click.prevent="sendOTP"
                >Pay From
                  Bank</a>
                <a
                  v-else
                  href="#"
                  class="btn btn-primary btn-loading"
                >Sending
                  OTP...</a>
              </template>
              <template v-else>
                <a
                  v-if="! loading"
                  href="#"
                  class="btn btn-primary"
                  @click.prevent="payWithBank"
                >Pay From
                  Bank</a>
                <a
                  v-else
                  href="#"
                  class="btn btn-primary btn-loading"
                >Authorizing
                  Payment...</a>
              </template>
            </template>
          </template>
        </div>
      </div>
      <modal
        :show-modal="showOTPForm"
        @close="showOTPForm = false"
      >
        <template v-slot:header>
          <h5>Enter OTP to authorize payment</h5>
        </template>
        <div v-html="$error.handle(error)" />
        <form
          class="row"
          data-vv-scope="form3"
          @submit.prevent="validateForm('form3')"
        >
          <div
            v-for="(r, i) in otps"
            :key="i"
            class="form-group col-md-4"
          >
            <label>OTP</label>
            <input
              v-model="otps[i]"
              v-validate="'required'"
              type="text"
              :name="'otp_'+i"
              data-vv-as="otp"
              class="form-control"
            >
            <div :class="{'help-block': errors.has('form3.otp_'+i)}">
              {{ errors.first('form3.otp_' + i) }}
            </div>
          </div>
          <div class="form-group col-md-12">
            <button
              class="btn btn-success"
              type="submit"
            >
              Authorize
            </button>
          </div>
        </form>
      </modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      message: 'Loading ...',
      error: '',
      stk_phone_number: '',
      transaction_code: '',
      amount: '',
      balance: 0,
      customer_id: '',
      account_no: '',
      company: '',
      bank_balance: 0,
      can_pay: false,
      stk_waiting: false,
      loading: false,
      showOTPForm: false,
      otps: [],
    }
  },
  computed: {
    ...mapGetters({
      current_user: 'getAuthUser',
    }),
    account() {
      return this.$route.params.id;
    },
    exceeds_mpesa() {
      return this.amount > 1000000;
    },
    mpesa_paybill() {
      return process.env.VUE_APP_MPESA_PAYBILL;
    },
    can_pay_via_account() {
      return this.$can.view(this.current_user, 'pay-via-account');
    }
  },
  watch: {
    current_user() {
      if (this.$can.view(this.current_user, 'pay-via-account')) {
        this.getBankBalance();
      }
    }
  },
  mounted() {
    this.fetch();
    this.fetchBalance();
    if (this.$can.view(this.current_user, 'pay-via-account')) {
      this.getBankBalance();
    }
  },
  methods: {
    fetch() {
      this.$axios.post('/api/v1/bid-bonds/cost', {
        secret: this.$route.params.id
      }).then(response => {
        this.amount = response.data.total;
      });
    },
    fetchBalance() {
      this.$axios.post('/api/v1/wallets/balance', {
        company: this.$route.params.co
      }).then(response => {
        this.balance = response.data.balance;
      }).catch(err => {
        this.error = err.response;
      });
    },
    payWithWallet() {
      this.$axios.post('/api/v1/initiate-wallet-payment', {
        company: this.$route.params.co,
        bidbond: this.$route.params.id,
        amount: this.amount,
        currency: 'KES'
      }).then(response => {
        if (response.data.status === 'success') {
          this.$toastr.s(response.data.message);
          this.checkExists();
        } else {
          this.$toastr.e(response.data.message);
        }
      }).catch(err => {
        this.$toastr.e(err.response.data.error.message);
      });
    },
    getBankBalance() {
      this.$axios.post('/api/v1/companies/can-make-bank-payment', {
        company_id: this.$route.params.co
      }).then(response => {
        this.can_pay = response.data.can_pay;
        if (this.can_pay) {
          this.message = "";
          this.bank_balance = response.data.balance;
          this.customer_id = response.data.customer_id;
          this.account_no = response.data.account;
          this.company = response.data.company;
        }
      }).catch(err => {
        this.message = "Cannot transact via Bank Account";
        this.$toastr.e(err.response.data.error.message);
      });
    },
    sendOTP() {
      this.otps = [];
      this.$axios.post('/api/v1/approvers/otp', {
        company_unique_id: this.$route.params.co
      }).then(response => {
        for (let i = 0; i < response.data.approvers; i++) {
          this.otps.push('');
        }
        this.showOTPForm = true;
      }).catch(err => {
        this.error = err.response;
      });
    },
    payWithBank() {
      this.loading = true;
      this.$axios.post('/api/v1/make-bank-payment', {
        account: this.$route.params.id,
        company_id: this.$route.params.co,
        otp: this.otps
      }).then(response => {
        this.loading = false;
        if (response.data.status === 'success') {
          this.showOTPForm = false;
          this.$toastr.s(response.data.message);
          this.checkExists();
        } else {
          this.$toastr.e(response.data.message);
        }
      }).catch(err => {
        this.loading = false;
        this.error = err.response;
        this.showOTPForm = true;
        this.$toastr.e(err.response.data.error.message);
      });
    },
    validateForm(scope) {
      return this.$validator.validateAll(scope).then(result => {
        if (result) {
          if (scope === 'form1') this.requestSTK();
          if (scope === 'form2') this.confirmTrx();
          if (scope === 'form3') this.payWithBank()
        }
      });
    },
    requestSTK() {
      this.error = "";
      this.stk_waiting = true;
      this.$axios.post('/api/v1/initiate-stk', {
        phone: this.stk_phone_number,
        account: 'BP' + this.account,
        amount: this.amount
      }).then(() => {
        this.$toastr.i("A push notification of KES " + this.amount + " for payment has been sent to " + this.stk_phone_number);
        this.confirmPayment();
      }).catch(err => {
        this.stk_waiting = false;
        this.error = err.response;
        this.$toastr.e(err.response.data.error.message);
      });
    },
    confirmPayment() {
      const self = this;
      let attempts = 0;
      self.loading = true;
      let interval = setInterval(function () {
        self.$axios.post('/api/v1/confirm-payment', {
          account: 'BP' + self.account
        }).then(response => {
          if (response.data.confirmed) {
            self.stopInterval(interval);
            self.$toastr.i("Bid bond payment successfully received! Please wait while your bid bond is being generated");
            self.$router.push({
              name: 'bidbonds.show',
              params: {id: self.$route.params.id}
            });
          } else {
            attempts += 1;
            if (attempts === 10) {
              self.stopInterval(interval);
              attempts = 0;
              self.$toastr.e("Took too long to perform payment.Retry confirm", "Timeout");
            }
          }
        }).catch(err => {
          self.stopInterval(interval);
          this.error = err.response;
        });
      }, 5000);
    },
    stopInterval(interval) {
      this.stk_waiting = false;
      this.loading = false;
      clearInterval(interval);
    },
    confirmTrx() {
      this.error = "";
      this.loading = true;
      this.$axios.post('/api/v1/confirm-transaction', {
        account: 'BP' + this.account,
        transaction_code: this.transaction_code
      }).then(response => {
        if (response.data.confirmed) {
          this.checkExists();
        } else {
          this.loading = false;
          this.$toastr.e("Could not find this payment", "Payment not found");
        }
      }).catch(err => {
        this.loading = false;
        this.error = err.response;
      });
    },
    checkExists() {
      this.$toastr.i("Bid Bond payment successfully received!");
      this.notifyChecking();
      const self = this;
      let attempts = 0;
      let interv = setInterval(function () {
        self.$axios.post('/api/v1/bid-bonds/exists', {
          secret: self.account
        }).then(response => {
          if (response.data.exists) {
            clearInterval(interv);
            self.$toastr.i("Bid bond generation successfully!");
            self.$router.push({
              name: 'bidbonds.show',
              params: {id: self.$route.params.id}
            });
          } else {
            attempts += 1;
            if (attempts === 10) {
              clearInterval(interv);
              attempts = 0;
              self.$toastr.e("Bid Bond processing took too long.If you do not receive the bid bond in your email in the next 5 minutes contact support", "Bidbond Delay");
              self.$router.push({name: 'bidbonds.index'});
            } else {
              self.notifyChecking();
            }
          }
        }).catch(err => {
          self.stopInterval(interv);
          self.error = err.response;
        });
      }, 6000);
    },
    notifyChecking() {
      this.$swal({
        title: 'Bid Bond Processing',
        text: "Waiting for bid bond to complete processing ...",
        icon: 'info',
        timer: 6000,
        timerProgressBar: true,
      });
    }
  }
}
</script>

<style lang="scss">
#company-payment {
  .mpesa {
    padding: 20px 0;

    .manual {
      border-left: 1px dotted #aaa;
      padding-left: 20px;

      @media (max-width: 480px) {
        border-top: solid 1px #ddd;
        margin-top: 30px;
        padding-top: 10px;
        border-left: none;
        padding-left: 0;
      }
    }
  }
}
</style>
