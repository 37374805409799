<template>
  <div>
    <template v-if="loading">
      <loading/>
    </template>
    <template v-else>
      <div v-if="$can.view($store.getters.getAuthUser, 'create-counterparties')">
        <router-link
            :to="{ name: 'performance.counterparties.create'}"
            class="btn btn-sm btn-black pull-right"
        >
          Create
          New
        </router-link>
      </div>
      <hr>
      <table
          ref="table"
          class="table table-striped table-responsive-sm"
      >
        <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Physical Address</th>
          <th>Postal Address</th>
          <th>Type</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(party, i) in counterparties"
            :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ party.name }}</td>
          <td>{{ party.physical_address }}</td>
          <td>{{ party.postal_address }}</td>
          <td>{{ party.secured ? 'Secured' : 'Unsecured' }}</td>
          <td>
            <div class="btn btn-group">
              <button
                  v-if="$can.view($store.getters.getAuthUser, 'edit-counterparties')"
                  class="btn btn-sm btn-info"
                  type="button"
                  @click.prevent="edit(party)"
              >
                Edit
              </button>
              <button
                  v-if="$can.view($store.getters.getAuthUser, 'delete-counterparties')"
                  class="btn btn-sm btn-danger"
                  @click.prevent="deleteParty(party)"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <modal v-if="counterparty.postal_code_id"
           :show-modal="showModal"
           @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit Counterparty</h5>
      </template>
      <form @submit.prevent="update">
        <div v-html="$error.handle(error)"/>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Name</label>
            <input
                v-model="counterparty.name"
                class="form-control"
                required
                type="text"
            >
          </div>
          <div class="col-md-6 form-group">
            <label>Physical Address</label>
            <input
                v-model="counterparty.physical_address"
                class="form-control"
                required
                type="text"
            >
          </div>
          <div class="col-md-6 form-group">
            <label>Postal Address</label>
            <input
                v-model="counterparty.postal_address"
                class="form-control"
                required
                type="text"
            >
          </div>
          <div class="col-md-6 form-group">
            <label>Category</label>
            <v-select
                v-model="counterparty.category_id"
                :options="categories"
                :reduce="x => x.id"
                label="name"
                placeholder="Category"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Postal Code</label>
            <v-select
                v-model="counterparty.postal_code_id"
                :options="postalcodes"
                :reduce="x => x.id"
                label="code"
                placeholder="Postal Code"
                @input="getCounterparty"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Postal Name</label>
            <input
                v-model="counterparty.county"
                class="form-control"
                readonly=""
                required
                type="text"
            >
          </div>
          <div class="col-md-6 form-group">
            <label>Type</label>
            <v-select
                v-model="counterparty.secured"
                v-validate="'required'"
                :options="secure_states"
                :reduce="c => c.value"
                name="type"
            ></v-select>
            <small
                v-if="errors.has('type')"
                class="help-block"
            >{{ errors.first('type') }}</small>
          </div>
          <div class="col-md-12 form-group">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import $ from 'jquery';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      loading: false,
      showModal: false,
      dt: "",
      counterparty: {
        name: '',
        postal_code_id: null,
        category_secret: '',
        secured: false,
        county: ''
      },
      error: '',
    }
  },
  computed: {
    ...mapGetters({
      counterparties: 'getPerformanceParties',
      postalcodes: 'getPostalCodes',
      categories: 'getCategories',
      secure_states: 'getSecureStates'
    })
  },
  watch: {
    counterparties() {
      if (this.dt) this.dt.destroy();
      setTimeout(() => {
        this.dt = $(this.$refs.table).DataTable();
      }, 1000);
    }
  },
  mounted() {
    setTimeout(() => {
      this.dt = $(this.$refs.table).DataTable();
    }, 1000);
    this.getCounterparties();
  },
  methods: {
    async getCounterparties() {
      if (!this.counterparties.length) {
        this.loading = true;
        await this.$store.dispatch("fetchPerformanceParties").then(() => {
          this.loading = false;
        });
      }
    },
    async getPostalCodes() {
      if (!this.postalcodes.length) {
        this.loading = true;
        await this.$store.dispatch("fetchPostalCodes").then(() => {
          this.loading = false;
        });
      }
    },
    async getCategories() {
      if (!this.categories.length) {
        this.loading = true;
        await this.$store.dispatch("fetchCategories").then(() => {
          this.loading = false;
        });
      }
    },
    edit(c) {
      this.getPostalCodes();
      this.getCategories();
      this.showModal = true;
      this.counterparty = c;
    },
    getCounterparty() {
      if (this.counterparty.postal_code_id) {
        this.counterparty.county = this.postalcodes.filter(x => x.id === this.counterparty.postal_code_id)[0].county;
      }
    },
    update() {
      this.$axios.put('/api/v1/performance-counterparties/' + this.counterparty.id, this.counterparty).then((response) => {
        this.showModal = false;
        this.$store.dispatch("setPerformanceParty", response.data.data);
        this.$toastr.s("Counterparty updated successfully")
      }).catch(error => {
        this.error = error.response;
      });
    },
    deleteParty(party) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the counterparty " + party.name + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/performance-counterparties/' + party.id).then(() => {
            this.$store.dispatch("deletePerformanceParty", party);
            this.$toastr.s("Counterparty deleted successfully");
          });
        }
      });
    }
  }
}
</script>
