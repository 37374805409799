<template>
  <div>
    <loading v-if="loading" />
    <template v-else>
      <div class="row mb-3">
        <div class="input-group col-md-4">
          <input
            v-model="search"
            class="form-control py-2 border-right-0 border"
            type="search"
            placeholder="Search By Name..."
          >
          <span class="input-group-append">
            <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
            >
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
      <table
        v-if="companies.length"
        class="table table-striped table-responsive-sm"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Reg Number</th>
            <th>Approval Status</th>
            <th>Banned</th>
            <th>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(company, i) in companies"
            :key="i"
          >
            <td>{{ i + pagination.from }}</td>
            <td>{{ company.name }}</td>
            <td>{{ company.crp }}</td>
            <td>{{ company.approval_status }}</td>
            <td>{{ company.banned }}</td>
            <td>
              <router-link
                :to="{name: 'companies.show', params: { id: company.company_unique_id }}"
                class="btn btn-sm btn-warning"
              >
                show
              </router-link>
              <button
                v-if="can_whitelist(company.stage)"
                type="button"
                class="btn btn-sm ml-1 btn-info"
                @click.prevent="fetchWhitelisters(company)"
              >
                <i
                  class="fa fa-check"
                /> Whitelist
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="alert alert-warning"
      >
        <p>There are no companies</p>
      </div>
      <pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="5"
        @paginate="fetch"
      />
      <modal
        v-if="company"
        :show-modal="showModal"
        @close="showModal = false"
      >
        <template v-slot:header>
          <h5>WHITELIST COMPANY</h5>
        </template>
        <form>
          <div class="container">
            <div class="row">
              <div class="col-md-12 form-group">
                <label>Company Name : </label>
                <input
                  v-model="company.name"
                  class="form-control"
                  readonly
                >
              </div>
              <div
                class="col-md-12 form-group"
                :class="{ 'has-feedback has-error': errors.has('reason')}"
              >
                <label>Reason</label>
                <textarea
                  v-model="form.reason"
                  v-validate="'required'"
                  class="form-control"
                  placeholder="Reason company is to be whitelisted"
                  name="reason"
                />
                <small
                  v-if="errors.has('reason')"
                  class="help-block"
                >
                  {{ errors.first('reason') }}
                </small>
              </div>
              <table class="table table-bordered">
                <tr v-if="company.maker">
                  <td><b>Maker: </b><span v-text="company.maker" /></td>
                  <td><b>Reason: </b> <span v-text="company.maker_reason" /></td>
                </tr>
                <tr v-if="company.checker">
                  <td><b>Checker: </b><span v-text="company.checker" /></td>
                  <td><b>Reason: </b><span v-text="company.checker_reason" /></td>
                </tr>
              </table>
              <div class="col-md-12 form-group">
                <button
                  v-if="company.stage"
                  class="btn btn-danger pull-right"
                  type="button"
                  @click="submitForm('rejected')"
                >
                  <i class="fa fa-ban" /> Reject
                </button>
                <button
                  class="btn btn-success mr-2 pull-right"
                  type="button"
                  @click="submitForm('approved')"
                >
                  <i class="fa fa-check-circle" /> Approve
                </button>
              </div>
            </div>
          </div>
        </form>
      </modal>
    </template>
  </div>
</template>

<script>
export default {

  data() {
    return {
      search: '',
      companies: [],
      company: {
        id: null,
        stage: null,
        name: '',
        checker: '',
        maker: '',
        checker_reason: '',
        maker_reason: '',
      },
      form: {
        stage: null,
        company_id: null,
        reason: '',
        status: ''
      },
      loading: true,
      showModal: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    },
  },
  mounted() {
    this.fetch();
  },

  methods: {
    can_whitelist(stage) {
      return (this.$can.view(this.$store.getters.getAuthUser, 'whitelist') && stage === 0) ||
          (this.$can.view(this.$store.getters.getAuthUser, 'approve-whitelist') && stage === 1);
    },
    fetchWhitelisters(co) {
      this.loading = true;
      this.$axios.get(`/api/v1/companies/${co.id}/whitelisters`).then(response => {
        this.loading = false;
        this.showModal = true;
        this.company = response.data;
      });
    },
    submitForm(status) {
      this.form.status = status;
      this.form.company_id = this.company.id;
      this.form.stage = this.company.stage;
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.showModal = false;
          this.loading = true;
          this.$axios.post(`/api/v1/company/unban`, this.form).then(() => {
            this.$toastr.s('Company Whitelisted');
            this.$router.go();
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          }).then(() => {
            this.loading = false;
          });
        }
      });
    },
    fetch() {
      this.$axios.get('/api/v1/companies/banned', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.loading = false;
        if (!response.data.data) return;
        this.companies = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    }
  }
}
</script>
