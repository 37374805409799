<template>
  <div>
    <h4>Update Performance Template</h4>
    <hr>
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label>Template Name</label>
          <input
            v-model="name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <tinymce
            id="one"
            v-model="my_data"
            html-class="large_editor"
            :other_options="editor_options"
          />
        </div>
        <div class="form-group">
          <a
            href="#"
            class="btn btn-primary"
            @click.prevent="submit"
          >Save</a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="variables">
          <h4>What you can use</h4>
          <table class="table table-bordered">
            <tbody>
              <tr
                v-for="(variable, definition) in variables"
                :key="definition"
              >
                <td>
                  <a
                    :id="variable"
                    href="#"
                    title="copy"
                    class="copy"
                    @click.prevent="copyVariable(variable)"
                  ><i class="fa fa-copy" /></a>
                  {{ definition }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from 'vue-tinymce-editor'
import {editor_data, copyVar} from "@/mixins/editor";

export default {
  components: {
    tinymce
  },
  data() {
    return editor_data
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/performance-templates/' + this.$route.params.id + '/edit').then(response => {
        this.name = response.data.template.name;
        this.my_data = response.data.template.content;
        this.variables = response.data.variables;
      });
    },
    copyVariable(val) {
      copyVar(val)
    },
    submit() {
      this.$axios.put('/api/v1/performance-templates/' + this.$route.params.id, {
        name: this.name,
        content: this.my_data
      }).then(() => {
        this.$router.push({name: 'performance.templates.show', params: {id: this.$route.params.id}});
      });
    }
  }
}
</script>
