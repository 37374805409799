<template>
  <fieldset class="container">
    <legend class="text-info">
      Create A User
    </legend>
    <div v-html="$error.handle(user_error)"/>
    <form @submit.prevent="validateForm">
      <div class="row">
        <div :class="{ 'has-feedback has-error': errors.has('first_name')}"
             class="col-md-6 form-group"
        >
          <label>First Name</label>
          <input
              v-model="user.firstname"
              v-validate="'required'"
              class="form-control"
              name="first_name"
              type="text"
          >
          <small
              v-if="errors.has('first_name')"
              class="help-block"
          >
            {{ errors.first('first_name') }}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Middle Name</label>
          <input
              v-model="user.middlename"
              class="form-control"
              type="text"
          >
        </div>
        <div :class="{ 'has-feedback has-error': errors.has('last_name')}"
             class="col-md-6 form-group"
        >
          <label>Last name</label>
          <input
              v-model="user.lastname"
              v-validate="'required'"
              class="form-control"
              name="last_name"
              type="text"
          >
          <small
              v-if="errors.has('last_name')"
              class="help-block"
          >
            {{ errors.first('last_name') }}
          </small>
        </div>

        <div :class="{ 'has-feedback has-error': errors.has('email')}"
             class="col-md-6 form-group"
        >
          <label>Email</label>
          <input
              v-model="user.email"
              v-validate="'required|email'"
              class="form-control"
              name="email"
              type="email"
          >
          <small
              v-if="errors.has('email')"
              class="help-block"
          >
            {{ errors.first('email') }}
          </small>
        </div>

        <div :class="{ 'has-feedback has-error': errors.has('phone_number')}"
             class="col-md-6 form-group"
        >
          <label>Phone </label>
          <phone-number
              v-model="user.phone_number"
              v-validate="'required|numeric|min:10|max:15'"
              name="phone_number"
          />
          <small
              v-if="errors.has('phone_number')"
              class="help-block"
          >
            {{ errors.first('phone_number') }}
          </small>
        </div>

        <div :class="{ 'has-feedback has-error': errors.has('id_number')}"
             class="col-md-6 form-group"
        >
          <label>ID Number </label>
          <input
              v-model="user.id_number"
              v-validate="'required'"
              class="form-control"
              name="id_number"
              type="text"
          >
          <small
              v-if="errors.has('id_number')"
              class="help-block"
          >
            {{ errors.first('id_number') }}
          </small>
        </div>

        <div class="col-md-6 form-group">
          <label>Role</label>
          <select
              v-model="user.role"
              v-validate="'required'"
              class="form-control"
              name="role"
          >
            <option
                v-for="role in roles"
                :key="role"
            >
              {{ role | unslug }}
            </option>
          </select>
          <small
              v-if="errors.has('id_number')"
              class="help-block"
          >
            {{ errors.first('id_number') }}
          </small>
        </div>
        <div v-if="user.role === 'Relationship Manager'" :class="{ 'has-feedback has-error': errors.has('branch')}"
             class="form-group col-md-6">
          <label>Branch</label>
          <v-select
              v-model="user.branch"
              v-validate="'required'"
              :options="branches"
              :reduce="c => c.id"
              label="name"
              name="branch"
              placeholder="Branch"
          />
          <div :class="{'text-danger': errors.has('branch')}">
            {{ errors.first('branch') }}
          </div>
        </div>
        <div class="col-md-12 form-group">
            <button class="btn btn-primary pull-right">
              <i class="fa fa-send"></i> Submit
            </button>
        </div>
      </div>
    </form>
  </fieldset>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  components: {},
  data() {
    return {
      roles: [],
      user: {
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        role: '',
        id_number: '',
        phone_number: '254',
        branch: null
      },
      user_error: '',
    }
  },
  computed: {
    ...mapGetters({
      branches: 'getBranches'
    }),
  },
  mounted() {
    this.fetchRoles();
    if (!this.branches.length) {
      this.$store.dispatch("fetchBranches");
    }
  },
  methods: {
    fetchRoles() {
      this.$axios.get('/api/v1/roles/other').then(response => {
        const r = response.data;
        this.roles = r.filter(x => x.name !== 'agent').map(x => x.name);
        this.loading = false;
      });
    },
    createUser() {
      let user = this.user;
      user.role = user.role.toLowerCase().replace(' ', '_');
      this.$axios.post('/api/v1/users', user).then(() => {
        this.$toastr.s('User ' + this.user.firstname + ' has been created');
        this.$router.push({name: "users.index"});
      }).catch(error => {
        this.user_error = error.response;
      });
    },

    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.createUser();
        }
      });
    },
  },
}
</script>
