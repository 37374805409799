<template>
  <div class="home">
    <h4 class="sub-heading">
      Tender Leads
    </h4>
    <div class="row">
      <div class="form-group col-md-3 col-md-push-3">
        <label class="fs">Start Date</label>
        <date-picker
          v-model="start.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">End Date</label>
        <date-picker
          v-model="to.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">Duration</label>
        <v-select
          v-model="duration"
          :options="['Today','This Week','This Month']"
          @input="setDuration"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">Counterparty</label>
        <v-select
          v-model="counterparty"
          :options="counterparties"
          @input="setCounterparty"
        />
      </div>
      <div
        class="alert alert-info col-md-12 alert-dismissible fade show"
        role="alert"
      >
        The following tenders have not yet been bid for on {{ app_name }}!
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <table
      ref="tb_tenders"
      class="table table-striped small table-responsive-sm"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Tender #</th>
          <th>Description</th>
          <th>Counterparty</th>
          <th>Type</th>
          <th>Category</th>
          <th>Closing Date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in tenders"
          :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item.tender_ref_no }}</td>
          <td>{{ item.tender_title }}</td>
          <td>{{ item.org_name }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.tender_category }}</td>
          <td>{{ item.closing_date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery';
import DatePicker from "vuejs-datepicker";
import moment from 'moment/src/moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      dt: "",
      counterparty:'',
      duration: 'Today',
      start: {
        date: moment().toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    tenders() {
      return this.$store.getters.getTenderLeads;
    },
    counterparties(){
      if(this.tenders.length){
        const orgs = this.tenders.map(x =>x.org_name);
        //remove duplicates
        return [ ...new Set(orgs) ].sort();
      }
      return [];
    },
    period() {
      return [this.start.date, this.to.date].join();
    },
    app_name() {
      return process.env.VUE_APP_NAME;
    },
  },
  watch: {
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
    tenders() {
      if (this.dt) this.dt.destroy();
      this.createDT();
    }
  },
  mounted() {
    this.createDT();
    this.getTenders();
  },
  methods: {
    createDT() {
      setTimeout(() => {
        this.dt = $(this.$refs.tb_tenders).DataTable({
          destroy: true,
          dom: 'Bfrtip',
          buttons: [{extend: 'csvHtml5', text: 'Excel'}]
        });
      }, 3000);
    },
    getTenders() {
      if (!this.tenders.length) {
        this.fetch();
      }
    },
    setDuration() {
      switch (this.duration) {
        case 'This Week':
          this.start.date = moment().startOf('week').toDate();
          this.to.date = moment().endOf('week').toDate();
          break;
        case 'This Month':
          this.start.date = moment().startOf('month').toDate();
          this.to.date = moment().endOf('month').toDate();
          break;
        default:
          this.start.date = moment().toDate();
          this.to.date = moment().toDate();
      }
    },
    setCounterparty(){
      this.dt.column(3).search(this.counterparty).draw();
    },
    fetch() {
      this.$store.dispatch('fetchTenderLeads', {start: this.start.date, end: this.to.date});
    }
  }
}
</script>
