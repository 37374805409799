<template>
  <fieldset>
    <legend class="text-info">
      Onboard a Company
    </legend>
    <div v-html="$error.handle(error)" />
    <form
      class="row"
      @submit.prevent="validateForm"
    >
      <div
        class="form-group col-md-6"
        :class="{ 'has-feedback has-error': errors.has('company_name')}"
      >
        <label>Company Name</label>
        <input
          v-model="name"
          v-validate="'required'"
          type="text"
          class="form-control"
          name="company_name"
        >
        <small
          v-if="errors.has('company_name')"
          class="help-block"
        >
          {{ errors.first('company_name') }}
        </small>
      </div>
      <div class="col-md-6">
        <div
          class="form-group"
          :class="{ 'has-feedback has-error': errors.has('registration_number')}"
        >
          <label>Registration Number</label>
          <input
            v-model="crp"
            v-validate="'required'"
            type="text"
            class="form-control"
            placeholder="e.g CPR/2001/2650"
            name="registration_number"
          >
          <small
            v-if="errors.has('registration_number')"
            class="help-block"
          >
            {{ errors.first('registration_number') }}
          </small>
        </div>
      </div>
      <template v-if="current_user.role !== 'agent'">
        <div
          class="form-group col-md-4"
          :class="{ 'has-feedback has-error': errors.has('email')}"
        >
          <label>Company Email</label>
          <input
            v-model="email"
            v-validate="'required|email'"
            type="email"
            class="form-control"
            name="email"
          >
          <small
            v-if="errors.has('email')"
            class="help-block"
          >
            {{ errors.first('email') }}
          </small>
        </div>
        <div
          class="form-group col-md-4"
          :class="{ 'has-feedback has-error': errors.has('kra_pin')}"
        >
          <label>Company KRA PIN</label>
          <input
            v-model="kra_pin"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="kra_pin"
          >
          <small
            v-if="errors.has('kra_pin')"
            class="help-block"
          >
            {{ errors.first('kra_pin') }}
          </small>
        </div>
        <div
          class="form-group col-md-4"
          :class="{ 'has-feedback has-error': errors.has('phone')}"
        >
          <label>Phone Number</label>
          <phone-number
            v-model="phone_number"
            v-validate="'required'"
            name="phone"
          />
          <small
            v-if="errors.has('phone')"
            class="help-block"
          >
            {{ errors.first('phone') }}
          </small>
        </div>
      </template>

      <div
        class="form-group col-md-4"
        :class="{ 'has-feedback has-error': errors.has('postal_address')}"
      >
        <label>Postal Address</label>
        <input
          v-model="postal_address"
          v-validate="'required|numeric'"
          type="text"
          placeholder="eg 123"
          class="form-control"
          name="postal_address"
        >
        <small
          v-if="errors.has('postal_address')"
          class="help-block"
        >
          {{ errors.first('postal_address') }}
        </small>
      </div>

      <div
        class="form-group col-md-4"
        :class="{ 'has-feedback has-error': errors.has('postal_code')}"
      >
        <label>Postal Code</label>
        <v-select
          v-model="postal_code"
          v-validate="'required'"
          :options="postal_codes"
          label="code"
          name="postal_code"
          placeholder="Postal Code"
        />
        <small
          v-if="errors.has('postal_code')"
          class="help-block"
        >
          {{ errors.first('postal_code') }}
        </small>
      </div>

      <div
        v-if="postal_code"
        class="form-group col-md-4"
      >
        <label>Postal Name</label>
        <input
          v-model="postal_code.name"
          type="text"
          class="form-control"
          readonly
        >
      </div>

      <div
        v-if="current_user.role !== 'agent'"
        class="form-group col-md-4"
        :class="{ 'has-feedback has-error': errors.has('physical_address')}"
      >
        <label>Physical Address</label>
        <input
          v-model="physical_address"
          v-validate="'required'"
          type="text"
          class="form-control"
          name="physical_address"
        >
        <small
          v-if="errors.has('physical_address')"
          class="help-block"
        >
          {{ errors.first('physical_address') }}
        </small>
      </div>
      <div
        v-if="current_user.role !== 'agent'"
        class="form-group col-md-4"
      >
        <label>Relationship Manager
          <small class="text-muted">(Optional)</small>
        </label>
        <v-select
          v-model="relationship_manager"
          :options="rms"
          name="relationship_manager"
          label="fullname"
          placeholder="Relationship Manager"
        />
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-primary pull-right">
          Submit
        </button>
      </div>
    </form>
  </fieldset>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      name: '',
      crp: '',
      kra_pin: '',
      email: '',
      phone_number: '',
      physical_address: '',
      postal_address: '',
      relationship_manager: null,
      postal_code: null,
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      current_user: 'getAuthUser',
      postal_codes: 'getPostalCodes',
      rms: 'getRelationshipManagers'
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (!this.postal_codes.length) {
        this.$store.dispatch("fetchPostalCodes")
      }
      if (!this.rms.length) {
        this.$store.dispatch("fetchRelationshipManagers");
      }
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.save();
        }
      });
    },
    agentSave() {
      this.$axios.post('/api/v1/agent/companies', {
        'name': this.name,
        'postal_address': this.postal_address,
        'postal_code_id': this.postal_code.id,
        'crp': this.crp
      }).then(() => {
        this.$router.push({name: 'companies.index'});
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    },
    save() {
      if (this.$store.getters.getAuthUser.role === 'agent') {
        this.agentSave();
        return;
      }
      const data = {
        'name': this.name,
        'crp': this.crp,
        'kra_pin': this.kra_pin,
        'email': this.email,
        'phone_number': this.phone_number,
        'physical_address': this.physical_address,
        'postal_address': this.postal_address,
        'postal_code_id': this.postal_code.id
      };

      if (this.relationship_manager)
        data.relationship_manager_id = this.relationship_manager.id;
      this.$axios.post('/api/v1/companies', data).then(resp => {
        if (resp.data.kyc_status === 1) {
          this.$router.push({name: 'companies.payment', params: {id: resp.data.company_unique_id}});
        } else {
          this.$toastr.s("Company created successfully! Finalizing registration process...");
          this.$router.push({name: 'companies.index'});
        }
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    }
  }
}
</script>
