export default {
    methods:{
        isPinValid(pin){
            var pin_reg = /^[A]{1}[0-9]{9}[a-zA-Z]{1}$/;
            if (pin.match(pin_reg)){
                return true;
            }
            return false;
        },
        isIDValid(id){
            var id_reg =/^[0-9]{8}$/;
            if (id.match(id_reg)){
                return true;
            }
            return false;
        },
        isPassportValid(passport){
            var passport_reg =  /^[A-Z0-9]{7,9}$/;
            if (passport.match(passport_reg)){
                return true;
            }
            return false;
        },
        checkIdentifier(){
            if (this.form.identifier === "id_number"){
                if (!this.isIDValid(this.form.identity)){
                    this.error = "Invalid ID Number";
                    return false;
                }else {
                    this.error = "";
                    return true;
                }
            }
            else if (this.form.identifier === "passport"){
                if (!this.isPassportValid(this.form.identity)){
                    this.error = "Invalid Passport Number";
                    return false;
                }else {
                    this.error = "";
                    return true;
                }
            }
            else if (this.form.identifier === "kra_pin"){
                if (!this.isPinValid(this.form.identity)){
                    this.error = "Invalid KRA PIN";
                    return false;
                }else {
                    this.error = "";
                    return true;
                }
            }
        },
        validateIdentifier(){
            if (this.form.identifier !=""){
                if (this.form.identity =="") {
                    this.error="Identity field is required";
                    return false;
                }
                return true;
            }
            return true;
        },
    }
}