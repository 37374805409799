<template>
  <div>
    <h4>Create a new Bid bond template.</h4>
    <hr>
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label>Template Name</label>
          <input
            v-model="name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <tinymce
            id="one"
            v-model="my_data"
            html-class="large_editor"
            :other_options="editor_options"
          />
        </div>
        <div class="form-group">
          <a
            href="#"
            class="btn btn-primary"
            @click.prevent="submit"
          >Save</a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="variables">
          <h4>What you can use</h4>
          <table class="table table-bordered">
            <tbody>
              <tr
                v-for="(variable, definition) in variables"
                :key="definition"
              >
                <td>
                  <a
                    :id="variable"
                    href="#"
                    title="copy"
                    class="copy"
                    @click.prevent="copyVariable(variable)"
                  ><i class="fa fa-copy" /></a>
                  {{ definition }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from 'vue-tinymce-editor'

export default {

  components: {
    tinymce
  },

  data() {
    return {
      my_data: {},
      editor_options: {
        height: '400px',
        forced_root_block : false,
        file_picker_types: 'image',
        /* and here's our custom image picker*/
        // eslint-disable-next-line no-unused-vars
        file_picker_callback: function (cb, value, meta) {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          let _this = this;
          input.onchange = function () {
            let file = this.files[0];

            const reader = new FileReader();

            reader.onload = () => {
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = _this.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), {title: file.name});
            };

            reader.readAsDataURL(file);
          };

          input.click();
        }
      },
      variables: [],
      name: ''
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/bid-bond-templates/' + this.$route.params.id + '/edit').then(response => {
        this.name = response.data.data.template.name;
        this.my_data = response.data.data.template.content;
        this.variables = response.data.data.variables;
      });
    },

    copyVariable(val) {
      var to_copy = '{{ $' + val + ' }}';
      var input = document.createElement('input');
      input.setAttribute('type', 'text');
      input.setAttribute('value', to_copy);
      input.setAttribute('id', 'temporary_input');
      var clicked = document.getElementById(val);
      clicked.prepend(input);
      input.focus();
      input.select();
      document.execCommand('copy');
      document.getElementById('temporary_input').remove();
      var msg = document.createElement('span');
      msg.setAttribute('id', 'show_copied');
      msg.innerHTML = 'Copied';
      clicked.append(msg);

      setTimeout(() => {
        msg.remove();
      }, 2000);
    },

    submit() {
      let data = {
        name: this.name,
        content: this.my_data
      };
      this.$axios.put('/api/v1/bid-bond-templates/' + this.$route.params.id, data).then(response => {
        this.$router.push({name: 'bid-bond-templates.show', params: {id: response.data.data.secret}});
      });
    }
  }
}
</script>

<style lang="scss">
.copy {
  i {
    margin-right: 10px;
  }
}

#show_copied {
  background: #404040;
  color: #fff;
  padding: 5px;
  top: 15px;
}
</style>
