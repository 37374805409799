<template>
  <div>
    <div>
      <router-link
        v-if="$can.view($store.getters.getAuthUser, 'create-performance-items')"
        :to="{ name: 'performance.pricing.create'}"
        class="btn btn-sm btn-black pull-right"
      >
        Create New
      </router-link>
    </div>
    <h4>Performance Pricing Tariffs</h4>
    <hr>
    <table
      ref="table"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>User Group</th>
          <th>Contract Type</th>
          <th>Lower Bound</th>
          <th>Upper Bound</th>
          <th>Charge</th>
          <th>Duration</th>
          <th>Charge Type</th>
          <th>Discount</th>
          <th>Discount Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item,index) in pricing"
          :key="index"
        >
          <td>{{ item.group.name | unslug }}</td>
          <td>{{ item.contract_type }}</td>
          <td>{{ $number.format(item.lower_bound) }}</td>
          <td>{{ $number.format(item.upper_bound) }}</td>
          <td>{{ $number.format(item.cost) }}</td>
          <td>{{ duration(item.duration) }}</td>
          <td>{{ item.charge_type }}</td>
          <td>{{ item.discount }}</td>
          <td>{{ item.discount_type }}</td>
          <td>
            <div class="btn btn-group">
              <button
                v-if="$can.view($store.getters.getAuthUser, 'edit-performance-items')"
                type="button"
                class="btn btn-sm btn-black"
                @click.prevent="edit(item)"
              >
                Edit
              </button>

              <button
                v-if="$can.view($store.getters.getAuthUser, 'delete-performance-items')"
                type="button"
                class="btn btn-sm btn-danger"
                @click.prevent="deleteData(item.id)"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :offset="5"
      @paginate="fetch"
    />

    <modal
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit performance pricing for {{ edit_item.group.name | unslug }}</h5>
      </template>
      <div
        v-if="error"
        v-html="$error.handle(error)"
      />
      <form @submit.prevent="update">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Contract Type</label>
            <v-select
              v-model="edit_item.contract_type"
              v-validate="'required'"
              name="contract_type"
              :options="contracts"
              placeholder="Select Contract Type"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Lower Bound</label>
            <vue-numeric
              v-model="edit_item.lower_bound"
              class="form-control"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Upper Bound</label>
            <vue-numeric
              v-model="edit_item.upper_bound"
              class="form-control"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Charge Type</label>
            <select
              v-model="edit_item.charge_type"
              class="form-control"
            >
              <option value="fixed">
                Fixed
              </option>
              <option value="percent">
                Percent
              </option>
            </select>
          </div>
          <div class="col-md-6 form-group">
            <label>Charge</label>
            <vue-numeric
              v-model="edit_item.cost"
              :precision="2"
              class="form-control"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Discount</label>
            <vue-numeric
              v-model="edit_item.discount"
              :precision="3"
              class="form-control"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Discount Type</label>
            <select
              v-model="edit_item.discount_type"
              class="form-control"
            >
              <option value="fixed">
                Fixed
              </option>
              <option value="percent">
                Percent
              </option>
            </select>
          </div>
          <div class="col-md-6 form-group">
            <label>Duration</label>
            <v-select
              v-model="edit_item.duration"
              :options="durations"
              :reduce="c =>c.value"
            />
          </div>
          <div class="col-md-12 form-group">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {durations, duration_value} from "@/mixins/durations";
import {contracts} from "@/mixins/contracts";

export default {
  data() {
    return {
      error: null,
      pricing: [],
      edit_item: {},
      showModal: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  created() {
    this.durations = durations;
    this.contracts = contracts;
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/performance-prices?page=' + this.pagination.current_page).then(response => {
        if (!response.data.data) return;
        this.pricing = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    edit(item) {
      this.edit_item = item;
      this.showModal = true;
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the performance pricing group?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/performance-prices/' + id).then(() => {
            this.fetch();
          })
        }
      });
    },
    update() {
      this.error = null;
      this.$axios.put('/api/v1/performance-prices/' + this.edit_item.id, this.edit_item).then(() => {
        this.showModal = false;
        this.fetch();
      }).catch(error => {
        this.error = error.response;
      });
    },
    duration(days) {
      return duration_value(days);
    },
  }
}
</script>

