<template>
  <div>
    <ul class="nav nav-tabs mb-2">
      <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'reports.companies.rms'}"
        >
          Companies & RMs
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'reports.companies.bycreator'}"
          class="nav-link"
        >
          By Creator
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'reports.companies.commission'}"
          class="nav-link"
        >
          Commission
        </router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>
<script>
export default {}
</script>
