<template>
  <div>
    <button
      v-if="$can.view($store.getters.getAuthUser, 'create-bidbond-pricing')"
      type="button"
      class="btn btn-black pull-right"
      @click.prevent="groupModal(null)"
    >
      Create Group
    </button>
    <h4>Pricing Group</h4>
    <hr>
    <table
      ref="table"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Group</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(group, index) in groups"
          :key="index"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ group.name | unslug }}</td>
          <td>
            <div class="btn btn-group">
              <button
                v-if="$can.view($store.getters.getAuthUser, 'edit-bidbond-pricing')"
                type="button"
                class="btn btn-sm btn-black"
                @click.prevent="groupModal(group)"
              >
                Edit
              </button>

              <button
                v-if="$can.view($store.getters.getAuthUser, 'delete-bidbond-pricing')"
                type="button"
                class="btn btn-sm btn-danger"
                @click.prevent="deleteGroup(group)"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5 v-if="group">
          Edit Group
        </h5>
        <h5 v-else>
          Create New Group
        </h5>
      </template>
      <form @submit.prevent="save">
        <div class="row">
          <div
            class="col-md-12"
            v-html="$error.handle(error)"
          />
          <div class="col-md-12">
            <div class="form-group">
              <label>Name</label>
              <input
                v-model="name"
                type="text"
                required
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button
                class="btn btn-primary pull-right"
                type="submit"
              >
                <span v-if="group">Update</span>
                <span v-else>Create</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                dt: "",
                error: '',
                group: null,
                name: '',
                showModal: false
            }
        },
        computed: {
            groups() {
                return this.$store.getters.getGroups;
            },
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                if (!this.groups.length) {
                    this.$store.dispatch("fetchGroups").then(() => {
                        this.loading = false;
                    });
                }
            },
            save() {
                if (this.group) {
                    this.edit();
                    return;
                }
                this.loading = true;
                this.$axios.post('/api/v1/groups', {'name': this.name.replace(/ /g, '_').toLowerCase()}).then((response) => {
                    this.showModal = false;
                    this.loading = false;
                    this.name = '';
                    this.group = null;
                    this.$store.dispatch('setGroup', response.data);
                    this.$toastr.s('Group Created');
                }).catch(err => {
                    this.loading = false;
                    if (err.response) {
                        this.error = err.response;
                    }
                });
            },
            edit() {
                this.group.name = this.name.replace(/ /g, '_').toLowerCase();
                this.loading = true;
                this.$axios.put('/api/v1/groups/' + this.group.id, this.group).then(() => {
                    this.showModal = false;
                    this.loading = false;
                    this.$store.dispatch('setGroup', this.group);
                    this.name = '';
                    this.group = null;
                    this.$toastr.s('Group updated');
                }).catch(err => {
                    this.loading = false;
                    if (err.response) {
                        this.error = err.response;
                    }
                });
            },
            deleteGroup(group) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "Are you sure you want to delete the group " + group.name + " ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$axios.delete('/api/v1/groups/'+ group.id).then(() => {
                            this.$store.dispatch('deleteGroup', group);
                            this.group = null;
                            this.$toastr.s('Group deleted');
                        }).catch(error => {
                            this.$toastr.w(error.response.data.error);
                        });
                    }
                })
            },
            groupModal(group) {
                if (group != null) {
                    this.group = group;
                    this.name = group.name;
                } else {
                    this.group = null;
                }
                this.showModal = true;
            }
        }
    }
</script>

