<template>
  <div>
    <div v-html="$error.handle(error)" />
    <form @submit.prevent="validateForm">
      <div class="container">
        <div class="row">
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('company_name')}"
          >
            <label>Company Name</label>
            <input
              v-model="company.name"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="company_name"
            >
            <small
              v-if="errors.has('company_name')"
              class="help-block"
            >
              {{ errors.first('company_name') }}
            </small>
          </div>
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('registration_number')}"
          >
            <label>Registration Number</label>
            <input
              v-model="company.crp"
              v-validate="'required'"
              type="text"
              class="form-control"
              placeholder="e.g CPR/2001/2650"
              name="registration_number"
            >
            <small
              v-if="errors.has('registration_number')"
              class="help-block"
            >
              {{ errors.first('registration_number') }}
            </small>
          </div>
          <template v-if="current_user.role !== 'agent'">
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('kra_pin')}"
            >
              <label>Company KRA PIN</label>
              <input
                v-model="company.kra_pin"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="kra_pin"
              >
              <small
                v-if="errors.has('kra_pin')"
                class="help-block"
              >
                {{ errors.first('kra_pin') }}
              </small>
            </div>
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('email')}"
            >
              <label>Company Email</label>
              <input
                v-model="company.email"
                v-validate="'required|email'"
                type="email"
                class="form-control"
                name="email"
              >
              <small
                v-if="errors.has('email')"
                class="help-block"
              >
                {{ errors.first('email') }}
              </small>
            </div>
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('phone')}"
            >
              <label>Company Phone Number</label>
              <input
                v-model="company.phone_number"
                v-validate="'required|numeric'"
                type="text"
                placeholder="254****"
                class="form-control"
                name="phone"
              >
              <small
                v-if="errors.has('phone')"
                class="help-block"
              >
                {{ errors.first('phone') }}
              </small>
            </div>
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('physical_address')}"
            >
              <label>Company Physical Address</label>
              <input
                v-model="company.physical_address"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="physical_address"
              >
              <small
                v-if="errors.has('physical_address')"
                class="help-block"
              >
                {{ errors.first('physical_address') }}
              </small>
            </div>
          </template>

          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('postal_address')}"
          >
            <label>Company Postal Address</label>
            <input
              v-model="company.postal_address"
              v-validate="'required|numeric'"
              type="text"
              placeholder="eg 123"
              class="form-control"
              name="postal_address"
            >
            <small
              v-if="errors.has('postal_address')"
              class="help-block"
            >
              {{ errors.first('postal_address') }}
            </small>
          </div>
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('postal_code')}"
          >
            <label>Postal Code</label>
            <v-select
              v-model="company.postal_code_id"
              v-validate="'required'"
              :options="postal_codes"
              label="code"
              name="postal_code"
              :reduce="c => c.id"
              placeholder="Postal Code"
            />
            <small
              v-if="errors.has('postal_code')"
              class="help-block"
            >
              {{ errors.first('postal_code') }}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Price Group</label>
            <v-select
              v-model="company.group_id"
              v-validate="'required'"
              :options="groups"
              label="name"
              name="price_group"
              :reduce="g => g.id"
              placeholder="Price Group"
            />
            <small
              v-if="errors.has('step1.price_group')"
              class="help-block"
            >
              {{ errors.first('step1.price_group') }}
            </small>
          </div>
          <div class="form-group col-md-6">
            <label>Relationship Manager
              <small class="text-muted">(Optional)</small>
            </label>
            <v-select
              v-model="company.relationship_manager_id"
              :options="rms"
              :reduce="c => c.id"
              name="relationship_manager"
              label="fullname"
              placeholder="Relationship Manager"
            />
          </div>
          <div class="form-group col-md-6">
            <label>CustomerID</label>
            <input
              v-model="company.customerid"
              type="text"
              class="form-control"
              name="customerid"
              placeholder="Customer ID"
            >
          </div>
          <div class="form-group col-md-6">
            <label>Account</label>
            <input
              v-model="company.account"
              type="text"
              class="form-control"
              name="account"
              placeholder="Account No"
            >
          </div>
          <div class="col-md-12 form-group">
            <button class="btn btn-primary pull-right">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {company: Object},
  data() {
    return {
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      groups: 'getGroups',
      postal_codes: 'getPostalCodes',
      current_user: 'getAuthUser',
      rms: 'getRelationshipManagers'
    })
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (!this.groups.length) {
        this.$store.dispatch("fetchGroups");
      }
      if (!this.postal_codes.length) {
        this.$store.dispatch("fetchPostalCodes").then(() => {
          this.loading = false;
        });
      }
      if (!this.rms.length) {
        this.$store.dispatch("fetchRelationshipManagers");
      }
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.update();
        }
      });
    },
    update() {
      const company = (
          ({
             name,
             crp,
             kra_pin,
             email,
             phone_number,
             physical_address,
             postal_address,
             group_id,
             postal_code_id,
             customerid,
             account,
             relationship_manager_id
           }) =>
              ({
                name,
                crp,
                kra_pin,
                email,
                phone_number,
                physical_address,
                postal_address,
                group_id,
                postal_code_id,
                customerid,
                account,
                relationship_manager_id
              })
      )(this.company);

      this.$axios.put('/api/v1/companies/' + this.company.id, company).then(() => {
        this.$toastr.s("Company updated successfully");
        this.$router.push({name: 'companies.index'});
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    }
  }
}
</script>
