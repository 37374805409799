<template>
  <div>
    <form
        class="row"
        @submit.prevent="validateForm()"
    >
      <div class="form-group col-md-6">
        <label>Contract Type</label>
        <v-select
            v-model="performance.cover"
            v-validate="'required'"
            :options="contracts"
            name="contract_type"
            placeholder="Select Contract Type"
        />
        <small
            v-if="errors.has('contract_type')"
            class="help-block"
        >
          {{ errors.first('contract_type') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <label>Contract Number</label>
        <input
            v-model="performance.contract_no"
            v-validate="'required'"
            class="form-control"
            name="contract_number"
            placeholder="eg Kenha/2259/2020"
            type="text"
        >
        <small
            v-if="errors.has('contract_number')"
            class="help-block"
        >
          {{ errors.first('contract_number') }}
        </small>
      </div>
      <template v-if="performance.cover">
        <div class="col-md-12 m-1 alert alert-info"> {{
            contract_description[contracts.indexOf(performance.cover)]
          }}
        </div>
        <div class="form-group col-md-12">
          <input
              v-model="terms"
              v-validate="'required'"
              name="terms"
              type="checkbox"
          >
          <label
              class="ml-2"
          >Confirm you understand the type of contract </label>
          <div
              v-if="errors.has('terms')"
              class="small help-block"
          >
            {{ errors.first('terms') }}
          </div>
        </div>
      </template>
      <div class="form-group col-md-6">
        <label>Company Name</label>
        <v-select
            v-model="performance.company"
            v-validate="'required'"
            :options="companies"
            label="name"
            name="company"
            placeholder="Select your Company"
        />
        <small
            v-if="errors.has('company')"
            class="help-block"
        >
          {{ errors.first('company') }}
        </small>
      </div>
      <div class="form-group col-md-6" v-if="$can.view($store.getters.getAuthUser, 'create-joint-venture')">
        <input
            v-model="is_venture"
            name="is_venture"
            type="checkbox"
        >
        <label
            class="ml-3"
        >Is Joint Venture</label>
      </div>
      <div
          v-for="(r, i) in ventures"
          :key="i"
          class="form-group col-md-6"
      >
        <label>Joint Company Name</label>
        <input
            v-model="ventures[i]"
            v-validate="'required'"
            :name="'venture_'+i"
            class="form-control"
            data-vv-as="Joint Company"
            placeholder="Joint Company name"
            type="text"
        >
        <button
            class="btn btn-sm btn-primary"
            @click.prevent="addRow()"
        >
          <i class="fa fa-plus"/>
        </button>
        <button
            v-if="ventures.length>1"
            class="btn btn-sm btn-danger"
            @click.prevent="minusRow()"
        >
          <i class="fa fa-minus"/>
        </button>
        <div :class="{'help-block': errors.has('venture_'+i)}">
          {{ errors.first('venture_' + i) }}
        </div>
      </div>
      <div class="form-group col-md-12">
        <button
            class="btn btn-primary pull-right btn-sm"
            type="submit"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {contracts, contract_description} from "@/mixins/contracts";

export default {
  props: {
    companies: {
      type: Array,
      default() {
        return [];
      }
    },
    current: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      performance: {
        company: '',
        contract_no: '',
        joint_venture: '',
        cover: ''
      },
      terms: false,
      is_venture: false,
      ventures: [],
    }
  },
  watch: {
    'performance.company': {
      handler: function (newVal) {
        if (newVal !== null)
          this.$emit('company-change', newVal);
      },
    },
    current(newval) {
      this.performance = newval;
    },
    is_venture: function (n) {
      if (!n) {
        this.ventures = [];
        return;
      }
      if (!this.ventures.length) this.ventures.push('');
    },
  },
  created() {
    this.contracts = contracts;
    this.contract_description = contract_description;
  },
  mounted() {
    this.performance = this.current;
    if (this.current.joint_venture) {
      this.is_venture = true;
      if (this.current.joint_venture.indexOf('-') !== -1) {
        this.ventures = this.current.joint_venture.split('-');
      } else {
        this.ventures.push(this.current.joint_venture)
      }
    }
    this.$nextTick(() => {
      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    });
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          if (this.is_venture) this.performance.joint_venture = this.ventures.join('-');
          this.$emit('step1', this.performance);
        }
      });
    },
    addRow() {
      this.ventures.push('');
    },
    minusRow() {
      this.ventures.pop();
    }
  }
}
</script>
