<template>
  <div>
    <div class="row">
      <div class="form-group col-md-3 col-md-push-3">
        <label class="fs">Start Date</label>
        <date-picker
          v-model="start.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">End Date</label>
        <date-picker
          v-model="to.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
    </div>
    <table
      ref="tbbids"
      class="table table-striped"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>GUARANTEE REF</th>
          <th>CUSTOMER</th>
          <th>BENEFICIARY</th>
          <th>CUR</th>
          <th>AMOUNT</th>
          <th>ISSUE DATE</th>
          <th>EXPIRY DATE</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(d,i) in bids"
          :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ d.reference }}</td>
          <td>{{ d.company }}</td>
          <td>{{ d.counterparty }}</td>
          <td>{{ d.currency }}</td>
          <td>{{ $number.format(d.amount) }}</td>
          <td>{{ d.deal_date }}</td>
          <td>{{ d.expiry_date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import $ from 'jquery';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      dt: "",
      start: {
        date: moment().add('-1', 'week').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    bids() {
      return this.$store.getters.getBidbonds;
    },
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
    bids() {
      if (this.dt) this.dt.destroy();
      this.$nextTick(() => {
        this.dt = $(this.$refs.tbbids).DataTable({
          dom: 'Bfrtip',
          buttons: [{extend: 'csvHtml5', text: 'Excel'}]
        });
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.dt = $(this.$refs.tbbids).DataTable({
        dom: 'Bfrtip',
        buttons: [{extend: 'csvHtml5', text: 'Excel'}]
      });
    });
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchBidBonds', {start: this.start.date, end: this.to.date});
    },
    getBidBonds() {
      if (!this.bids && !this.bids.results.length) {
        this.fetch();
      }
    },
  }
}
</script>
