<template>
  <div>
    <form @submit.prevent="validateForm()">
      <div class="form-group">
        <label>Company Name</label>
        <v-select
          v-model="bid_bond.company"
          v-validate="'required'"
          :options="companies"
          label="name"
          name="company"
          placeholder="Select your Company"
        />
        <small
          v-if="errors.has('company')"
          class="help-block"
        >
          {{ errors.first('company') }}
        </small>
      </div>
      <div class="form-group" v-if="$can.view($store.getters.getAuthUser, 'create-joint-venture')">
        <input
          v-model="is_venture"
          name="is_venture"
          type="checkbox"
        >
        <label
          for="is_venture"
          class="ml-3"
        >Is Joint Venture</label>
      </div>
      <div
        v-for="(r, i) in ventures"
        :key="i"
        class="form-group"
      >
        <label>Joint Company Name</label>
        <input
          v-model="ventures[i]"
          v-validate="'required'"
          type="text"
          placeholder="Joint Company name"
          :name="'venture_'+i"
          data-vv-as="Joint Company"
          class="form-control"
        >
        <button
          class="btn btn-sm btn-primary"
          @click.prevent="addRow()"
        >
          <i class="fa fa-plus" />
        </button>
        <button
          v-if="ventures.length>1"
          class="btn btn-sm btn-danger"
          @click.prevent="minusRow()"
        >
          <i class="fa fa-minus" />
        </button>
        <div :class="{'help-block': errors.has('venture_'+i)}">
          {{ errors.first('venture_' + i) }}
        </div>
      </div>
      <div class="form-group">
        <label>Tender Number</label>
        <input
          v-model="bid_bond.tender_number"
          v-validate="'required'"
          type="text"
          class="form-control"
          placeholder="eg Kenha/2259/2020"
          name="tender_number"
        >
        <small
          v-if="errors.has('tender_number')"
          class="help-block"
        >
          {{ errors.first('tender_number') }}
        </small>
      </div>
      <div class="form-group">
        <button
          class="btn btn-primary pull-right btn-sm"
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    companies: {
      type: Array,
      default() {
        return [];
      }
    },
    current: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      bid_bond: {
        company: '',
        tender_number: '',
        joint_venture: ''
      },
      is_venture: false,
      ventures: [],
    }
  },
  watch: {
    'bid_bond.company': {
      handler: function (newVal) {
        if (newVal !== null)
          this.$emit('company-change', newVal);
      },
    },
    current(newval) {
      this.bid_bond = newval;
    },
    is_venture: function (n) {
      if (!n) {
        this.ventures = [];
        return;
      }
      if (!this.ventures.length) this.ventures.push('');
    },
  },
  mounted() {
    this.bid_bond = this.current;
    if (this.current.joint_venture) {
      this.is_venture = true;
      if (this.current.joint_venture.indexOf('-') != -1) {
        this.ventures = this.current.joint_venture.split('-');
      } else {
        this.ventures.push(this.current.joint_venture)
      }
    }
    this.$nextTick(() => {
      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    });
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          if (this.is_venture) this.bid_bond.joint_venture = this.ventures.join('-');
          this.$emit('step1', this.bid_bond);
        }
      });
    },
    addRow() {
      this.ventures.push('');
    },
    minusRow() {
      this.ventures.pop();
    }
  }
}
</script>
