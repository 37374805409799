<template>
  <div>
    <fieldset>
      <legend>GENERATE BIDBOND</legend>
      <div v-html="$error.handle(error)" />
      <template v-if="step === 1">
        <step-one
          :companies="companies"
          :current="bid_bond"
          @step1="step1"
        />
      </template>
      <template v-if="step === 2">
        <step-two
          :counterparties="counterparties"
          :current="bid_bond"
          @step2="step2"
          @back="step = 1"
        />
      </template>
      <template v-if="step === 3">
        <step-three
          :current="bid_bond"
          @step3="step3"
          @back="step = 2"
        />
      </template>
      <template v-if="step === 4">
        <template v-if="show_summary">
          <step-four
            :bid_bond="bid_bond"
            :pricing="pricing"
            @back="step = 3"
            @step4="step4"
          />
        </template>
        <template v-if="! show_summary">
          <loading />
        </template>
      </template>
      <template v-if="step === 5">
        <select-template
          :current="bid_bond"
          @selected="selectTemplate"
          @back="step = 4"
        />
      </template>
    </fieldset>
  </div>
</template>

<script>
import moment from 'moment/src/moment';
import SelectTemplate from "@/components/Dashboard/BidBonds/SelectTemplate.vue";
import StepOne from "@/components/Dashboard/BidBonds/StepOne.vue";
import StepTwo from "@/components/Dashboard/BidBonds/StepTwo.vue";
import StepThree from "@/components/Dashboard/BidBonds/StepThree.vue";
import StepFour from "@/components/Dashboard/BidBonds/StepFour";


export default {
  components: {
    SelectTemplate, StepOne, StepTwo, StepThree, StepFour
  },
  data() {
    return {
      bid_bond: {
        company: null
      },
      bid_company_id: null,
      step: 1,
      error: '',
      pricing: {},
      companies: [],
      show_summary: false
    }
  },
  computed: {
    counterparties() {
      return this.$store.getters.getCounterParties;
    },
  },
  mounted() {
    this.fetch();
    this.fetchCounterParties();
  },
  methods: {
    fetch() {
      const co_id = this.$route.params.company_id;
      this.bid_bond.tender_number = this.$route.params.tender_no;

      this.$axios.get('/api/v1/companies/approved/options').then(response => {
        this.companies = response.data;
        if (co_id) {
          this.fetchCompanyDetail(co_id);
          this.step = 0;
          this.searchTender();
        }
      });
    },
    fetchCompanyDetail(co_id) {
      this.$axios.get('/api/v1/companies/bid/' + co_id).then(response => {
        this.bid_bond.company = response.data;
      });
    },
    fetchCounterParties() {
      if (!this.counterparties.length) {
        this.$store.dispatch("fetchCounterParties").then(() => {
          this.loading = false;
        });
      }
    },
    extend(obj, src) {
      for (let key in src) {
        if (Object.prototype.hasOwnProperty.call(src, key)) obj[key] = src[key]
      }
      return obj;
    },
    calculatePricing() {
      this.show_summary = false;
      let data = {
        amount: this.bid_bond.amount,
        currency: this.bid_bond.currency,
        period: this.bid_bond.period,
        company_id: this.bid_bond.company.company_unique_id,
      };
      if (this.bid_company_id === this.bid_bond.company.company_unique_id) {
        if (this.bid_bond.secret) {
          data.secret = this.bid_bond.secret;
        }
      }
      this.$axios.post('/api/v1/bid-bonds/pricing', data).then(response => {
        this.pricing = response.data;
        this.show_summary = true;
      });
    },
    searchTender() {
      let co_id = this.$route.params.company_id;
      if (!co_id) {
        co_id = this.bid_bond.company.company_unique_id
      }
      this.$axios.get('/api/v1/bid-bonds/tender?tender_number=' +
          encodeURIComponent(this.bid_bond.tender_number) + '&company_id=' +
          encodeURIComponent(co_id)
      ).then(response => {
        const data = response.data.data;
        if (data) {
          if (this.$route.params.tender_no) {
            this.bid_bond.reference = data.reference;
          }
          this.bid_company_id = data.company_id;
          this.bid_bond.secret = data.secret;
          this.bid_bond.currency = data.currency;
          this.bid_bond.amount = parseFloat(data.amount);
          this.bid_bond.period = data.period;
          this.bid_bond.tender_purpose = data.purpose;
          this.bid_bond.addressee = data.addressee;
          this.bid_bond.start_date = data.effective_date;
          this.bid_bond.template = data.template_secret;
          this.bid_bond.counter_party = data.counterparty;
        }
        this.step < 3 ? this.step++ : this.step;
      });
    },
    step1(bid_bond) {
      this.bid_bond = bid_bond;
      this.searchTender();
      this.fetchCompanyDetail(this.bid_bond.company.company_unique_id);
    },
    step2(bid_bond) {
      this.bid_bond = this.extend(this.bid_bond, bid_bond);
      this.step = 3;
    },
    step3(bid_bond) {
      this.bid_bond = this.extend(this.bid_bond, bid_bond);
      this.calculatePricing();
      this.step = 4;
    },
    step4() {
      this.step = 5;
    },
    selectTemplate(secret) {
      this.bid_bond.template = secret;
      this.bid_bond.start_date = moment(this.bid_bond.start_date).format('YYYY-MM-DD');
      // eslint-disable-next-line no-unused-vars
      const bond = (({company, counter_party, ...o}) => o)(this.bid_bond); //remove counterparty,company from bidbond
      bond.company = this.bid_bond.company.company_unique_id;
      bond.counter_party = this.bid_bond.counter_party.id;

      this.$axios.post('/api/v1/bid-bonds', bond).then(response => {
        if (response.data.error) {
          this.error = response.data.error;
        }
        this.$router.push({
          name: 'bidbonds.payment',
          params: {id: response.data.data.secret, co: bond.company}
        })
      }).catch(error => {
        this.error = error.response;
      });
    }
  }
}
</script>
