<template>
  <div>
    <loading v-if="! is_loaded" />
    <template v-else>
      <div class="row">
        <div class="input-group col-md-4 mb-3">
          <input
            v-model="search"
            class="form-control py-2 border-right-0 border"
            type="search"
            placeholder="Search ..."
          >
          <span class="input-group-append">
            <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
            >
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
      <table
        class="table table-striped table-responsive-sm"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Created At</th>
            <th v-if="$can.view($store.getters.getAuthUser, 'view-user')">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, i) in users"
            :key="i"
          >
            <td>{{ pagination.from + i }}</td>
            <td>{{ fullname(user) }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role | unslug }}</td>
            <td>{{ user.active ? 'Active' : 'Inactive' }}</td>
            <td>{{ user.created_at }}</td>
            <td v-if="$can.view($store.getters.getAuthUser, 'view-user')">
              <router-link
                :to="{name: 'users.show', params: { id: user.user_unique_id }}"
                class="btn btn-sm btn-warning"
              >
                show
              </router-link>
            </td>
          </tr>
          <tr v-if="!users.length">
            <td :colspan="$can.view($store.getters.getAuthUser, 'view-user') ? 8 : 7">
              <div class="alert alert-info">
                No users found
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="5"
        @paginate="fetch"
      />
    </template>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
export default {
  mixins: [helper],
  data() {
    return {
      search: '',
      users: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/users', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.users = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    }
  },
}
</script>
