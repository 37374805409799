<template>
  <div class="home">
    <h4 class="sub-heading">
      Companies & RMs
    </h4>
    <div class="row">
      <div class="form-group col-md-3 col-md-push-3">
        <label class="fs">Start Date</label>
        <date-picker
          v-model="start.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">End Date</label>
        <date-picker
          v-model="to.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
    </div>
    <table
      ref="tb_companies"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Company</th>
          <th>Reg. No.</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Relationship Manager</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in companies"
          :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.crp }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.email }}</td>
          <td>{{ fullname(item.rm) }}</td>
          <td>{{ item.created_at }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery';
import DatePicker from "vuejs-datepicker";
import moment from 'moment/src/moment';
import helper from "@/mixins/helper";

export default {
  components: {
    DatePicker
  },
  mixins: [helper],
  data() {
    return {
      dt: "",
      start: {
        date: moment().add('week', '-1').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    companies() {
      return this.$store.getters.getCompanyRMs;
    },
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
    companies() {
      if (this.dt) this.dt.destroy();
      setTimeout(() => {
        this.dt = $(this.$refs.tb_companies).DataTable({
          destroy: true,
          dom: 'Bfrtip',
          buttons: [{extend: 'csvHtml5', text: 'Excel'}]
        });
      }, 3000);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.dt = $(this.$refs.tb_companies).DataTable({
        dom: 'Bfrtip',
        buttons: [{extend: 'csvHtml5', text: 'Excel'}]
      });
    });
    this.getRMSummary();
  },
  methods: {
    getRMSummary() {
      if (!this.companies.length) {
        this.fetch();
      }
    },
    fetch() {
      this.$store.dispatch('fetchCompanyRMs', {start: this.start.date, end: this.to.date});
    }
  }
}
</script>
