<template>
  <div>
    <h4>Bid bond Templates</h4>
    <div class="row mb-3">
      <div class="input-group col-md-4">
        <input
          v-model="search"
          class="form-control py-2 border-right-0 border"
          type="search"
          placeholder="Search By Name..."
        >
        <span class="input-group-append">
          <button
            class="btn btn-outline-secondary border-left-0 border"
            type="button"
          >
            <i class="fa fa-search" />
          </button>
        </span>
      </div>
      <router-link
        v-if="$can.view($store.getters.getAuthUser, 'create-bidbond-templates')"
        :to="{ name: 'bid-bond-templates.create'}"
        class="btn btn-sm btn-black offset-md-4"
      >
        Create New
      </router-link>
    </div>
    <table
      v-if="is_loaded"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(bid, i) in bidtemplates"
          :key="i"
        >
          <td>{{ pagination.from + i }}</td>
          <td>{{ bid.name }}</td>
          <td>
            <router-link :to="{name: 'bid-bond-templates.show', params: {id: bid.secret}}">
              <i
                class="fa fa-eye"
              /> show
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <loading v-else />
    <pagination
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :offset="5"
      @paginate="fetch"
    />
    <!--            <api-table :url="url" :columns="columns" classes="table table-striped table-responsive-sm"-->
    <!--                       show="bid-bond-templates.show" link="secret"/>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      },
      bidtemplates: []
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/bid-bond-templates', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.bidtemplates = response.data.data;
        this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      }).catch(error => {
        if (error.response) {
          this.$error.handle(error.response);
        }
      });
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    }
  }
}
</script>
