<template>
  <div>
    <div>
      <router-link
        v-if="$can.view($store.getters.getAuthUser, 'create-bidbond-pricing')"
        :to="{ name: 'bidbonds.pricing.create'}"
        class="btn btn-sm btn-black pull-right"
      >
        Create New
      </router-link>
    </div>
    <h4>Pricing Tariffs</h4>
    <hr>
    <table
      ref="table"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>User Group</th>
          <th>Lower Bound</th>
          <th>Upper Bound</th>
          <th>Charge</th>
          <th>Duration</th>
          <th>Charge Type</th>
          <th>Discount</th>
          <th>Discount Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(price,index) in pricing"
          :key="index"
        >
          <td>{{ price.group | unslug }}</td>
          <td>{{ $number.format(price.lower_bound) }}</td>
          <td>{{ $number.format(price.upper_bound) }}</td>
          <td>{{ $number.format(price.cost) }}</td>
          <td>{{ duration(price.duration) }}</td>
          <td>{{ price.charge_type }}</td>
          <td>{{ price.discount }}</td>
          <td>{{ price.discount_type }}</td>
          <td>
            <div class="btn btn-group">
              <button
                v-if="$can.view($store.getters.getAuthUser, 'edit-bidbond-pricing')"
                type="button"
                class="btn btn-sm btn-black"
                @click.prevent="edit(price)"
              >
                Edit
              </button>

              <button
                v-if="$can.view($store.getters.getAuthUser, 'delete-bidbond-pricing')"
                type="button"
                class="btn btn-sm btn-danger"
                @click.prevent="DeleteData(price.id)"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :offset="5"
      @paginate="fetch"
    />

    <modal
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit pricing for {{ edit_price.group | unslug }}</h5>
      </template>
      <div
        v-if="error"
        v-html="$error.handle(error)"
      />
      <form @submit.prevent="update">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Lower Bound</label>
              <vue-numeric
                v-model="edit_price.lower_bound"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Upper Bound</label>
              <vue-numeric
                v-model="edit_price.upper_bound"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Charge Type</label>
              <select
                v-model="edit_price.charge_type"
                class="form-control"
              >
                <option value="fixed">
                  Fixed
                </option>
                <option value="percent">
                  Percent
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Charge</label>
              <vue-numeric
                v-model="edit_price.cost"
                :precision="3"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Discount</label>
              <vue-numeric
                v-model="edit_price.discount"
                :precision="3"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Discount Type</label>
              <select
                v-model="edit_price.discount_type"
                class="form-control"
              >
                <option value="fixed">
                  Fixed
                </option>
                <option value="percent">
                  Percent
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Duration</label>
              <v-select
                v-model="edit_price.duration"
                :options="durations"
                :reduce="c =>c.value"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {durations, duration_value} from "@/mixins/durations"
export default {
  data() {
    return {
      error: null,
      pricing: [],
      edit_price: {},
      showModal: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  created() {
    this.durations = durations;
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/bidbond-pricing?page=' + this.pagination.current_page).then(response => {
        if (!response.data.data) return;
        this.pricing = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    edit(price) {
      this.edit_price = price;
      this.showModal = true;
    },
    DeleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the pricing group?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/bidbond-pricing/' + id).then(() => {
            this.fetch();
          })
        }
      });
    },
    update() {
      this.error = null;
      this.$axios.put('/api/v1/bidbond-pricing/' + this.edit_price.id, this.edit_price).then(() => {
        this.showModal = false;
        this.fetch();
      }).catch(error => {
        this.error = error.response;
      });
    },
    duration(days) {
      return duration_value(days);
    }
  }
}
</script>

