<template>
  <div>
    <template v-if="is_loaded">
      <div
        v-if="$can.view($store.getters.getAuthUser, 'list-performancebonds')"
        class="row"
      >
        <div class="input-group col-md-4 mb-3">
          <input
            v-model="search"
            class="form-control py-2 border-right-0 border"
            type="search"
            placeholder="Search ..."
          >
          <span class="input-group-append">
            <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
            >
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
      <table
        v-if="bonds.length"
        class="table table-bordered table-responsive-sm"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Performance #</th>
            <th>Contract #</th>
            <th>Company</th>
            <th />
            <th>Amount</th>
            <th>Expiry Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(bond, i) in bonds"
            :key="i"
          >
            <td>{{ pagination.from + i }}</td>
            <td>{{ bond.reference }}</td>
            <td>{{ bond.contract_no }}</td>
            <td>{{ bond.name }}</td>
            <td>{{ bond.currency }}</td>
            <td>{{ $number.format(bond.amount) }}</td>
            <td>{{ bond.expiry_date }}</td>
            <td>
              <button
                v-if="$can.view($store.getters.getAuthUser, 'refund-performance-cover')"
                class="btn btn-sm mr-2 btn-outline-primary"
                @click.prevent="refund(bond)"
              >
                <i class="fa fa-dollar" /> Refund
              </button>
              <router-link
                :to="{ name: 'performance.show', params: { secret: bond.secret }}"
                class="btn btn-outline-info btn-sm"
              >
                <i class="fa fa-eye" /> View
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="alert alert-warning"
      >
        <p>There are no performance bonds to approve for refund</p>
      </div>
      <pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="5"
        @paginate="fetch"
      />
    </template>
    <template v-else>
      <loading />
    </template>
  </div>
</template>

<script>

export default {
  data() {
    return {
      search: '',
      bonds: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/performances/matured', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.bonds = response.data.data;
        this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      }).catch(error => {
        if (error.response) {
          this.$error.handle(error.response);
        }
      });
    },
    refund(bond) {
      this.$swal({
        title: 'Initiate Limit/Cash Cover Refund?',
        text: "This will refund the company account for " + bond.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, refund!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.$axios.post('/api/v1/performances/refund', {
            'secret': bond.secret
          }).then(() => {
            this.$toastr.s('Refund successful!', 'Success');
            this.fetch();
          }).catch(err => {
            if (err.response) {
              this.error = err.response;
            }
          });
        }
      })
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2500)
    },
  }
}
</script>
