<template>
  <div>
    <form @submit.prevent="validateForm()">
      <div class="form-group">
        <label>Procuring Entity</label>
        <v-select
            v-model="performance.counterparty_id"
            v-validate="'required'"
            :options="counterparties"
            :reduce="c => c.id"
            data-vv-name="procuring_entity"
            label="name"
            name="procuring_entity"
            placeholder="Select Company Tendering From"
        />
        <small
            v-if="errors.has('procuring_entity')"
            class="help-block"
        >{{ errors.first('procuring_entity') }}
        </small>
      </div>

      <div class="form-group">
        <label>Contract Purpose</label>
        <textarea
            v-model="performance.purpose"
            v-validate="'required'"
            class="form-control"
            name="contract_purpose"
            placeholder="eg. Provision of auctioneering services"
            rows="3"
        />
        <small
            v-if="errors.has('contract_purpose')"
            class="help-block"
        >{{ errors.first('contract_purpose') }}</small>
      </div>
      <div class="form-group">
        <div class="placeholder-red">
          Strictly type the "Addressee" without including P.O.Box or Physical address such as : Director General,
          Managing Director, Head of Procurement etc.
        </div>
        <label>Addressee</label>
        <textarea
            v-model="performance.addressee"
            v-validate="'required'"
            class="form-control"
            name="addressee"
            placeholder="e.g General Manager"
            rows="3"
        />
        <small
            v-if="errors.has('addressee')"
            class="help-block"
        >{{ errors.first('addressee') }}</small>
      </div>
      <div class="form-group">
        <a
            class="btn btn-info pull-left btn-sm"
            href="#"
            @click.prevent="$emit('back')"
        >Previous</a>
        <button
            class="btn btn-primary pull-right btn-sm"
            type="submit"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    current: {
      type: Object
    },
    counterparties: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      performance: {
        counterparty_id: null,
        purpose: '',
        addressee: '',
      },
    }
  },
  watch: {
    current(newval) {
      newval.amount ? this.performance.amount = newval.amount : this.performance.amount = '';
      newval.purpose ? this.performance.purpose = newval.purpose : this.performance.purpose = '';
      newval.addressee ? this.performance.addressee = newval.addressee : this.performance.addressee = '';
    }
  },
  mounted() {
    this.performance.counterparty_id = this.current.counterparty_id;
    this.current.purpose ? this.performance.purpose = this.current.purpose : this.performance.purpose = '';
    this.current.addressee ? this.performance.addressee = this.current.addressee : this.performance.addressee = '';
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('step2', this.performance);
        }
      });
    }
    ,
  }
}
</script>
