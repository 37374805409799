<template>
  <div class="home">
    <h4 class="sub-heading">
      Summary
    </h4>
    <div class="row">
      <div class="form-group col-md-3 col-md-push-3">
        <label class="fs">Start Date</label>
        <date-picker
          v-model="start.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">End Date</label>
        <date-picker
          v-model="to.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
    </div>
    <div class="tiles">
      <div
        v-for="(item, i) in company_summary"
        :key="i"
        class="row"
      >
        <div class="col-md-3">
          <div class="tile red">
            <div class="tile-title">
              BidBonds
            </div>
            <div class="tile-content">
              {{ $number.format(item.bidbond_count) }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tile blue">
            <div class="tile-title">
              Bidbond Exposure <small>({{ item.currency }})</small>
            </div>
            <div class="tile-content">
              {{ $number.format(item.exposure) }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tile green">
            <div class="tile-title">
              Commission <small>(KES)</small>
            </div>
            <div class="tile-content">
              {{ $number.format(item.commission) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      colors: ["red", "blue", "green", "yellow"],
      start: {
        date: moment().add('week', '-1').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    company_summary() {
      return this.$store.getters.getCompanySummary;
    },
    // companies() {
    // return this.company_summary.map(x => {
    //
    // });
    // },
    // results() {
    //   return this.company_summary.results;
    // },
    // bidbonds() {
    //   return this.results ? this.results.reduce((acc, item) => acc + parseInt(item.companies), 0) : 0
    // },
    // exposure() {
    //   return this.results ? this.results.reduce((acc, item) => acc + parseInt(item.exposure), 0) : 0
    // },
    // commission() {
    //   return this.results ? this.results.reduce((acc, item) => acc + parseInt(item.commission), 0) : 0
    // },
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
  },
  mounted() {
    this.getCompanySummary();
  },
  methods: {
    getCompanySummary() {
      if (!this.company_summary.length) {
        this.fetch();
      }
    },
    fetch() {
      this.$store.dispatch('fetchCompanySummary', {start: this.start.date, end: this.to.date});
    }
  }
}
</script>
