<template>
  <div>
    <h4>Create Bid Bond template.</h4>
    <hr>
    <div class="row">
      <div class="col-md-8">
        <div v-html="$error.handle(error)" />
        <div class="form-group">
          <label>Template Name</label>
          <input
            v-model="name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <tinymce
            id="one"
            v-model="my_data"
            html-class="large_editor"
            :other_options="editor_options"
          />
        </div>
        <div class="form-group">
          <a
            href="#"
            class="btn btn-primary"
            @click.prevent="submit"
          >Save</a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="variables">
          <h4>What you can use</h4>
          <table class="table table-bordered">
            <tbody>
              <tr
                v-for="(variable, definition) in variables"
                :key="variable"
              >
                <td>
                  <a
                    :id="variable"
                    href="#"
                    title="copy"
                    class="copy"
                    @click.prevent="copyVariable(variable)"
                  ><i class="fa fa-copy" /></a>
                  {{ definition }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from 'vue-tinymce-editor'
import {editor_data,copyVar} from "@/mixins/editor";
export default {
  components: {
    tinymce
  },
  data() {
    return editor_data
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$axios.get('/api/v1/bid-bond-templates/create').then(response => {
        this.variables = response.data;
      });
    },
    copyVariable(val) {
      copyVar(val)
    },
    submit() {
      this.$axios.post('/api/v1/bid-bond-templates', {
        name: this.name,
        content: this.my_data
      }).then(response => {
        this.$router.push({name: 'bid-bond-templates.show', params: {id: response.data.data.secret}});
      }).catch(error => {
        this.error = error.response;
      });
    }
  }
}
</script>

<style lang="scss">
.copy {
  i {
    margin-right: 10px;
  }
}

#show_copied {
  background: #404040;
  color: #fff;
  padding: 5px;
  top: 15px;
}
</style>
