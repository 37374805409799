<template>
  <div>
    <div class="pdf text-right">
      <a
        href="#"
        style="margin-right: 10px"
        class="btn btn-info btn-sm"
        @click.prevent="showModal = true"
      ><i
        class="fa fa-pencil"
      /></a>
      <a
        v-if="can_download"
        :href="bid_url"
        target="_blank"
        class="btn btn-outline-info btn-sm"
      ><i class="fa fa-download" />
        Download PDF</a>
    </div>

    <modal
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit Performance Bond Period</h5>
      </template>
      <form
        class="picker"
        @submit.prevent="update"
      >
        <div class="form-group">
          <label>Start Date</label>
          <date-picker
            v-model="start_date"
            format="yyyy-MM-dd"
            name="bid_bond_start_date"
            input-class="form-control bg-white"
            :disabled-dates="disabledDates"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-sm">
            Update Date
          </button>
        </div>
      </form>
    </modal>
    <div
      id="bid-bond-template"
      v-html="performance"
    />
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';

export default {
  components: {
    DatePicker
  },

  data() {
    return {
      can_download: false,
      bid_ref: '',
      company_id: '',
      bid_url: null,
      performance: '',
      showModal: false,
      start_date: '',
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      }
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.bid_url = process.env.VUE_APP_API_URL + '/storage/PP_' + this.$route.params.secret + '.pdf';
      this.$axios.get('/api/v1/performances/' + this.$route.params.secret + '/reference').then(response => {
        this.start_date = response.data.data.effective_date;
        this.bid_ref = response.data.data.reference;
        this.company_id = response.data.data.company_id;
        if (this.bid_ref) {
          this.can_download = true;
        }
      }).catch(error => {
        this.can_download = false;
        this.error = error.response.data.error;
      });
      this.$axios.get('/api/v1/performances/' + this.$route.params.secret + '/secret').then(response => {
        this.performance = response.data;
      }).catch(error => {
        if (error.response.status === 422) {
          this.$router.replace({
            name: 'performance.payment',
            params: {secret: this.$route.params.secret, company: this.company_id}
          })
        }
        this.error = error.response.data.error;
      });
    },
    update() {
      this.$axios.post('/api/v1/performances/date', {
        start_date: moment(this.start_date).format('YYYY-MM-DD'),
        secret: this.$route.params.secret,
        company_unique_id: this.company_id
      }).then(() => {
        this.fetch();
        this.showModal = false;
        this.$toastr.s('Performance bond has been updated.');
      });
    },
  }
}
</script>
