export default {
    methods: {
        fullname(user) {
            if (user) {
                const n = ['firstname', 'middlename', 'lastname'];
                let names = Object.keys(user)
                    .map((key) => {
                        if (n.indexOf(key) !== -1)
                            return user[key];
                    });
                return names.join(' ');
            } else {
                return "None";
            }
        }
    }
}
