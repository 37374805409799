<template>
  <div>
    <ul class="nav nav-tabs mb-2">
      <li
        v-if="$can.view($store.getters.getAuthUser, 'bidbonds')"
        class="nav-item"
      >
        <router-link
          class="nav-link"
          :to="{ name: 'reports.bidbonds.index'}"
        >
          Bidbonds
        </router-link>
      </li>
      <template v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')">
        <li>
          <router-link
            :to="{ name: 'reports.bidbonds.summary'}"
            class="nav-link"
          >
            Bidbond Summary
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'reports.bidbonds.rm-summary'}"
            class="nav-link"
          >
            RM Summary
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'reports.bidbonds.rms'}"
            class="nav-link"
          >
            RM BreakDown
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'reports.bidbonds.by-creator'}"
            class="nav-link"
          >
            Bidbonds By Creator
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'reports.bidbonds.expiry'}"
            class="nav-link"
          >
            Bidbond Expiry
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'reports.bidbonds.company.summary'}"
            class="nav-link"
          >
            Summary
          </router-link>
        </li>
      </template>
    </ul>
    <router-view />
  </div>
</template>
<script>
export default {}
</script>
