<template>
  <div>
    <loading v-if="loading" />
    <template v-else>
      <div
        v-if="$can.view($store.getters.getAuthUser, 'list-companies')"
        class="row mb-3"
      >
        <div class="input-group col-md-4">
          <input
            v-model="search"
            class="form-control py-2 border-right-0 border"
            type="search"
            placeholder="Search By Name..."
          >
          <span class="input-group-append">
            <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
            >
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
        <div class="input-group col-md-4">
          <v-select
            v-model="group_id"
            :options="groups"
            label="name"
            :reduce="g => g.id"
            class="w-75"
            placeholder="Filter By Pricing Group"
          />
        </div>
      </div>
      <table
        v-if="companies.length"
        class="table table-striped table-responsive-sm"
      >
        <thead>
          <tr v-if="$store.getters.getAuthUser.role === 'agent'">
            <th>#</th>
            <th>Name</th>
            <th>Reg Number</th>
            <th>Postal Address</th>
            <th>Postal Code</th>
            <th>County</th>
            <th>Actions</th>
          </tr>
          <tr v-else>
            <th>#</th>
            <th>Name</th>
            <th>Reg Number</th>
            <th>Approval Status</th>
            <th>Payment Status</th>
            <template v-if="$can.view($store.getters.getAuthUser, 'view-companies')">
              <th>Actions</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="$store.getters.getAuthUser.role === 'agent'">
            <tr
              v-for="(company, i) in companies"
              :key="i"
            >
              <td>{{ i + pagination.from }}</td>
              <td>{{ company.name }}</td>
              <td>{{ company.crp }}</td>
              <td>{{ company.postal_address }}</td>
              <td>{{ company.postal_code }}</td>
              <td>{{ company.county }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-info"
                  @click.prevent="editModal(company)"
                >
                  <i
                    class="fa fa-edit"
                  /> Edit
                </button>
                <button
                  type="button"
                  class="btn btn-sm ml-1 btn-danger"
                  @click.prevent="deleteCompany(company)"
                >
                  <i
                    class="fa fa-trash"
                  /> Delete
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="(company, i) in companies"
              :key="i"
            >
              <td>{{ i + pagination.from }}</td>
              <td>{{ company.name }}</td>
              <td>{{ company.crp }}</td>
              <td>{{ company.approval_status }}</td>
              <td>{{ company.paid ? 'Paid' : 'Unpaid' }}</td>
              <template v-if="$can.view($store.getters.getAuthUser, 'view-companies')">
                <td>
                  <span class="comp">
                    <router-link
                      :to="{name: 'companies.show', params: { id: company.company_unique_id }}"
                      class="btn btn-sm btn-warning"
                    >
                      show
                    </router-link>
                  </span>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
      <div
        v-else
        class="alert alert-warning"
      >
        <p>There are no companies</p>
      </div>
      <pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="5"
        @paginate="fetch"
      />
    </template>
    <modal
      v-if="company"
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit Company</h5>
      </template>
      <form @submit.prevent="validateForm">
        <div class="container">
          <div class="row">
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('company_name')}"
            >
              <label>Company Name</label>
              <input
                v-model="company.name"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="company_name"
              >
              <small
                v-if="errors.has('company_name')"
                class="help-block"
              >
                {{ errors.first('company_name') }}
              </small>
            </div>
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('registration_number')}"
            >
              <label>Registration Number</label>
              <input
                v-model="company.crp"
                v-validate="'required'"
                type="text"
                class="form-control"
                placeholder="e.g CPR/2001/2650"
                name="registration_number"
              >
              <small
                v-if="errors.has('registration_number')"
                class="help-block"
              >
                {{ errors.first('registration_number') }}
              </small>
            </div>
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('postal_address')}"
            >
              <label>Company Postal Address</label>
              <input
                v-model="company.postal_address"
                v-validate="'required|numeric'"
                type="text"
                placeholder="eg 123"
                class="form-control"
                name="postal_address"
              >
              <small
                v-if="errors.has('postal_address')"
                class="help-block"
              >
                {{ errors.first('postal_address') }}
              </small>
            </div>
            <div
              class="col-md-6 form-group"
              :class="{ 'has-feedback has-error': errors.has('postal_code')}"
            >
              <label>Postal Code</label>
              <v-select
                v-model="company.postal_code_id"
                v-validate="'required'"
                :options="postal_codes"
                label="code"
                name="postal_code"
                :reduce="c => c.id"
                placeholder="Postal Code"
              />
              <small
                v-if="errors.has('postal_code')"
                class="help-block"
              >
                {{ errors.first('postal_code') }}
              </small>
            </div>
            <div class="col-md-6 form-group">
              <label>County</label>
              <input
                v-model="$store.getters.getCounty"
                type="text"
                placeholder="County"
                class="form-control"
                readonly
              >
            </div>
            <div class="col-md-12 form-group">
              <button class="btn btn-primary pull-right">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {

  data() {
    return {
      search: '',
      companies: [],
      group_id: null,
      loading: false,
      showModal: false,
      company: {
        name: '',
        crp: '',
        postal_address: '',
        postal_code_id: null
      },
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  computed: {
    ...mapGetters({
      postal_codes: 'getPostalCodes',
      groups: 'getGroups'
    }),
  },
  watch: {
    search(o, n) {
      if (!n && !o) return;
      this.fetchEntriesDebounced();
    },
    group_id(o, n) {
      if (!n && !o) return;
      this.fetchEntriesDebounced();
    },
    'company.postal_code_id'(n) {
      this.$store.dispatch('setCounty', n);
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/companies', {
        params: {
          page: this.pagination.current_page,
          search: this.search,
          group_id: this.group_id
        }
      }).then(response => {
        this.loading = false;
        if (!response.data.data) return;
        this.companies = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
      if (!this.groups.length) {
        this.loading = true;
        this.$store.dispatch("fetchGroups").then(() => {
          this.loading = false;
        });
      }
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    },
    editModal(company) {
      this.company = company;
      if (this.postal_codes.length) {
        this.showModal = true;
        return;
      }
      this.loading = true;
      this.$store.dispatch("fetchPostalCodes").then(() => {
        this.loading = false;
        this.showModal = true;
      });
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.update();
        }
      });
    },
    update() {
      this.company.company_id = this.company.company_unique_id;
      const company = (({company_id, name, crp, postal_address, postal_code_id}) =>
          ({company_id, name, crp, postal_address, postal_code_id}))(this.company);

      this.$axios.put('/api/v1/agent/companies', company).then(() => {
        this.$toastr.s("Company updated successfully");
        this.showModal = false;
        this.fetch();
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    },
    deleteCompany(company) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the company " + company.name + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/agent/companies', {data: {'company_id': company.company_unique_id}}).then(() => {
            this.fetch();
            this.$toastr.s('Company deleted');
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          });
        }
      })
    },
  }
}
</script>
