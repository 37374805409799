<template>
  <div>
    <form @submit.prevent="$emit('step4')">
      <h4>Summary</h4>
      <hr>
      <div
        v-if="is_valid"
        class="row"
      >
        <div class="col-md-6">
          <div class="bond-content">
            <p>Company: <span>{{ company_name }}</span></p>
            <p>Tender Number: <span>{{ bid_bond.tender_number }}</span></p>
            <p>Addressee</p>
            <p><span>{{ bid_bond.addressee }}</span></p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="bond-content">
            <p>Procuring Entity: <span>{{ bid_bond.counter_party.name }}</span></p>
            <p>Tender Purpose</p>
            <p><span>{{ bid_bond.tender_purpose }}</span></p>
          </div>
        </div>
      </div>
      <hr>
      <div
        v-if="is_valid"
        class="row"
      >
        <div class="col-md-6">
          <div class="bond-content">
            <p>BidBond Amount: <span>{{ bid_bond.currency }} {{ $number.format(bid_bond.amount) }}</span></p>
            <p>Tender Period: <span>{{ bid_bond.period }}</span></p>
            <p>Start Date: <span>{{ start_date }}</span></p>
            <p>End Date: <span>{{ bid_bond.end_date }}</span></p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="bond-content">
            <p>BidBond Charge: <span>{{ bid_bond.currency }} {{ $number.format(pricing.bid_bond_charge) }}</span></p>
            <p>Excise Duty: <span>{{ bid_bond.currency }} {{ $number.format(pricing.excise_duty) }}</span></p>
            <p>Indemnity Cost: <span>{{ bid_bond.currency }} {{ $number.format(pricing.indemnity_cost) }}</span></p>
            <p>Total: <span>{{ bid_bond.currency }} {{ $number.format(pricing.total) }}</span></p>
          </div>
        </div>
      </div>
      <br v-if="is_valid">
      <div
        v-if="! is_valid"
        class="alert alert-danger"
      >
        <p>The bidbond amount is invalid. Please try a different amount.</p>
      </div>
      <div class="form-group">
        <a
          href=""
          class="btn btn-info pull-left btn-sm"
          @click.prevent="$emit('back')"
        >Previous</a>
        <button
          v-if="is_valid"
          class="btn btn-primary pull-right btn-sm"
        >
          Confirm
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment/src/moment';

export default {
  props: {
    bid_bond: {
      type: Object,
      default() {
        return {
          company: {},
          counter_party: {}
        }
      }
    },
    pricing: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    start_date() {
      return moment(this.bid_bond.start_date).format('YYYY-MM-DD')
    },
    company_name(){
      if(this.bid_bond.joint_venture) {
        return `${this.bid_bond.company.name}-${this.bid_bond.joint_venture} JV`;
      }
      return this.bid_bond.company.name;
    },
    is_valid() {
      return this.pricing.bid_bond_charge != null;
    }
  }
}
</script>
