<template>
  <div>
    <div class="text-right">
      <router-link
        v-if="$can.view($store.getters.getAuthUser, 'update-performance-templates')"
        :to="{ name: 'performance.templates.edit', params: { id: $route.params.id }}"
        class="btn btn-primary mr-1"
      >
        Edit
      </router-link>
      <a
        v-if="$can.view($store.getters.getAuthUser, 'delete-performance-templates')"
        href="#"
        class="btn btn-danger"
        @click.prevent="deleteTemplate"
      >Delete</a>
    </div>
    <hr>
    <div
      class="container"
      v-html="template"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      template: {}
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/performance-templates/' + this.$route.params.id).then(response => {
        this.template = response.data;
      });
    },
    deleteTemplate() {
      this.$swal({
        title: 'Are you sure?',
        text: "This will delete this template!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/performance-templates/' + this.$route.params.id).then(() => {
            this.$router.push({name: 'performance.templates.index'});
          })
        }
      })
    },
  }
}
</script>
