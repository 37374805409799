<template>
  <div class="card">
    <loading v-if="loading" />
    <form
      v-else
      class="row"
      @submit.prevent="upload"
    >
      <h4 class="col-md-12">
        Performance Bond Award Letter
      </h4>
      <div
        class="col-md-12"
        v-html="$error.handle(error)"
      />
      <hr>
      <div class="col-md-6 form-group">
        <label>Award Letter</label>
        <input
          ref="award_letter"
          v-validate="'required|ext:pdf,png,jpg,jpeg'"
          class="form-control"
          name="award_letter"
          title="Upload award letter to proceed"
          type="file"
        >
        <small
          v-if="errors.has('award_letter')"
          class="help-block"
        >
          {{ errors.first('award_letter') }}
        </small>
      </div>
      <div
        v-if="performance.attachments.length"
        class="col-md-12 form-group"
      >
        <span
          v-for="(p,k) in performance.attachments"
          :key="k"
        >
          <a
            :href="getLink(p)"
            target="_blank"
          >{{ p.name }}</a>
        </span>
      </div>
      <div class="form-group col-md-12">
        <button
          class="btn btn-primary"
          type="submit"
        >
          <i class="fa fa-upload" />
          <span v-if="performance.attachments.length"> Replace</span>
          <span v-else> Upload</span>
        </button>
        <router-link
          :to="{ name: 'performance.payment', params: { secret: $route.params.secret, company: $route.params.company_id }}"
          class="btn btn-success ml-2"
        >
          Next <i class="fa fa-chevron-right" />
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      error: '',
      performance: {
        attachments: []
      },
    }
  },
  mounted() {
    this.getPerformance();
  },
  methods: {
    upload() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.loading = true;
          const formData = new FormData();
          formData.append('award_letter', this.$refs.award_letter.files[0]);
          formData.append('secret', this.$route.params.secret);
          this.$axios.post('/api/v1/performances/attachment', formData, {
            headers: {'content-type': 'multipart/form-data'}
          }).then(() => {
            this.loading = false;
            this.$toastr.s('Award letter uploaded!', 'Success');
            this.$router.push({
              name: 'performance.payment',
              params: {secret: this.$route.params.secret, company: this.$route.params.company_id}
            });
          }).catch(error => {
            this.loading = false;
            this.error = error.response;
          });
        }
      });
    },
    async getPerformance() {
      await this.$axios.get('/api/v1/performances/' + this.$route.params.secret).then(response => {
        this.performance = response.data.data;
      }).catch(error => {
        this.error = error.response.data.error;
      });
    },
    getLink(a) {
      return process.env.VUE_APP_API_URL
          + '/performances/attachment?award_letter='
          + a.name;
    }
  },
}
</script>