<template>
  <div>
    <template v-if="is_loaded">
      <div
        v-if="$can.view($store.getters.getAuthUser, 'list-performancebonds')"
        class="row"
      >
        <div class="input-group col-md-4 mb-3">
          <input
            v-model="search"
            class="form-control py-2 border-right-0 border"
            type="search"
            placeholder="Search ..."
          >
          <span class="input-group-append">
            <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
            >
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
      <table
        v-if="bonds.length"
        class="table table-bordered table-responsive-sm"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Performance #</th>
            <th>Company</th>
            <th>Counter Party</th>
            <th>Amount</th>
            <th>Effective Date</th>
            <th>Period</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(bond, i) in bonds"
            :key="i"
          >
            <td>{{ pagination.from + i }}</td>
            <td>{{ bond.secret }}</td>
            <td>{{ companyName(bond) }}</td>
            <td>{{ bond.counterparty }}</td>
            <td>{{ $number.format(bond.amount) }}</td>
            <td>{{ bond.effective_date }}</td>
            <td>{{ bond.period }}</td>
            <td>{{ pay_status(bond.charge_paid) }}</td>
            <td>
              <template v-if="bond.charge_paid">
                <router-link
                  v-if="$can.view($store.getters.getAuthUser, 'update-performancebonds')"
                  :to="{ 'name': 'performance.edit', params: { secret: bond.secret} }"
                  class="btn btn-sm mr-2 btn-outline-primary"
                >
                  <i class="fa fa-edit" /> Edit
                </router-link>
                <router-link
                  :to="{ name: 'performance.show', params: { secret: bond.secret }}"
                  class="btn btn-outline-info btn-sm"
                >
                  <i class="fa fa-eye" /> View
                </router-link>
              </template>
              <template v-else>
                <router-link
                  v-if="isValid(bond)"
                  :to="{ name: 'performance.payment', params: { secret: bond.secret, company: bond.company_id }}"
                  class="btn btn-outline-success btn-sm"
                >
                  <i class="fa fa-money" /> Pay
                </router-link>
                <router-link
                  v-if="!bond.attachments"
                  :to="{ name: 'performance.award', params: { secret: bond.secret, company_id: bond.company_id }}"
                  class="btn btn-outline-primary mt-1 btn-sm"
                >
                  <i class="fa fa-upload" /> Award letter
                </router-link>
                <router-link
                  :to="{ name: 'performance.create', params: { contract_no: bond.contract_no, company_id: bond.company_id }}"
                  class="btn btn-outline-primary mt-1 btn-sm"
                >
                  <i class="fa fa-edit" /> Edit
                </router-link>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="alert alert-warning"
      >
        <p>There are no performance bonds generated</p>
      </div>
      <pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="5"
        @paginate="fetch"
      />
    </template>
    <template v-else>
      <loading />
    </template>
  </div>
</template>

<script>
import moment from 'moment/src/moment';

export default {
  data() {
    return {
      search: '',
      bonds: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/performances', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.bonds = response.data.data;
        this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      }).catch(error => {
        this.is_loaded = true;
        if (error.response) {
          this.$error.handle(error.response);
        }
      });
    },
    isValid(bond) {
      return bond.attachments && moment(bond.effective_date).isSameOrAfter(moment(), 'day');
    },
    companyName(bond) {
      return bond.joint_venture ? bond.name + '_' + bond.joint_venture + ' JV' : bond.name;
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2500)
    },
    pay_status(x) {
      return x ? 'Paid' : 'Pending Payment';
    }
  }
}
</script>
