<template>
  <div>
    <div class="row">
      <div class="form-group col-md-3">
        <label>Start Date</label>
        <date-picker
            v-model="start.date"
            format="yyyy-MM-dd"
            input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label>End Date</label>
        <date-picker
            v-model="to.date"
            format="yyyy-MM-dd"
            input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label>Branch</label>
        <v-select
            v-model="branch_id"
            v-validate="'required'"
            :options="branches"
            :reduce="c => c.id"
            label="name"
            name="branch"
            placeholder="Filter By Branch"
        />
      </div>
    </div>
    <table
        ref="tbbids"
        class="table table-striped"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Ref</th>
          <th>Company</th>
          <th>CounterParty</th>
          <th>Commission (KES)</th>
          <th>Deal Date</th>
          <th>RelationshipManager</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(d,i) in bids"
          :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>
            <router-link
                :to="{ name: 'bidbonds.show', params: { id: d.secret }}">
              {{ d.reference }}
            </router-link>
          </td>
          <td>{{ d.company_name }}</td>
          <td>{{ d.counter_party_name }}</td>
          <td>{{ $number.format(d.charge) }} </td>
          <td>{{ d.deal_date }}</td>
          <td>{{ d.firstname }} {{ d.lastname }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import $ from 'jquery';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      dt: "",
      branch_id: null,
      start: {
        date: moment().add('-1', 'day').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    ...mapGetters({
      bids: 'getRMBids',
      branches: 'getBranches'
    }),
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    branch_id() {
      this.fetch();
    },
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
    bids() {
      if (this.dt) this.dt.destroy();
      this.$nextTick(() => {
        this.dt = $(this.$refs.tbbids).DataTable({
          dom: 'Bfrtip',
          buttons: [{extend: 'csvHtml5', text: 'Excel'}]
        });
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.dt = $(this.$refs.tbbids).DataTable({
        dom: 'Bfrtip',
        buttons: [{extend: 'csvHtml5', text: 'Excel'}]
      });
    });
    this.fetch();
    if (!this.branches.length) {
      this.$store.dispatch("fetchBranches");
    }
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchRMBids', {start: this.start.date, end: this.to.date, branch_id: this.branch_id});
    },
    getBidBonds() {
      if (!this.bids && !this.bids.results.length) {
        this.fetch();
      }
    },
  }
}
</script>
