<template>
  <div v-if="!loading">
    <h4>Edit Bid bond</h4>
    <div v-html="$error.handle(error)" />
    <form
      v-if="step === 1"
      class="row"
      data-vv-scope="step1"
      @submit.prevent="validateForm('step1')"
    >
      <div class="form-group col-md-6">
        <label>Company Name</label>
        <input
          v-model="bid_bond.company"
          type="text"
          class="form-control"
          placeholder="Company Name"
          readonly
        >
      </div>
      <div class="form-group col-md-6">
        <input
          v-model="is_venture"
          name="is_venture"
          type="checkbox"
        >
        <label
          for="is_venture"
          class="ml-3"
        >Is Joint Venture</label>
      </div>
      <div
        v-for="(r, i) in ventures"
        :key="i"
        class="form-group col-md-6"
      >
        <label>Joint Company Name</label>
        <input
          v-model="ventures[i]"
          v-validate="'required'"
          type="text"
          placeholder="Joint Company name"
          :name="'venture_'+i"
          data-vv-as="Joint Company"
          class="form-control"
        >
        <button
          class="btn btn-sm btn-primary"
          @click.prevent="addRow()"
        >
          <i class="fa fa-plus" />
        </button>
        <button
          v-if="ventures.length>1"
          class="btn btn-sm btn-danger"
          @click.prevent="minusRow()"
        >
          <i class="fa fa-minus" />
        </button>
        <div :class="{'help-block': errors.has('step1.venture_'+i)}">
          {{ errors.first('step1.venture_' + i) }}
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Procuring Entity</label>
        <v-select
          v-model="bid_bond.counter_party_id"
          v-validate="'required'"
          :options="counter_parties"
          label="name"
          data-vv-name="procuring_entity"
          name="procuring_entity"
          :reduce="c =>c.id"
          placeholder="Select Company Tendering From"
        />
        <small
          v-if="errors.has('step1.procuring_entity')"
          class="help-block"
        >
          {{ errors.first('step1.procuring_entity') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <label>Tender Number</label>
        <input
          v-model="bid_bond.tender_no"
          v-validate="'required'"
          type="text"
          class="form-control"
          placeholder="eg Kenha/2259/2020"
          name="tender_number"
        >
        <small
          v-if="errors.has('step1.tender_number')"
          class="help-block"
        >
          {{ errors.first('step1.tender_number') }}
        </small>
      </div>
      <div class="form-group col-md-3">
        <label>Currency</label>
        <v-select
          v-model="bid_bond.currency"
          v-validate="'required'"
          :options="currencies"
          name="currency"
          placeholder="Select Currency"
        />
        <small
          v-if="errors.has('step1.currency')"
          class="help-block"
        >{{ errors.first('step1.currency') }}
        </small>
      </div>
      <div class="form-group col-md-3">
        <label>Tender Amount</label>
        <vue-numeric
          v-model="bid_bond.amount"
          v-validate="'required'"
          class="form-control"
          name="tender_amount"
        />
        <small
          v-if="errors.has('step1.tender_amount')"
          class="help-block"
        >
          {{ errors.first('step1.tender_amount') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <label>Start Date</label>
        <date-picker
          v-model="bid_bond.effective_date"
          v-validate="'required'"
          format="yyyy-MM-dd"
          name="bid_bond_start_date"
          input-class="form-control bg-white"
        />
        <small
          v-if="errors.has('step1.bid_bond_start_date')"
          class="help-block"
        >
          {{ errors.first('step1.bid_bond_start_date') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <label>Tender Period</label>
        <vue-numeric
          v-model="bid_bond.period"
          v-validate="'required'"
          class="form-control"
          name="tender_period"
        />
        <small
          v-if="errors.has('step1.tender_period')"
          class="help-block"
        >
          {{ errors.first('step1.tender_period') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <label>Tender Purpose</label>
        <textarea
          v-model="bid_bond.purpose"
          v-validate="'required'"
          rows="3"
          name="tender_purpose"
          class="form-control"
          placeholder="eg. Tender for provision of auctioneering services"
        />
        <small
          v-if="errors.has('step1.tender_purpose')"
          class="help-block"
        >
          {{ errors.first('step1.tender_purpose') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <div class="placeholder-red">
          Strictly type the "Addressee" without including P.O.Box or Physical address such as : Director General,
          Managing Director, Head of Procurement etc.
        </div>
        <label>Addressee</label>
        <textarea
          v-model="bid_bond.addressee"
          v-validate="'required'"
          rows="3"
          placeholder="e.g General Manager"
          class="form-control"
          name="addressee"
        />
        <small
          v-if="errors.has('step1.addressee')"
          class="help-block"
        >{{ errors.first('step1.addressee') }}
        </small>
      </div>
      <div class="form-group col-md-12">
        <button
          class="btn btn-primary btn-sm"
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
    <form
      v-if="step === 2"
      data-vv-scope="step2"
      @submit.prevent="validateForm('step2')"
    >
      <div class="form-group">
        <label>Select Template</label>
        <v-select
          v-model="bid_bond.template_secret"
          v-validate="'required'"
          :options="templates"
          label="name"
          name="template"
          :reduce="c => c.secret"
          placeholder="Select Template"
        />
        <small
          v-if="errors.has('step2.template')"
          class="help-block"
        >{{ errors.first('step2.template') }}</small>
      </div>
      <div class="form-group">
        <label
          for="terms"
          class="checkbox"
        >
          <input
            v-validate="'required'"
            type="checkbox"
            name="terms"
          >
          I have read the <a
            href="#"
            @click.prevent="show_terms=true"
          >terms
            and conditions.</a>
        </label>
        <small
          v-if="errors.has('step2.terms')"
          class="help-block"
        >{{ errors.first('step2.terms') }}</small>
      </div>
      <hr>
      <div class="form-group">
        <div
          id="bid-bond-template"
          class="preview"
          style="background: #fff url(/img/sample.png) no-repeat; background-size: contain; background-position-x: center;"
        >
          <div v-html="preview" />
        </div>
      </div>
      <hr>
      <div class="form-group px-5 text-right">
        <button
          class="btn btn-primary btn-sm"
          type="submit"
        >
          Update
        </button>
        <a
          href="#"
          class="btn btn-info pull-left btn-sm"
          @click.prevent="step=1"
        >Previous</a>
      </div>
    </form>
  </div>
  <loading v-else />
</template>
<style scoped>
@-webkit-keyframes fadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.placeholder-red {
  background-color: red;
  color: white;
  display: inline-block;
  padding: 5px;
  -webkit-animation: fadein 2s ease-in alternate infinite;
  -moz-animation: fadein 2s ease-in alternate infinite;
  animation: fadein 2s ease-in alternate infinite;
}
</style>
<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import {currencies} from "@/mixins/currencies";
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      bid_bond: {
        company_id: null,
        purpose: '',
        counter_party_id: '',
        addressee: '',
        template_secret: '',
        charge: null,
        currency: 'KES',
        bidbond_secret: '',
        joint_venture: ''
      },
      is_venture: false,
      ventures: [],
      step: 1,
      error: '',
      templates: [],
      selected_template: {},
      preview: '',
      loading: false,
      terms: false,
      show_terms: false
    }
  },
  computed: {
    counter_parties() {
      return this.$store.getters.getCounterParties;
    },
  },
  watch: {
    'bid_bond.template_secret': function () {
      this.fetchPreview();
    },
    is_venture: function (n) {
      if(n && !this.ventures.length){
        this.ventures.push('');
      }else{
        this.ventures = [];
      }
    },
  },
  mounted() {
    this.fetchBidbond();
    this.fetchTemplates();
    this.fetchCounterParties();
  },
  created() {
    this.currencies = currencies;
  },
  methods: {
    fetchTemplates() {
      this.loading = true;
      this.$axios.get('/api/v1/bid-bond-templates/all').then(response => {
        this.templates = response.data;
        this.loading = false;
      });
    },
    fetchCounterParties() {
      if (!this.counter_parties.length) {
        this.$store.dispatch("fetchCounterParties").then(() => {
          this.loading = false;
        });
      }
    },
    fetchBidbond() {
      this.loading = true;
      this.$axios.get('/api/v1/bid-bonds/' + this.$route.params.id).then(response => {
        this.bid_bond = response.data.data;
        this.bid_bond.counter_party_id = parseInt(this.bid_bond.counter_party_id);
        if(this.bid_bond.joint_venture){
          this.is_venture = true;
          if(this.bid_bond.joint_venture.indexOf('-') !== -1){
            this.ventures = this.bid_bond.joint_venture.split('-');
          }else{
            this.$set(this.ventures, 0, this.bid_bond.joint_venture);
          }
        }
        this.loading = false;
      }).catch(error => {
        this.error = error.response;
        this.loading = false;
      });
    },
    calculatePricing() {
      this.loading = true;
      this.$axios.post('/api/v1/bid-bonds/pricing', {
        amount: this.bid_bond.amount,
        currency:this.bid_bond.currency,
        period: this.bid_bond.period,
        company_id: this.bid_bond.company_id,
        secret: this.bid_bond.bidbond_secret,
      }).then(response => {
        this.loading = false;
        this.bid_bond.charge = response.data.total;
      });
    },
    validateForm(scope) {
      return this.$validator.validateAll(scope).then(result => {
        if (result) {
          if (scope === 'step1') {
            this.bid_bond.joint_venture = this.ventures.join('-');
            this.bid_bond.end_date = moment(this.bid_bond.effective_date).add(parseInt(this.bid_bond.period), 'days').format('YYYY-MM-DD');
            this.bid_bond.effective_date = moment(this.bid_bond.effective_date).format('YYYY-MM-DD');
            this.step = 2;
            this.calculatePricing();
            this.fetchPreview();
          }
          if (scope === 'step2') {
            this.postBidbond();
          }
        }
      });
    },
    postBidbond() {
      this.loading = true;
      this.$axios.put('/api/v1/bid-bonds/' + this.bid_bond.id, this.bid_bond).then(() => {
        this.loading = false;
        this.$toastr.s('Bidbond updated successfully!If you have changed the bid period an additional payment will be required!');
        this.$router.push({name: "bidbonds.index"});
      }).catch(err => {
        this.loading = false;
        if (err.response) {
          this.the_error = err.response;
        }
      });
    },
    fetchPreview() {
      this.$axios.post('/api/v1/bid-bonds/preview', this.bid_bond).then(response => {
        this.preview = response.data;
      });
    },
    addRow(){
      this.ventures.push('');
    },
    minusRow(){
      this.ventures.pop();
    }
  }
}
</script>


