<template>
  <div class="home">
    <h4 class="sub-heading">
      Bid Bonds Summary
    </h4>
    <div class="chart-container">
      <bar-chart />
    </div>
    <div class="row">
      <div class="form-group col-md-3 col-md-push-3">
        <label class="fs">Start Date</label>
        <date-picker
          v-model="start.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">End Date</label>
        <date-picker
          v-model="to.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
    </div>
    <table
      ref="table"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Companies On-boarded</th>
          <th>Bid Bond Generated</th>
          <th>Tenders Closing</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in bid_summary"
          :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item.date }}</td>
          <td>{{ $number.format(item.companies) }}</td>
          <td>{{ $number.format(item.bidbonds) }}</td>
          <td>{{ $number.format(item.tenders) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment/src/moment';
import $ from 'jquery';
import DatePicker from 'vuejs-datepicker';
import BarChart from "./BarChart.vue";

export default {
  components: {
    DatePicker,
    BarChart
  },
  data() {
    return {
      dt: "",
      start: {
        date: moment().add('week', '-1').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    bid_summary() {
      return this.$store.getters.getBidbondsSummary;
    },
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
    bid_summary() {
      if (this.dt) this.dt.destroy();
      this.$nextTick(() => {
        this.dt = $(this.$refs.table).DataTable({
          dom: 'Bfrtip',
          buttons: [{extend: 'csvHtml5', text: 'Excel'}]
        });
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.dt = $(this.$refs.table).DataTable({
        dom: 'Bfrtip',
        buttons: [{extend: 'csvHtml5', text: 'Excel'}]
      });
    });
    this.getBidBondSummary();
  },
  methods: {
    getBidBondSummary() {
      if (!this.bid_summary.length) {
        this.fetch();
      }
    },
    fetch() {
      this.$store.dispatch('fetchBidBondSummary', {start: this.start.date, end: this.to.date});
    }
  }
}
</script>
