<template>
  <div>
    <template v-if="loading">
      <loading />
    </template>
    <template v-else>
      <div v-html="$error.handle(error)" />
    </template>
  </div>
</template>

<script>
    export default {

        data() {
            return {
                error: '',
                loading: true
            }
        },
        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.$axios.get('/api/v1/companies/search/' + this.$route.params.id).then(() => {
                    this.$toastr.s('The company search was successfull. You may now add directors', 'Success');
                    this.$router.push({name: 'companies.directors.create', params: {id: this.$route.params.id}});
                }).catch(error => {
                    this.loading = false;
                    this.error = error.response;
                });
            }
        }
    }
</script>
