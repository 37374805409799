<template>
  <div>
    <div class="text-right">
      <router-link
        v-if="$can.view($store.getters.getAuthUser, 'update-bidbond-templates')"
        :to="{ name: 'bid-bond-templates.edit', params: { id: $route.params.id }}"
        class="btn btn-primary mr-1"
      >
        Edit
      </router-link>
      <a
        v-if="$can.view($store.getters.getAuthUser, 'delete-bidbond-templates')"
        href="#"
        class="btn btn-danger"
        @click.prevent="deleteTemplate"
      >Delete</a>
    </div>
    <hr>
    <div
      class="container"
      v-html="template"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      template: {}
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/bid-bond-templates/' + this.$route.params.id).then(response => {
        this.template = response.data;
      });
    },
    deleteTemplate() {
      this.$axios.delete('/api/v1/bid-bond-templates/' + this.$route.params.id).then(() => {
        this.$router.push({name: 'bid-bond-templates.index'});
      })
    }
  }
}
</script>
