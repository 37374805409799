<template>
  <loading v-if="loading"/>
  <div
      v-else
  >
    <div
        class="card"
    >
      <div class="card-header bg-secondary text-white text-center">
        <h6>Company Performance Details</h6>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12 mb-2">
          <router-link
              :to="{ name: 'companies.show', params: { id: this.$route.params.id }}"
              class="btn btn-sm btn-outline-primary"
          >
            <i class="fa fa-arrow-left"/>
            Back
          </router-link>
          <button
              v-if="this.$can.view(this.$store.getters.getAuthUser, 'update-performance-limit')"
              class="btn btn-info btn-sm ml-2"
              @click.prevent="showModal=true"
          >
            <i class="fa fa-edit"/>
            Update
          </button>
        </div>
        <div class="col-md-6">
          <p>Performance Group: <span v-if="performance.group">{{ performance.group.name | unslug }}</span></p>
          <p>Performance Limit: <span v-if="performance">KES {{ $number.format(performance.limit) }}</span></p>
          <p>Performance Balance: <span>KES {{ $number.format(performance.balance) }}</span></p>
          <p class="alert alert-info">
            <template v-if="performance.secured">
              Approved to apply for unsecured labour based contract
            </template>
            <template v-else>
              Not approved to apply for unsecured labour based contract
            </template>
          </p>
        </div>
        <div class="col-md-6"/>
      </div>
    </div>
    <modal
        :show-modal="showModal"
        @close="showModal = false"
    >
      <template #header>
        <h5 v-if="performance.group">
          Edit {{ performance.name }} performance settings
        </h5>
        <h5 v-else>
          Set Performance Settings
        </h5>
      </template>
      <div v-html="$error.handle(error)"/>
      <form
          class="row"
          @submit.prevent="save"
      >
        <div class="col-md-12 form-group">
          <label>Price Group</label>
          <v-select
              v-model="performance.group_id"
              v-validate="'required'"
              :options="groups"
              :reduce="g => g.id"
              label="name"
              name="price_group"
              placeholder="Price Group"
          />
          <small
              v-if="errors.has('price_group')"
              class="help-block"
          >
            {{ errors.first('price_group') }}
          </small>
        </div>
        <div class="col-md-12 form-group">
          <label>Limit</label>
          <vue-numeric
              v-model="performance.limit"
              class="form-control"
              type="text"
          />
        </div>
        <div class="col-md-12 form-group">
          <label>Can apply for unsecured loans?</label>
          <v-select
              v-model="performance.secured"
              :options="states"
              :reduce="c => c.value"
          />
        </div>
        <div class="col-md-12 form-group">
          <button
              class="btn btn-primary pull-right"
              type="submit"
          >
            <span v-if="performance.group">Update</span>
            <span v-else>Create</span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      loading: false,
      performance: {
        name: '',
        group: null,
        group_id: null,
        limit: 0,
        balance: 0
      },
      states: [
        {'label': 'Yes', 'value': true},
        {'label': 'No', 'value': false}
      ],
      showModal: false,
      error: '',
    }
  },
  computed: {
    ...mapGetters({
      groups: 'getPerformanceGroups',
    }),
    can_edit() {
      return this.$can.view(this.$store.getters.getAuthUser, 'update-performance-limit') && this.performance.group;
    }
  },
  watch: {
    showModal(n) {
      if (!this.groups.length && n === true) {
        this.$store.dispatch("fetchPerformanceGroups");
      }
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/performance-companies/' + this.$route.params.id).then(response => {
        this.performance = response.data.data;
      });
    },
    save() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.loading = true;
          this.performance.group ? this.updateLimit() : this.createLimit();
        }
      });
    },
    updateLimit() {
      this.$axios.put('/api/v1/performance-companies/' + this.$route.params.id, {
        'group_id': this.performance.group_id,
        'limit': this.performance.limit,
        'secured': this.performance.secured
      }).then(() => {
        this.afterPost();
      }).catch(error => {
        if (error.response) {
          this.error = error.response;
        }
      });
    },
    createLimit() {
      this.$axios.post('/api/v1/performance-companies', {
        'company_id': this.$route.params.id,
        'group_id': this.performance.group_id,
        'limit': this.performance.limit,
        'secured': this.performance.secured
      }).then(() => {
        this.afterPost();
      }).catch(error => {
        if (error.response) {
          this.error = error.response;
        }
      });
    },
    afterPost() {
      this.loading = false;
      this.$toastr.s('Company limit updated');
      this.showModal = false;
      this.fetch();
    }
  },
}
</script>
