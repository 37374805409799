<template>
  <div>
    <fieldset>
      <legend>GENERATE PERFORMANCE BOND</legend>
      <div v-html="$error.handle(error)"/>
      <template v-if="step === 1">
        <step-one
            :companies="companies"
            :current="performance"
            @step1="step1"
        />
      </template>
      <template v-if="step === 2">
        <step-two
            :counterparties="counterparties"
            :current="performance"
            @back="step = 1"
            @step2="step2"
        />
      </template>
      <template v-if="step === 3">
        <step-three
            :current="performance"
            @back="step = 2"
            @step3="step3"
        />
      </template>
      <template v-if="step === 4">
        <template v-if="show_summary">
          <step-four
              :performance="performance"
              :pricing="pricing"
              @back="step = 3"
              @step4="step4"
          />
        </template>
        <template v-if="! show_summary">
          <loading/>
        </template>
      </template>
      <template v-if="step === 5">
        <select-template
            :current="performance"
            @back="step = 4"
            @selected="selectTemplate"
        />
      </template>
    </fieldset>
  </div>
</template>

<script>
import moment from 'moment/src/moment';
import {mapGetters} from 'vuex';
import SelectTemplate from "@/views/Dashboard/Performance/Views/SelectTemplate.vue";
import StepOne from "@/views/Dashboard/Performance/Views/StepOne.vue";
import StepTwo from "@/views/Dashboard/Performance/Views/StepTwo.vue";
import StepThree from "@/views/Dashboard/Performance/Views/StepThree.vue";
import StepFour from "@/views/Dashboard/Performance/Views/Summary.vue";


export default {
  components: {
    SelectTemplate, StepOne, StepTwo, StepThree, StepFour
  },
  data() {
    return {
      performance: {
        company: null
      },
      company_id: null,
      step: 1,
      error: '',
      pricing: {},
      companies: [],
      show_summary: false
    }
  },
  computed: {
    ...mapGetters({
      counterparties: 'getPerformanceParties'
    })
  },
  mounted() {
    this.fetch();
    this.fetchCounterParties();
  },
  methods: {
    fetch() {
      const co_id = this.$route.params.company_id;
      this.performance.contract_no = this.$route.params.contract_no;

      this.$axios.get('/api/v1/companies/approved/options').then(response => {
        this.companies = response.data;
        if (co_id) {
          this.company_id = co_id;
          this.fetchCompanyDetail();
          this.step = 0;
          this.searchTender();
        }
      });
    },
    fetchCompanyDetail() {
      this.$axios.get('/api/v1/performance-companies/' + this.company_id).then(response => {
        let co = this.performance.company;
        this.performance.company = response.data.data;
        this.performance.company.name = co.name;
        this.performance.company.company_unique_id = this.company_id;
      }).catch(error => {
        if (error.response.status === 404) {
          this.error = {
            "data": {"message": "Company account not linked!"},
            "status": 400
          };
          return;
        }
        this.error = error.response;
      });
    },
    fetchCounterParties() {
      if (!this.counterparties.length) {
        this.$store.dispatch("fetchPerformanceParties").then(() => {
          this.loading = false;
        });
      }
    },
    extend(obj, src) {
      for (let key in src) {
        if (Object.prototype.hasOwnProperty.call(src, key)) obj[key] = src[key]
      }
      return obj;
    },
    calculatePricing() {
      this.show_summary = false;
      let data = {
        amount: this.performance.amount,
        currency: this.performance.currency,
        period: this.performance.period,
        company_id: this.company_id,
        contract_type: this.performance.cover
      };
      if (this.performance.secret) {
        data.secret = this.performance.secret;
      }
      this.$axios.post('/api/v1/performances/pricing', data).then(response => {
        this.pricing = response.data;
        this.show_summary = true;
      });
    },
    searchTender() {
      if (!this.$route.params.company_id) {
        this.company_id = this.performance.company.company_unique_id ? this.performance.company.company_unique_id : this.performance.company.id;
      }
      this.$axios.get('/api/v1/performances/contract?contract_no=' +
          encodeURIComponent(this.performance.contract_no) + '&company_unique_id=' +
          encodeURIComponent(this.company_id)
      ).then(response => {
        const data = response.data.data;
        if (data) {
          if (this.$route.params.contract_no) {
            this.performance.reference = data.reference;
          }
          this.performance.cover = data.cover;
          this.performance.secret = data.secret;
          this.performance.currency = data.currency;
          this.performance.amount = parseFloat(data.amount);
          this.performance.period = data.period;
          this.performance.purpose = data.purpose;
          this.performance.addressee = data.addressee;
          this.performance.start_date = data.effective_date;
          this.performance.template = data.template_secret;
          this.performance.counterparty_id = data.counterparty_id;
        }
        this.step < 3 ? this.step++ : this.step;
      });
    },
    step1(performance) {
      this.performance = performance;
      this.searchTender();
      this.company_id = performance.company.id ? performance.company.id : performance.company.company_unique_id;
      this.fetchCompanyDetail();
    },
    step2(performance) {
      this.performance = this.extend(this.performance, performance);
      this.step = 3;
    },
    step3(performance) {
      this.performance = this.extend(this.performance, performance);
      this.calculatePricing();
      this.step = 4;
    },
    step4() {
      this.step = 5;
    },
    selectTemplate(secret) {
      this.performance.template = secret;
      this.performance.start_date = moment(this.performance.start_date).format('YYYY-MM-DD');
      // eslint-disable-next-line no-unused-vars
      const bond = (({company, ...o}) => o)(this.performance); //remove company from bidbond
      bond.company = this.company_id;

      this.$axios.post('/api/v1/performances', bond).then(response => {
        if (response.data.error) {
          this.error = response.data.error;
        }
        this.$router.push({
          name: 'performance.award',
          params: {secret: response.data.data.secret, company_id: bond.company}
        })
      }).catch(error => {
        this.error = error.response;
      });
    }
  }
}
</script>
