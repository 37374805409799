<template>
  <div>
    <loading v-if="loading"/>
    <fieldset>
      <legend class="text-info">
        Assign Relationship Manager
      </legend>
      <div v-html="$error.handle(error)"/>
      <form
          @submit.prevent="validateForm"
      >
        <div class="row">
          <div :class="{ 'has-feedback has-error': errors.has('relationship_manager')}" class="form-group col-md-6">
            <label>Relationship Manager</label>
            <v-select
                v-model="form.user_id"
                v-validate="'required'"
                :options="rms"
                :reduce="c => c.id"
                label="fullname"
                name="relationship_manager"
                placeholder="Relationship Manager"
            />
            <div :class="{'text-danger': errors.has('relationship_manager')}">
              {{ errors.first('relationship_manager') }}
            </div>
          </div>
          <div :class="{ 'has-feedback has-error': errors.has('branch')}" class="form-group col-md-6">
            <label>Branch</label>
            <v-select
                v-model="form.branch_id"
                v-validate="'required'"
                :options="branches"
                :reduce="c => c.id"
                label="name"
                name="branch"
                placeholder="Branch"
            />
            <div :class="{'text-danger': errors.has('branch')}">
              {{ errors.first('branch') }}
            </div>
          </div>
          <div class="form-group col-md-12">
            <button
                class="btn btn-primary"
                type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  data() {
    return {
      loading: false,
      form: {
        branch_id: null,
        user_id: null
      },
      edit: false,
      error: '',
    }
  },
  computed: {
    ...mapGetters({
      rms: 'getRelationshipManagers',
      branches: 'getBranches'
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.edit ? this.editRM() : this.createRM();
        }
      });
    },
    editRM() {
      this.$axios.put('/api/v1/relationship_managers/' + this.$route.params.id, this.form).then(() => {
        this.$router.push({name: "rms"});
        this.error = '';
      }).catch(error => {
        this.error = error.response;
      });
    },
    createRM() {
      this.$axios.post('/api/v1/relationship_managers', this.form).then(() => {
        this.$router.push({name: "rms"});
        this.error = '';
      }).catch(error => {
        this.error = error.response;
      });
    },
    fetch() {
      if (!this.rms.length) {
        this.$store.dispatch("fetchRelationshipManagers");
      }
      if (!this.branches.length) {
        this.$store.dispatch("fetchBranches");
      }
      if (this.$route.params.id) {
        this.fetchRM()
      }
    },
    fetchRM() {
      this.edit = true;
      this.loading = true;
      this.$axios.get('/api/v1/relationship_managers/' + this.$route.params.id).then(response => {
        this.form.branch_id = response.data.branch_id;
        this.form.user_id = response.data.user_id
      }).then(() => {
        this.loading = false;
      });
    }
  },
}
</script>
