<template>
  <div>
    <div v-html="$error.handle(error)" />
    <form @submit.prevent="validateForm()">
      <div class="row">
        <div class="col-md-6">
          <div
            class="form-group"
            :class="{ 'has-feedback has-error': errors.has('firstname')}"
          >
            <label>First Name</label>
            <input
              v-model="individual.firstname"
              v-validate="'required'"
              type="text"
              name="firstname"
              class="form-control"
            >
            <small
              v-if="errors.has('firstname')"
              class="help-block"
            >
              {{ errors.first('firstname') }}
            </small>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="form-group"
            :class="{ 'has-feedback has-error': errors.has('lastname')}"
          >
            <label>Last Name</label>
            <input
              v-model="individual.lastname"
              v-validate="'required'"
              type="text"
              name="lastname"
              class="form-control"
            >
            <small
              v-if="errors.has('lastname')"
              class="help-block"
            >
              {{ errors.first('lastname') }}
            </small>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="form-group"
            :class="{ 'has-feedback has-error': errors.has('email')}"
          >
            <label>Email</label>
            <input
              v-model="individual.email"
              v-validate="'required|email'"
              type="text"
              name="email"
              class="form-control"
            >
            <small
              v-if="errors.has('email')"
              class="help-block"
            >
              {{ errors.first('email') }}
            </small>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="form-group"
            :class="{ 'has-feedback has-error': errors.has('phone')}"
          >
            <label>Phone Number</label>
            <phone-number
              v-model="individual.phone"
              v-validate="'required|digits:12'"
              name="phone"
            />
            <small
              v-if="errors.has('phone')"
              class="help-block"
            >
              {{ errors.first('phone') }}
            </small>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="form-group"
            :class="{ 'has-feedback has-error': errors.has('id_number')}"
          >
            <label>ID number</label>
            <input
              v-model="individual.id_number"
              v-validate="'required'"
              type="text"
              name="id_number"
              class="form-control"
            >
            <small
              v-if="errors.has('id_number')"
              class="help-block"
            >
              {{ errors.first('id_number') }}
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <button
            class="btn btn-primary pull-right"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
    export default {
        props: ['agentid'],
        data() {
            return {
                individual: {
                    first_name: "",
                    last_name: "",
                    phone: null,
                    id_number: null,
                    email: "",
                },
                error: ''
            }
        },
        methods: {
            linkUser() {
                this.loading = true;
                this.$axios.post("/api/v1/agents/" + this.agentid + "/linkUser", this.individual).then(() => {
                    this.loading = false;
                    this.$toastr.s('Agent updated successfully');
                    this.$router.push({name: "agents.index"});
                }).catch(err => {
                    this.loading = false;
                    if (err.response) {
                        this.error = err.response;
                    }
                });

            },
            validateForm() {
                return this.$validator.validateAll().then(result => {
                    if (result) {
                        this.linkUser();
                    }
                });
            },
        }
    }
</script>
