<template>
  <div>
    <h5>Payments</h5>
    <div
      v-if="$can.view($store.getters.getAuthUser, 'list-payments')"
      class="row"
    >
      <div class="input-group col-md-4 mb-3">
        <input
          v-model="search"
          class="form-control py-2 border-right-0 border"
          type="search"
          placeholder="Search ..."
        >
        <span class="input-group-append">
          <button
            class="btn btn-outline-secondary border-left-0 border"
            type="button"
          >
            <i class="fa fa-search" />
          </button>
        </span>
      </div>
    </div>
    <table
      v-if="is_loaded"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Amount</th>
          <th>Account</th>
          <th>Transaction Number</th>
          <th>Transaction Date</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, index) in payments"
          :key="index"
        >
          <td>{{ pagination.from + index }}</td>
          <td>{{ data.name }}</td>
          <td>{{ data.phone }}</td>
          <td>{{ $number.format(data.amount) }}</td>
          <td>{{ data.account }}</td>
          <td>{{ data.transaction_number }}</td>
          <td>{{ data.transaction_date | dateFilter }}</td>
          <td>
            <router-link
              v-if="getPayable(data.payable_type) === 'Bidbond'"
              :to="{ name: 'bidbonds.show', params: { id: data.payable_id }}"
            >
              {{ getPayable(data.payable_type) }}
            </router-link>
            <router-link
              v-if="getPayable(data.payable_type) === 'Company'"
              :to="{ name: 'companies.show', params: { id: data.payable_id }}"
            >
              {{ getPayable(data.payable_type) }}
            </router-link>
            <router-link
              v-if="getPayable(data.payable_type) === 'Agent'"
              :to="{ name: 'agents.show', params: { secret: data.payable_id }}"
            >
              {{ getPayable(data.payable_type) }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <loading v-else />
    <pagination
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :offset="5"
      @paginate="fetch"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      payments: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_loaded = true;
      this.$axios.get('/api/v1/payments', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if(!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.payments = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    },
    getPayable(type) {
      return type.replace("App\\", "");
    }
  }
}
</script>
