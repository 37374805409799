<template>
  <div>
    <loading v-if="loading"/>
    <fieldset v-else>
      <legend>Create Counterparty</legend>
      <div v-html="$error.handle(error)"/>
      <form @submit.prevent="validateForm">
        <div class="row">
          <div class="col-md-12 form-group">
            <label>Name</label>
            <input
                v-model="counterparty.name"
                v-validate="'required'"
                class="form-control"
                name="name"
                type="text"
            >
            <small
                v-if="errors.has('name')"
                class="help-block"
            >{{ errors.first('name') }}</small>
          </div>
          <div class="col-md-6 form-group">
            <label>Category</label>
            <v-select
                v-model="counterparty.category_id"
                v-validate="'required'"
                :options="categories"
                :reduce="c=>c.id"
                label="name"
                name="category"
            ></v-select>
            <small
                v-if="errors.has('category')"
                class="help-block"
            >{{ errors.first('category') }}</small>
          </div>
          <div class="col-md-6 form-group">
            <label>Postal Address</label>
            <input
                v-model="counterparty.postal_address"
                v-validate="'required|numeric'"
                class="form-control"
                data-vv-name="postal_address"
                placeholder="eg. 123"
                type="text"
            >
            <small
                v-if="errors.has('postal address')"
                class="help-block"
            >{{ errors.first('postal address') }}</small>
          </div>
          <div class="col-md-12 form-group">
            <label>Physical Address</label>
            <input
                v-model="counterparty.physical_address"
                v-validate="'required'"
                class="form-control"
                data-vv-name="physical address"
                type="text"
            >
            <small
                v-if="errors.has('physical address')"
                class="help-block"
            >{{ errors.first('physical address') }}</small>
          </div>
          <div class="col-md-6 form-group">
            <label>Postal Code</label>
            <v-select
                v-model="postal_code"
                v-validate="'required'"
                :options="postalcodes"
                label="code"
                data-vv-name="postal code"
                placeholder="Postal Code"
            />
            <small
                v-if="errors.has('postal code')"
                class="help-block"
            >{{ errors.first('postal code') }}</small>
          </div>
          <div class="col-md-6 form-group">
            <label>Postal Name</label>
            <input
                v-model="postal_code.name"
                class="form-control"
                readonly=""
                required
                type="text"
            >
          </div>
          <div class="col-md-6 form-group">
            <label>Type</label>
            <v-select
                v-model="counterparty.secured"
                v-validate="'required'"
                :options="secure_states"
                :reduce="c => c.value"
                name="type"
            ></v-select>
            <small
                v-if="errors.has('type')"
                class="help-block"
            >{{ errors.first('type') }}</small>
          </div>
          <div class="col-md-12">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      loading: false,
      counterparty: {},
      postal_code: {},
      error: '',
    }
  },
  mounted() {
    this.getPostalCodes();
    this.getCategories();
  },
  computed: {
    ...mapGetters({
      postalcodes: 'getPostalCodes',
      categories: 'getCategories',
      secure_states: 'getSecureStates'
    })
  },
  methods: {
    async getPostalCodes() {
      if (!this.postalcodes.length) {
        this.loading = true;
        await this.$store.dispatch("fetchPostalCodes").then(() => {
          this.loading = false;
        });
      }
    },
    async getCategories() {
      if (!this.categories.length) {
        this.loading = true;
        await this.$store.dispatch("fetchCategories").then(() => {
          this.loading = false;
        });
      }
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.submit();
        }
      });
    },
    submit() {
      let data = this.counterparty;
      data.postal_code_id = this.postal_code.id;
      this.$axios.post('/api/v1/performance-counterparties', data).then((response) => {
        this.$toastr.s("Performance Counterparty created successfully");
        if(this.$store.getters.getPerformanceParties.length){
          this.$store.dispatch('setPerformanceParty', response.data.data);
        }
        this.$router.replace({name: 'performance.counterparties.index'});
      });
    }
  }
}
</script>
