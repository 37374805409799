<template>
  <fieldset>
    <legend class="text-info">
      Onboard an Agent
    </legend>
    <div v-html="$error.handle(the_error)" />
    <form @submit.prevent="validateForm()">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Select Agent Type</label>
              <select
                v-model="agent.agent_type"
                class="form-control"
              >
                <option value="individual">
                  Individual
                </option>
                <option value="business">
                  Business
                </option>
              </select>
            </div>
          </div>
        </div>
        <h6>User Details</h6>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{ 'has-feedback has-error': errors.has('first_name')}"
            >
              <label>First Name</label>
              <input
                v-model="individual.first_name"
                v-validate="'required'"
                type="text"
                name="first_name"
                class="form-control"
              >
              <small
                v-if="errors.has('first_name')"
                class="help-block"
              >
                {{ errors.first('first_name') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{ 'has-feedback has-error': errors.has('last_name')}"
            >
              <label>Last Name</label>
              <input
                v-model="individual.last_name"
                v-validate="'required'"
                type="text"
                name="last_name"
                class="form-control"
              >
              <small
                v-if="errors.has('last_name')"
                class="help-block"
              >
                {{ errors.first('last_name') }}
              </small>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="form-group"
              :class="{ 'has-feedback has-error': errors.has('email')}"
            >
              <label>Email</label>
              <input
                v-model="individual.email"
                v-validate="'required|email'"
                type="text"
                name="email"
                autocomplete="email"
                class="form-control"
              >
              <small
                v-if="errors.has('email')"
                class="help-block"
              >
                {{ errors.first('email') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{ 'has-feedback has-error': errors.has('phone_number')}"
            >
              <label>Phone Number</label>
              <phone-number
                v-model="individual.phone_number"
                v-validate="'required|numeric|min:10|max:15'"
                name="phone_number"
                autocomplete="tel"
              />
              <small
                v-if="errors.has('phone_number')"
                class="help-block"
              >
                {{ errors.first('phone_number') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{ 'has-feedback has-error': errors.has('id_number')}"
            >
              <label>ID number</label>
              <input
                v-model="individual.id_number"
                v-validate="'required'"
                type="text"
                name="id_number"
                class="form-control"
              >
              <small
                v-if="errors.has('id_number')"
                class="help-block"
              >
                {{ errors.first('id_number') }}
              </small>
            </div>
          </div>
          <template v-if="agent.agent_type === 'individual'">
            <div class="col-md-12">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('physical_address')}"
              >
                <label>Physical Address</label>
                <input
                  v-model="individual.physical_address"
                  v-validate="'required'"
                  type="text"
                  name="physical_address"
                  class="form-control"
                >
                <small
                  v-if="errors.has('physical_address')"
                  class="help-block"
                >
                  {{ errors.first('physical_address') }}
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Postal Code</label>
                <v-select
                  v-model="individual.postal_code"
                  v-validate="'required'"
                  :options="postal_codes"
                  label="code"
                  name="postal_code"
                  placeholder="Postal Code"
                />
                <small
                  v-if="errors.has('postal_code')"
                  class="help-block"
                >
                  {{ errors.first('postal_code') }}
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Postal Name</label>
                <input
                  v-model="individual.postal_code.name"
                  type="text"
                  class="form-control"
                  readonly
                >
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('postal_address')}"
              >
                <label>Postal Address</label>
                <input
                  v-model="individual.postal_address"
                  v-validate="'required|numeric'"
                  type="text"
                  name="postal_address"
                  placeholder="Postal Address eg. 635"
                  class="form-control"
                >
                <small
                  v-if="errors.has('postal_address')"
                  class="help-block"
                >
                  {{ errors.first('postal_address') }}
                </small>
              </div>
            </div>
          </template>
        </div>
        <template v-if="agent.agent_type === 'business'">
          <h6>Business Details</h6>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('business_name')}"
              >
                <label class="control-label">
                  Business Name
                </label>
                <input
                  v-model="company.business_name"
                  v-validate="'required'"
                  type="text"
                  name="business_name"
                  class="form-control"
                >
                <small
                  v-if="errors.has('business_name')"
                  class="help-block"
                >
                  {{ errors.first('business_name') }}
                </small>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('business_email')}"
              >
                <label class="control-label">
                  Business Email
                </label>
                <input
                  v-model="company.business_email"
                  v-validate="'required|email'"
                  type="text"
                  name="business_email"
                  class="form-control"
                >
                <small
                  v-if="errors.has('business_email')"
                  class="help-block"
                >
                  {{ errors.first('business_email') }}
                </small>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('business_phone')}"
              >
                <label class="control-label">
                  Business Phone
                </label>
                <input
                  v-model="company.business_phone"
                  v-validate="'required|numeric|min:10|max:15'"
                  type="text"
                  class="form-control"
                  name="business_phone"
                >
                <small
                  v-if="errors.has('business_phone')"
                  class="help-block"
                >
                  {{ errors.first('business_phone') }}
                </small>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('registration_pin')}"
              >
                <label class="control-label">
                  CPR Number
                </label>
                <input
                  v-model="company.registration_pin"
                  v-validate="'required'"
                  type="text"
                  name="registration_pin"
                  class="form-control"
                  placeholder="CPR/123/2019 | PVT/123/2019"
                >
                <small
                  v-if="errors.has('registration_pin')"
                  class="help-block"
                >
                  {{ errors.first('registration_pin') }}
                </small>
              </div>
            </div>
            <div class="col-md-12">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('physical_address')}"
              >
                <label class="control-label">
                  Physical Address
                </label>
                <input
                  v-model="company.physical_address"
                  v-validate="'required'"
                  type="text"
                  name="physical_address"
                  class="form-control"
                >
                <small
                  v-if="errors.has('physical_address')"
                  class="help-block"
                >
                  {{ errors.first('physical_address') }}
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('postal_code')}"
              >
                <label>Postal Code</label>
                <v-select
                  v-model="postal_code"
                  v-validate="'required'"
                  :options="postal_codes"
                  label="code"
                  name="postal_code"
                  placeholder="Postal Code"
                />
                <small
                  v-if="errors.has('postal_code')"
                  class="help-block"
                >
                  {{ errors.first('postal_code') }}
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Postal Name</label>
                <input
                  v-model="postal_code.name"
                  type="text"
                  class="form-control"
                  readonly
                >
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="form-group"
                :class="{ 'has-feedback has-error': errors.has('postal_address')}"
              >
                <label>Postal Address</label>
                <input
                  v-model="company.postal_address"
                  v-validate="'required|numeric'"
                  type="text"
                  name="postal_address"
                  placeholder="Postal Address eg. 635"
                  class="form-control"
                >
                <small
                  v-if="errors.has('postal_address')"
                  class="help-block"
                >
                  {{ errors.first('postal_address') }}
                </small>
              </div>
            </div>
          </div>
        </template>
        <hr>
        <div class="row">
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('limit')}"
          >
            <label>Limit</label>
            <vue-numeric
              v-model="limit"
              v-validate="'required|numeric|min:1'"
              name="limit"
              class="form-control"
            />
            <small
              v-if="errors.has('limit')"
              class="help-block"
            >
              {{ errors.first('limit') }}
            </small>
          </div>
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('price_group')}"
          >
            <label>Price Group</label>
            <v-select
              v-model="group_id"
              v-validate="'required'"
              :options="groups"
              label="name"
              name="price_group"
              :reduce="g => g.id"
              placeholder="Price Group"
            />
            <small
              v-if="errors.has('price_group')"
              class="help-block"
            >
              {{ errors.first('price_group') }}
            </small>
          </div>
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('customerid')}"
          >
            <label>Customer ID</label>
            <input
              v-model="customerid"
              v-validate="'required'"
              type="text"
              name="customerid"
              class="form-control"
            >
            <small
              v-if="errors.has('customerid')"
              class="help-block"
            >
              {{ errors.first('customerid') }}
            </small>
          </div>
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('account')}"
          >
            <label>Account </label>
            <input
              v-model="account"
              v-validate="'required'"
              type="text"
              name="account"
              class="form-control"
            >
            <small
              v-if="errors.has('account')"
              class="help-block"
            >
              {{ errors.first('account') }}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 form-group">
            <button
              class="btn btn-primary pull-right"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
</template>

<script>
    export default {
        data() {
            return {
                agent: {
                    agent_type: 'business',
                },
                individual: {
                    first_name: "",
                    last_name: "",
                    phone_number: null,
                    id_number: null,
                    county: "",
                    physical_address: "",
                    postal_address: "",
                    email: "",
                    postal_code: {
                        id: '',
                        code: '',
                        name: ''
                    },
                },
                company: {
                    business_name: "",
                    business_email: "",
                    business_phone: null,
                    registration_pin: "",
                    physical_address: "",
                    postal_address: ""
                },
                limit: 0,
                customerid:'',
                account:'',
                group_id: null,
                postal_code: {
                    id: '',
                    code: '',
                    name: ''
                },
                error: '',
                the_error: ''
            }
        },
        computed: {
            postal_codes() {
                return this.$store.getters.getPostalCodes;
            },
            groups() {
                return this.$store.getters.getGroups;
            },
            all_errors() {
                if (typeof (this.error) == 'string') {
                    return this.error;
                }

                let str = '';
                this.error.forEach(err => {
                    str += '<p>' + err + '</p>';
                });

                return str;
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                if (!this.postal_codes.length) {
                    this.$store.dispatch("fetchPostalCodes").then(() => {
                        this.loading = false;
                    });
                }
                if (!this.groups.length) {
                    this.$store.dispatch("fetchGroups").then(() => {
                        this.loading = false;
                    });
                }
            },
            register() {
                this.loading = true;

                let data = {
                    "firstname": this.individual.first_name,
                    "lastname": this.individual.last_name,
                    "email": this.individual.email,
                    "phone": this.individual.phone_number,
                    "id_number": this.individual.id_number,
                    "agent_type": this.agent.agent_type,
                    "limit": this.limit,
                    "group_id": this.group_id,
                    "account" : this.account,
                    "customerid": this.customerid
                };

                if (this.agent.agent_type === 'individual') {
                    const individual = {
                        "postal_code_id": this.individual.postal_code.id,
                        "physical_address": this.individual.physical_address,
                        "postal_address": this.individual.postal_address,
                    };
                    data = {...data, ...individual};
                }

                if (this.agent.agent_type === 'business') {
                    const business = {
                        "business_name": this.company.business_name,
                        "business_email": this.company.business_email,
                        "business_phone": this.company.business_phone,
                        "business_physical_address": this.company.physical_address,
                        "business_postal_address": this.company.postal_address,
                        "business_postal_code_id": this.postal_code.id,
                        "crp": this.company.registration_pin,
                    };
                    data = {...data, ...business};
                }

                this.$axios.post("/api/v1/agents", data).then(() => {
                    this.loading = false;
                    this.$toastr.s('Agent created successfully');
                    this.$router.push({name: "agents.index"});
                }).catch(err => {
                    this.loading = false;
                    if (err.response) {
                        this.the_error = err.response;
                    }
                });

            },
            validateForm(scope) {
                return this.$validator.validateAll(scope).then(result => {
                    if (result) {
                        this.register();
                    }
                });
            },
        }
    }
</script>
