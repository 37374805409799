<template>
  <div
    id="bidbond-settings"
    class="tab-pane active"
  >
    <form @submit.prevent="save">
      <div class="form-group">
        <label>System Bank Limit</label>
        <vue-numeric
          v-model="setting.bank_limit"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Company Limit</label>
        <vue-numeric
          v-model="setting.company_limit"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>MPF</label>
        <input
          v-model="setting.mpf"
          type="number"
          step="0.01"
          min="0"
          max="1"
          class="form-control"
        >
      </div>
      <div class="form-group">
        <label>Other</label>
        <input
          v-model="setting.other"
          type="number"
          step="0.01"
          min="0"
          max="1"
          class="form-control"
        >
      </div>
      <div class="form-group">
        <button class="btn btn-primary">
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
    export default {

        data() {
            return {
                settings: [],
                setting: {
                    bank_limit: '',
                    indemnity_cost: '',
                    company_limit: '',
                    excise_duty: '',
                    mpf: '',
                    nic: '',
                },
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                this.$axios.get('/api/v1/settings').then(response => {
                    this.setting = response.data;
                    this.loading = false;
                });
            },

            save() {
                this.$axios.post('/api/v1/settings', this.setting).then(() => {
                    this.$toastr.s('The settings have been updated', 'Saved');
                });
            },
        }
    }
</script>
