<script>
import {Bar} from 'vue-chartjs'

export default {
  extends: Bar,
  data() {
    return {
      bid_expiry_data: {
        labels: ['Expiry Dates',],
        datasets: [{
          label: '# BidBonds Expiry',
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          data: [0, 0, 0],
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
              color: "rgba(255, 99, 132, 0.2)"
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  computed: {
    bidExpiry() {
      return this.$store.getters.getBidExpiry;
    },
  },
  watch: {
    bidExpiry() {
      this.displayGraph();
    }
  },
  mounted() {
    this.displayGraph();
  },
  methods: {
    displayGraph() {
      if (this.bidExpiry && this.bidExpiry.length) {
        this.bid_expiry_data.datasets[0]['data'] = this.bidExpiry.map(item => item.amount);
        this.bid_expiry_data.labels = this.bidExpiry.map(item => item.date);
      }
      this.renderChart(this.bid_expiry_data, this.options)
    }
  },
}
</script>
