<template>
  <div>
    <h4 class="sub-heading">
      Bid Bond Expiry
    </h4>
    <bar-chart :bid_expiry="bid_expiry" />
    <div class="row">
      <div class="form-group col-md-3 col-md-push-3">
        <label class="fs">Start Date</label>
        <date-picker
          v-model="start.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
      <div class="form-group col-md-3">
        <label class="fs">End Date</label>
        <date-picker
          v-model="to.date"
          format="yyyy-MM-dd"
          input-class="form-control bg-white"
        />
      </div>
    </div>
    <table
      ref="tbbond"
      class="table table-striped table-responsive-sm"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Bid Bonds Expired Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in bid_expiry"
          :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item.date }}</td>
          <td>{{ $number.format(item.amount) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import moment from 'moment/src/moment';
import $ from 'jquery';
import DatePicker from 'vuejs-datepicker';
import BarChart from "./BarChart";

export default {
  components: {
    DatePicker,
    BarChart
  },
  data() {
    return {
      dt: "",
      start: {
        date: moment().add('week', '-1').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    bid_expiry() {
      return this.$store.getters.getBidExpiry;
    },
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
    bid_expiry() {
      if (this.dt) this.dt.destroy();
      setTimeout(() => {
        this.dt = $(this.$refs.tbbond).DataTable({
          dom: 'Bfrtip',
          buttons: [{extend: 'csvHtml5', text: 'Excel'}]
        });
      }, 3000);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.dt = $(this.$refs.tb_rm_summary).DataTable({
        dom: 'Bfrtip',
        buttons: [{extend: 'csvHtml5', text: 'Excel'}]
      });
    });
    this.getBidBondExpiry();
  },
  methods: {
    getBidBondExpiry() {
      if (!this.bid_expiry.length) {
        this.fetch();
      }
    },
    fetch() {
      this.$store.dispatch('fetchBidBondExpiry', {start: this.start.date, end: this.to.date});
    },
  }
}
</script>
