<template>
  <div>
    <template v-if="loading">
      <loading />
    </template>
    <template v-else>
      <div v-if="$can.view($store.getters.getAuthUser, 'create-counterparties')">
        <router-link
          :to="{ name: 'counterparties.create'}"
          class="btn btn-sm btn-black pull-right"
        >
          Create
          New
        </router-link>
      </div>
      <hr>
      <table
        ref="table"
        class="table table-striped table-responsive-sm"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Physical Address</th>
            <th>Postal Address</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(party, i) in counter_parties"
            :key="i"
          >
            <td>{{ i + 1 }}</td>
            <td>{{ party.name }}</td>
            <td> {{ party.physical_address }}</td>
            <td> {{ party.postal_address }}</td>
            <td>
              <div class="btn btn-group">
                <button
                  v-if="$can.view($store.getters.getAuthUser, 'edit-counterparties')"
                  type="button"
                  class="btn btn-sm btn-info"
                  @click.prevent="edit(party)"
                >
                  Edit
                </button>
                <button
                  v-if="$can.view($store.getters.getAuthUser, 'delete-counterparties')"
                  class="btn btn-sm btn-danger"
                  @click.prevent="deleteParty(party)"
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <modal
      :show-modal="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit Counterparty</h5>
      </template>
      <form @submit.prevent="update">
        <div v-html="$error.handle(error)" />
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Name</label>
              <input
                v-model="counter_party.name"
                type="text"
                required
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Physical Address</label>
              <input
                v-model="counter_party.physical_address"
                type="text"
                required
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Address</label>
              <input
                v-model="counter_party.postal_address"
                type="text"
                required
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Category</label>
              <v-select
                v-model="counter_party.category_secret"
                :options="categories"
                :reduce="x => x.secret"
                label="name"
                placeholder="Category"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Code</label>
              <v-select
                v-model="counter_party.postal_code_id"
                :options="postal_codes"
                :reduce="x => x.id"
                label="code"
                placeholder="Postal Code"
                @input="getCounterparty"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Name</label>
              <input
                v-model="postal_code"
                required
                type="text"
                class="form-control"
                readonly=""
              >
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  data() {
    return {
      loading: true,
      showModal: false,
      dt: "",
      categories: [],
      postal_codes: [],
      postal_code: '',
      counter_party: {
        name: '',
        postal_code_id: null,
        category_secret: ''
      },
      error: '',
    }
  },
  computed: {
    counter_parties() {
      return this.$store.getters.getCounterParties;
    },
  },
  watch: {
    counter_parties() {
      if (this.dt) this.dt.destroy();
      setTimeout(() => {
        this.dt = $(this.$refs.table).DataTable();
      }, 1000);
    }
  },
  mounted() {
    setTimeout(() => {
      this.dt = $(this.$refs.table).DataTable();
    }, 1000);
    this.fetch();
    this.getCounterparties();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/counterparty').then(response => {
        this.categories = response.data.data.categories;
        this.postal_codes = response.data.data.postalcodes;
        this.loading = false;
      });
    },
    getCounterparties() {
      if (!this.counter_parties.length) {
        this.$store.dispatch("fetchCounterParties").then(() => {
          this.loading = false;
        });
      }
    },
    edit(c) {
      this.postal_code = this.postal_codes.filter(x => x.id == c.postal_code_id)[0].name;
      this.counter_party = c;
      this.showModal = true;
    },
    getCounterparty() {
      if (this.counter_party.postal_code_id) {
        this.postal_code = this.postal_codes.filter(x => x.id == this.counter_party.postal_code_id)[0].name;
      }
    },
    update() {
      let data = this.counter_party;
      data.postal_code = this.counter_party.postal_code_id;
      data.category = this.counter_party.category_secret;

      this.$axios.put('/api/v1/counterparty/' + this.counter_party.id, data).then((response) => {
        this.showModal = false;
        this.$store.dispatch("setCounterParty", response.data.data);
        this.$toastr.s("Counterparty updated successfully")
      }).catch(error => {
        this.error = error.response;
      });
    },
    deleteParty(party) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the counterparty " + party.name + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/counterparty/' + party.id).then(() => {
            this.$store.dispatch("deleteCounterParty", party);
            this.$toastr.s("Counterparty deleted successfully");
          });
        }
      });
    }
  }
}
</script>
