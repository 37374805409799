<template>
  <div>
    <fieldset>
      <legend>Create Counter Party</legend>
      <div v-html="$error.handle(error)" />
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-md-12 form-group">
              <label>Name</label>
              <input
                v-model="counter_party.name"
                v-validate="'required'"
                type="text"
                name="name"
                class="form-control"
              >
          </div>
          <div class="col-md-6 form-group">
              <label>Category</label>
              <select
                v-model="counter_party.category"
                class="form-control"
              >
                <option
                  v-for="(category,i) in categories"
                  :key="i"
                  :value="category.secret"
                >
                  {{ category.name }}
                </option>
              </select>
          </div>
          <div class="col-md-6 form-group">
            <label>Postal Address</label>
            <input
                v-model="counter_party.postal_address"
                type="text"
                class="form-control"
            >
          </div>
          <div class="col-md-12 form-group">
              <label>Physical Address</label>
              <input
                v-model="counter_party.physical_address"
                type="text"
                class="form-control"
              >
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Code</label>
              <v-select
                v-model="postal_code"
                v-validate="'required'"
                :options="postal_codes"
                label="code"
                placeholder="Postal Code"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Name</label>
              <input
                v-model="postal_code.name"
                required
                type="text"
                class="form-control"
                readonly=""
              >
            </div>
          </div>
          <div class="col-md-12">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</template>

<script>
export default {

  data() {
    return {
      postal_codes: [],
      counter_party: {},
      postal_code: {},
      error: '',
      categories: []
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/counterparty').then(response => {
        this.categories = response.data.data.categories;
        this.postal_codes = response.data.data.postalcodes;
      });
    },

    submit() {
      let data = this.counter_party;
      data.postal_code = this.postal_code.id;
      this.$axios.post('/api/v1/counterparty', data).then((response) => {
        this.$toastr.s("Counterparty created successfully");
        this.$store.dispatch('setCounterParty',response.data.data);
        this.$router.replace({ name: 'counterparties.index' });
      });
    }
  }
}
</script>
