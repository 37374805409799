<template>
  <div>
    <loading v-if="loading" />
    <template v-else>
      <table
        v-if="companies.length"
        class="table table-striped table-responsive-sm"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Reg Number</th>
            <th>Phone</th>
            <th>Approval Status</th>
            <th>Payment Status</th>
            <th>Created At</th>
            <th v-if="$can.view($store.getters.getAuthUser, 'restore-companies')">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(company, i) in companies"
            :key="i"
          >
            <td>{{ i + pagination.from }}</td>
            <td>{{ company.name }}</td>
            <td>{{ company.crp }}</td>
            <td>{{ company.phone_number }}</td>
            <td>{{ company.approval_status }}</td>
            <td>{{ company.paid ? 'Paid' : 'Unpaid' }}</td>
            <td>{{ company.created_at }}</td>
            <td v-if="$can.view($store.getters.getAuthUser, 'restore-companies')">
              <a
                href="#"
                class="btn btn-success btn-sm"
                @click.prevent="restore(company)"
              >Restore</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="alert alert-warning"
      >
        <p>There are no companies</p>
      </div>
      <pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="5"
        @paginate="fetch"
      />
    </template>
  </div>
</template>

<script>
export default {

  data() {
    return {
      companies: [],
      loading: true,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/companies/deleted?page=' + this.pagination.current_page).then(response => {
        this.loading = false;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.companies = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    restore(company) {
      this.$swal({
        title: 'Are you sure?',
        text: "This will restore " + company.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, restore it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.post('/api/v1/companies/' + company.id + '/restore').then(() => {
            this.$toastr.s(company.name + ' restored successfully.', 'Success');
            this.$router.replace({name: 'companies.index'});
          });
        }
      })
    },
  },
}
</script>
