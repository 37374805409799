<template>
  <div>
    <form @submit.prevent="validateForm">
      <div class="form-group">
        <label>BidBond Amount</label>
        <vue-numeric
          v-model="bid_bond.amount"
          v-validate="'required|max_value:' + current.company.balance"
          name="bidbond_amount"
          class="form-control"
        />
        <small
          v-if="errors.has('bidbond_amount')"
          class="help-block"
        >{{ errors.first('bidbond_amount') }}
        </small>
      </div>
      <div class="form-group">
        <label>Currency</label>
        <v-select
          v-model="bid_bond.currency"
          v-validate="'required'"
          :options="currencies"
          name="currency"
          placeholder="Select Currency"
        />
        <small
          v-if="errors.has('currency')"
          class="help-block"
        >{{ errors.first('currency') }}
        </small>
      </div>
      <div class="form-group">
        <label>Tender Period (days)</label>
        <vue-numeric
          v-model="bid_bond.period"
          v-validate="'required'"
          class="form-control"
          name="tender_period"
        />
        <small
          v-if="errors.has('tender_period')"
          class="help-block"
        >{{ errors.first('tender_period') }}
        </small>
      </div>
      <div class="form-group">
        <label>Start Date</label>
        <input
          ref="yesterdays_date"
          v-model="maxStartDate"
          type="hidden"
        >
        <date-picker
          v-model="bid_bond.start_date"
          v-validate="'required|date_format:yyyy-MM-dd|after:yesterdays_date'"
          format="yyyy-MM-dd"
          name="bid_bond_start_date"
          input-class="form-control bg-white"
          :disabled-dates="disabledDates"
        />
        <small
          v-if="errors.has('bid_bond_start_date')"
          class="help-block"
        >
          {{ errors.first('bid_bond_start_date') }}
        </small>
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input
          v-model="end_date"
          type="text"
          class="form-control"
          readonly
        >
      </div>
      <div class="form-group">
        <a
          href=""
          class="btn btn-info pull-left btn-sm"
          @click.prevent="$emit('back')"
        >Previous</a>
        <button
          class="btn btn-primary pull-right btn-sm"
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import {currencies} from "@/mixins/currencies";

export default {
  components: {
    DatePicker
  },
  props: {
    current: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      bid_bond: {
        amount: '',
        currency: 'KES',
        period: '',
        start_date: '',
        end_date: ''
      },
      maxStartDate: moment().add(-1, 'day').format('yyyy-MM-DD'),
      disabledDates: {
        to: moment().add(-1, 'day').toDate()
      },
      pre_pricing: {}
    }
  },
  computed: {
    end_date() {
      if (this.bid_bond.start_date && this.bid_bond.period) {
        let end_date = moment(this.bid_bond.start_date).add(parseInt(this.bid_bond.period), 'days').format('YYYY-MM-DD');
        this.formatStartDate();
        return end_date;
      }
      return '';
    }
  },

  watch: {
    end_date(newval) {
      this.bid_bond.end_date = newval;
    },

    current(newval) {
      newval.currency ? this.bid_bond.currency = newval.currency : this.bid_bond.currency = 'KES';
      newval.amount ? this.bid_bond.amount = newval.amount : this.bid_bond.amount = '';
      newval.period ? this.bid_bond.period = newval.period : this.bid_bond.period = '';
      newval.start_date ? this.bid_bond.start_date = newval.start_date : this.bid_bond.start_date = '';
    }
  },
  created() {
    this.currencies = currencies;
  },
  mounted() {
    this.current.currency ? this.bid_bond.currency = this.current.currency : this.bid_bond.currency = '';
    this.current.amount ? this.bid_bond.amount = this.current.amount : this.bid_bond.amount = '';
    this.current.period ? this.bid_bond.period = this.current.period : this.bid_bond.period = '';
    this.current.start_date ? this.bid_bond.start_date = this.current.start_date : this.bid_bond.start_date = '';
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('step3', this.bid_bond);
        }
      });
    },
    formatStartDate() {
      this.bid_bond.start_date = moment(this.bid_bond.start_date).format(
          "YYYY-MM-DD"
      );
    },
  }
}
</script>
