<template>
  <div v-if="!loading">
    <h4>Edit Performance bond</h4>
    <div v-html="$error.handle(error)"/>
    <form
        v-if="step === 1"
        class="row"
        data-vv-scope="step1"
        @submit.prevent="validateForm('step1')"
    >
      <div class="form-group col-md-4">
        <label>Company Name</label>
        <input
            v-model="performance.company.name"
            class="form-control"
            placeholder="Company Name"
            readonly
            type="text"
        >
      </div>
      <div class="form-group col-md-4">
        <label>Contract Type</label>
        <input
            v-model="performance.cover"
            readonly
            type="text"
        />
      </div>
      <div class="form-group col-md-4">
        <input
            v-model="is_venture"
            name="is_venture"
            type="checkbox"
        >
        <label
            class="ml-3"
        >Is Joint Venture</label>
      </div>
      <div
          v-for="(r, i) in ventures"
          :key="i"
          class="form-group col-md-4"
      >
        <label>Joint Company Name</label>
        <input
            v-model="ventures[i]"
            v-validate="'required'"
            :name="'venture_'+i"
            class="form-control"
            data-vv-as="Joint Company"
            placeholder="Joint Company name"
            type="text"
        >
        <button
            class="btn btn-sm btn-primary"
            @click.prevent="addRow()"
        >
          <i class="fa fa-plus"/>
        </button>
        <button
            v-if="ventures.length>1"
            class="btn btn-sm btn-danger"
            @click.prevent="minusRow()"
        >
          <i class="fa fa-minus"/>
        </button>
        <div :class="{'help-block': errors.has('step1.venture_'+i)}">
          {{ errors.first('step1.venture_' + i) }}
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Procuring Entity</label>
        <v-select
            v-model="performance.counterparty_id"
            v-validate="'required'"
            :options="counterparties"
            :reduce="c =>c.id"
            data-vv-name="procuring_entity"
            label="name"
            name="procuring_entity"
            placeholder="Select Company Tendering From"
        />
        <small
            v-if="errors.has('step1.procuring_entity')"
            class="help-block"
        >
          {{ errors.first('step1.procuring_entity') }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label>Contract Number</label>
        <input
            v-model="performance.contract_no"
            v-validate="'required'"
            class="form-control"
            data-vv-name="contract no"
            placeholder="eg KENHA/2259/2020"
            type="text"
        >
        <small
            v-if="errors.has('step1.contract no')"
            class="help-block"
        >
          {{ errors.first('step1.contract no') }}
        </small>
      </div>
      <div class="form-group col-md-3">
        <label>Currency</label>
        <v-select
            v-model="performance.currency"
            v-validate="'required'"
            :options="currencies"
            name="currency"
            placeholder="Select Currency"
        />
        <small
            v-if="errors.has('step1.currency')"
            class="help-block"
        >{{ errors.first('step1.currency') }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label>Tender Amount</label>
        <vue-numeric
            v-model="performance.amount"
            v-validate="'required'"
            class="form-control"
            name="tender_amount"
        />
        <small
            v-if="errors.has('step1.tender_amount')"
            class="help-block"
        >
          {{ errors.first('step1.tender_amount') }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label>Start Date</label>
        <date-picker
            v-model="performance.effective_date"
            v-validate="'required'"
            format="yyyy-MM-dd"
            input-class="form-control bg-white"
            name="performance_start_date"
        />
        <small
            v-if="errors.has('step1.performance_start_date')"
            class="help-block"
        >
          {{ errors.first('step1.performance_start_date') }}
        </small>
      </div>
      <div class="form-group col-md-3">
        <label>Tender Period</label>
        <vue-numeric
            v-model="performance.period"
            v-validate="'required|min_value:30'"
            class="form-control"
            name="tender_period"
        />
        <small
            v-if="errors.has('step1.tender_period')"
            class="help-block"
        >
          {{ errors.first('step1.tender_period') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <div class="placeholder-red">
          Strictly type the "Addressee" without including P.O.Box or Physical address such as : Director General,
          Managing Director, Head of Procurement etc.
        </div>
        <label>Addressee</label>
        <textarea
            v-model="performance.addressee"
            v-validate="'required'"
            class="form-control"
            name="addressee"
            placeholder="e.g General Manager"
            rows="3"
        />
        <small
            v-if="errors.has('step1.addressee')"
            class="help-block"
        >{{ errors.first('step1.addressee') }}
        </small>
      </div>
      <div class="form-group col-md-6">
        <label>Tender Purpose</label>
        <textarea
            v-model="performance.purpose"
            v-validate="'required'"
            class="form-control"
            name="tender_purpose"
            placeholder="eg. Tender for provision of auctioneering services"
            rows="3"
        />
        <small
            v-if="errors.has('step1.tender_purpose')"
            class="help-block"
        >
          {{ errors.first('step1.tender_purpose') }}
        </small>
      </div>
      <div class="form-group col-md-12">
        <button
            class="btn btn-primary btn-sm"
            type="submit"
        >
          Next
        </button>
      </div>
    </form>
    <form
        v-if="step === 2"
        data-vv-scope="step2"
        @submit.prevent="validateForm('step2')"
    >
      <div class="form-group">
        <label>Select Template</label>
        <v-select
            v-model="performance.template_secret"
            v-validate="'required'"
            :options="templates"
            :reduce="c => c.secret"
            label="name"
            name="template"
            placeholder="Select Template"
        />
        <small
            v-if="errors.has('step2.template')"
            class="help-block"
        >{{ errors.first('step2.template') }}</small>
      </div>
      <div class="form-group">
        <label class="checkbox">
          <input
              v-validate="'required'"
              name="terms"
              type="checkbox"
          >
          I have read the <a
            href="#"
            @click.prevent="show_terms=true"
        >terms
          and conditions.</a>
        </label>
        <small
            v-if="errors.has('step2.terms')"
            class="help-block"
        >{{ errors.first('step2.terms') }}</small>
      </div>
      <hr>
      <div class="form-group">
        <div
            id="bid-bond-template"
            class="preview"
            style="background: #fff url(/img/sample.png) no-repeat; background-size: contain; background-position-x: center;"
        >
          <div v-html="preview"/>
        </div>
      </div>
      <hr>
      <div class="form-group px-5 text-right">
        <button
            class="btn btn-primary btn-sm"
            type="submit"
        >
          Update
        </button>
        <a
            class="btn btn-info pull-left btn-sm"
            href="#"
            @click.prevent="step=1"
        >Previous</a>
      </div>
    </form>
  </div>
  <loading v-else/>
</template>
<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import {currencies} from "@/mixins/currencies";
import {mapGetters} from 'vuex';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      performance: {
        company: {},
        company_id: null,
        purpose: '',
        counterparty_id: '',
        addressee: '',
        template_secret: '',
        charge: null,
        currency: 'KES',
        bidbond_secret: '',
        joint_venture: ''
      },
      is_venture: false,
      ventures: [],
      step: 1,
      error: '',
      templates: [],
      selected_template: {},
      preview: '',
      loading: false,
      terms: false,
      show_terms: false
    }
  },
  computed: {
    ...mapGetters({
      counterparties: 'getPerformanceParties'
    })
  },
  watch: {
    'performance.template_secret': function () {
      this.fetchPreview();
    },
    is_venture: function (n) {
      if (n && !this.ventures.length) {
        this.ventures.push('');
      } else {
        this.ventures = [];
      }
    },
  },
  mounted() {
    this.fetchPerformancebond();
    this.fetchTemplates();
    this.fetchCounterParties();
  },
  created() {
    this.currencies = currencies;
  },
  methods: {
    fetchTemplates() {
      this.loading = true;
      this.$axios.get('/api/v1/performance-templates/all').then(response => {
        this.templates = response.data;
        this.loading = false;
      });
    },
    fetchCounterParties() {
      if (!this.counterparties.length) {
        this.$store.dispatch("fetchPerformanceParties").then(() => {
          this.loading = false;
        });
      }
    },
    fetchPerformancebond() {
      this.loading = true;
      this.$axios.get('/api/v1/performances/' + this.$route.params.secret).then(response => {
        this.performance = response.data.data;
        if (this.performance.joint_venture) {
          this.is_venture = true;
          if (this.performance.joint_venture.indexOf('-') !== -1) {
            this.ventures = this.performance.joint_venture.split('-');
          } else {
            this.$set(this.ventures, 0, this.performance.joint_venture);
          }
        }
        this.loading = false;
      }).catch(error => {
        this.error = error.response;
        this.loading = false;
      });
    },
    calculatePricing() {
      this.loading = true;
      this.$axios.post('/api/v1/performances/pricing', {
        amount: this.performance.amount,
        currency: this.performance.currency,
        period: this.performance.period,
        company_id: this.performance.company_id,
        secret: this.performance.secret,
        contract_type: this.performance.cover
      }).then(response => {
        this.loading = false;
        this.performance.charge = response.data.total;
      });
    },
    validateForm(scope) {
      return this.$validator.validateAll(scope).then(result => {
        if (result) {
          if (scope === 'step1') {
            this.performance.joint_venture = this.ventures.join('-');
            this.performance.end_date = moment(this.performance.effective_date).add(parseInt(this.performance.period), 'days').format('YYYY-MM-DD');
            this.performance.effective_date = moment(this.performance.effective_date).format('YYYY-MM-DD');
            this.step = 2;
            this.calculatePricing();
            this.fetchPreview();
          }
          if (scope === 'step2') {
            this.postPerformancebond();
          }
        }
      });
    },
    postPerformancebond() {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      const data = (({company, counterparty, attachments, created_at, updated_at, ...o}) => o)(this.performance);
      this.$axios.put('/api/v1/performances/' + this.performance.secret, data).then(() => {
        this.loading = false;
        this.$toastr.s('Performance bond updated successfully!If you have changed the contract period an additional payment will be required!');
        this.$router.push({name: "performance.index"});
      }).catch(err => {
        this.loading = false;
        if (err.response) {
          this.error = err.response;
        }
      });
    },
    fetchPreview() {
      // eslint-disable-next-line no-unused-vars
      const data = (({company, counterparty, attachments, created_at, updated_at, ...o}) => o)(this.performance);
      data.company = this.performance.company.name;
      this.$axios.post('/api/v1/performances/preview', data).then(response => {
        this.preview = response.data;
      });
    },
    addRow() {
      this.ventures.push('');
    },
    minusRow() {
      this.ventures.pop();
    }
  }
}
</script>


