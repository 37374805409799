<template>
  <div>
    <fieldset>
      <legend>Add Pricing Tariff</legend>
      <div v-html="$error.handle(error)" />
      <form @submit.prevent="validateForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Pricing Group</label>
              <v-select
                v-model="price.group_id"
                v-validate="'required'"
                :options="groups"
                name="group"
                label="name"
                :reduce="c => c.id"
                :class="{'is-invalid': errors.has('group') }"
                placeholder="Select Pricing Group"
              />
              <small
                v-if="errors.has('group')"
                class="help-block"
              >
                {{ errors.first('group') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Lower Bound</label>
              <vue-numeric
                v-model="price.lower_bound"
                v-validate="'required'"
                class="form-control"
              />
              <small
                v-if="errors.has('lower_bound')"
                class="help-block"
              >
                {{ errors.first('lower_bound') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Upper Bound</label>
              <vue-numeric
                v-model="price.upper_bound"
                v-validate="'required'"
                class="form-control"
                name="upper_bound"
              />
              <small
                v-if="errors.has('lower_bound')"
                class="help-block"
              >
                {{ errors.first('upper_bound') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Charge Type</label>
              <select
                v-model="price.charge_type"
                v-validate="'required'"
                class="form-control"
                name="charge_type"
              >
                <option value="fixed">
                  Fixed
                </option>
                <option value="percent">
                  Percentage
                </option>
              </select>
              <small
                v-if="errors.has('charge_type')"
                class="help-block"
              >
                {{ errors.first('charge_type') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Cost</label>
              <vue-numeric
                v-model="price.cost"
                v-validate="'required'"
                :precision="3"
                name="cost"
                class="form-control"
              />
              <small
                v-if="errors.has('cost')"
                class="help-block"
              >
                {{ errors.first('cost') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Discount</label>
              <vue-numeric
                v-model="price.discount"
                v-validate="'required'"
                name="discount"
                :precision="3"
                class="form-control"
              />
              <small
                v-if="errors.has('discount')"
                class="help-block"
              >
                {{ errors.first('discount') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Discount Type</label>
              <select
                v-model="price.discount_type"
                v-validate="'required'"
                class="form-control"
                name="discount_type"
              >
                <option value="fixed">
                  Fixed
                </option>
                <option value="percent">
                  Percentage
                </option>
              </select>
              <small
                v-if="errors.has('discount_type')"
                class="help-block"
              >
                {{ errors.first('discount_type') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Duration</label>
              <select
                v-model="price.duration"
                v-validate="'required'"
                class="form-control"
                name="duration"
              >
                <option value="1">
                  Monthly
                </option>
                <option value="3">
                  Quarterly
                </option>
                <option value="6">
                  Half Yearly
                </option>
                <option value="12">
                  Yearly
                </option>
              </select>
              <small
                v-if="errors.has('duration')"
                class="help-block"
              >
                {{ errors.first('duration') }}
              </small>
            </div>
          </div>
          <div class="col-md-12">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</template>

<script>
    export default {

        data() {
            return {
                price: {},
                error: ''
            }
        },
        computed: {
            groups() {
                return this.$store.getters.getGroups;
            },
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                if (!this.groups.length) {
                    this.$store.dispatch("fetchGroups");
                }
            },
            validateForm() {
                return this.$validator.validateAll().then(result => {
                    if (result) {
                        this.$axios.post('/api/v1/bidbond-pricing', this.price).then(() => {
                            this.$router.push({name: 'bidbonds.pricing'})
                        }).catch(error => {
                            this.error = error.response;
                        });
                    }
                });
            },
        },
    }
</script>
